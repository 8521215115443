.skydart-packing {
  width: 100%;
}
.skydart-packing-container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  width: calc(100vw - 1.5rem);
  margin: 10px;
  height: calc(100vh - 8rem);
  overflow-y: auto;
  margin-top: 10px;
  border-radius: 10px;
}
.skydart-packing-header {
  padding: 20px;
  border-bottom: 1px solid lightgray;
  user-select: none;
}
.skydart-packing-text-field {
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.skydart-packing-text-field h3 {
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
}
.skydart-packing-input-container {
  margin-top: 10px;
  position: relative;
  display: flex;
}
.skydart-packing-show-bag {
  width: calc(100vw - 20rem);
}
.skydart-packing-show-bag-header {
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.skydart-packing-show-bag-header p {
  font-size: 12px;
}
.skydart-packing-export-btn {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
  color: blue;
}
.skydart-packing-export-btn:active {
  color: red;
}
.skydart-packing-input-container input {
  padding: 7px;
  width: 310px;
  border: none;
  border-radius: 12px;
  outline: none;
  border: 1px solid rgb(225, 225, 225);
  font-size: 16px;
}
.skydart-packing-input-container input:focus {
  outline: 1px solid rgb(34, 90, 194);
  outline-offset: 1px;
}
.skydart-packing-input-container input::placeholder {
  color: rgb(201, 201, 201);
}
.skydart-packing-input-container input:focus::placeholder {
  color: rgb(136, 136, 136);
}
.skydart-packing-text-field button {
  cursor: pointer;
  padding: 7px;
  margin-left: 5px;
}
.skydart-packing-flight-option {
  padding: 5px;
  user-select: none;
  cursor: pointer;
  border-bottom: 1px solid rgb(225, 225, 225);
}
.skydart-packing-flight-option:hover {
  background-color: rgb(245, 245, 245);
}
.skydart-packing-flight-option:active {
  background-color: rgb(230, 230, 230);
}
.skydart-packing-flight {
  position: absolute;
  background-color: white;
  margin-top: 50px;
  max-height: 30vh;
  overflow-y: auto;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  width: 310px;
  border-radius: 10px;
  z-index: 9999;
}
.skydart-packing-show-bag-body {
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.skydart-packing-show-bag-body input {
  padding: 5px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 320px;
  border: 1px solid rgb(228, 228, 228);
  outline: none;
  background-color: rgb(242, 242, 242);
}
.skydart-packing-show-bag-body input:focus {
  background-color: white;
  outline: 1px solid #012fca;
  outline-offset: 1px;
}

.skydart-packing-content {
  display: flex;
}
.skydart-packing-sidebar {
  padding: 13px;
  width: 330px;
  border-right: 1px solid rgb(230, 230, 230);
}
.skydart-packing-sidebar-info h3 {
  font-size: 15px;
  font-weight: 600;
}
.skydart-packing-sidebar-info {
  display: flex;
  align-items: center;
  padding: 7px;
  justify-content: space-between;
}
.skydart-packing-show-bag-option p {
  font-size: 11px;
}
.skydart-packing-show-bag-option {
  padding: 10px;
}
.skydart-packing-radio {
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.skydart-packing-radio-left {
  display: flex;
  margin-right: 5px;
  align-items: center;
}
.skydart-packing-radio-left label {
  margin-left: 10px;
}
.skd-user-info-export-excel-table {
  background-color: white !important;
  width: 500px !important;
  height: 70vh !important;
  overflow: scroll !important;
  position: absolute !important;
  display: none !important;
  margin-left: 9999px !important;
}
.skd-user-info-export-excel-table th {
  font-size: 12px;
}
.skd-user-info-export-excel-table-info-container {
}
.skd-user-info-export-excel-table-info-container h3 {
  font-size: 16px;
}
.skd-user-info-export-excel-table-info-container p {
  font-size: 14px;
}
.skd-user-info-export-excel-table-header {
  padding: 5px;
  font-size: 18px;
  font-weight: 450;
  color: rgb(55, 55, 55);
}
.th-context-customer-filter {
  position: absolute;
  background-color: white;
  height: 30vh;
  margin-top: 300px;
  width: 100%;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 2px 4px;
}
.th-context-customer-filter p {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}
.th-context-customer-filter-option {
  padding: 5px;
}
.th-context-customer-filter-option:hover {
  background-color: rgb(240, 240, 240);
}
.th-context-customer-filter-option:hover p {
  text-decoration: underline;
}
.th-context-customer-filter-option:active {
  background-color: lightgray;
}
