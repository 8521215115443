.personal {
  flex: 4;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  animation: fadeIn 0.2s;
}
.personal h1 {
  font-weight: 400;
  font-size: 27px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.75);
}
.personal p {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
}
.personal__header {
  margin: 35px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personal__body {
  margin: 35px;
  width: 80%;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
}
.personal__body__top {
  padding: 25px;
}
.personal__body__top h3 {
  font-size: 23px;
  font-weight: 600;
}

.personal__body__option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 25px;
  border-bottom: 1px solid lightgray;
  transition: 0.1s;
}
.personal__body__option:hover {
  background-color: rgb(236, 236, 236);
}
.personal__body__option h3 {
  width: 30%;
  font-weight: 400;
  font-size: 15px;
  color: gray;
}
.personal__body__option p {
  width: 68%;
  font-weight: 600;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
}
.personal__body__Option {
  width: 68%;
}
.personal__country {
  width: 68%;
  display: flex;
  align-items: center;
}
.personal__country p {
  margin-left: 5px;
}
.personal__body__top p {
  font-weight: 600;
  font-size: 13px;
  margin-top: 10px;
}
.personal__body__top h3 {
  font-weight: 500;
}
.personal__header__right img {
  height: 30vh;
  margin-top: -20px;
}
.personal__header__left {
  width: 50%;
}
.personal__header__left h3 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}
.personal__header__left p {
  font-weight: 400;
  font-size: 13px;
}
.personal-btn {
  margin: 20px !important;
  background-color: #017de9 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}
/* .personal__body__top__option {
    width: 50%;
} */
.personal__body__top__option img {
  height: 30vh;
}
.personal__profile__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.55);
  animation: fadeIn 0.2s;
  overflow: auto;
}
.price__loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.637);
  animation: fadeIn 0.2s;
}
.price__loading__container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 50%;
}
.personal__profile__content {
  width: 40%;
  background-color: rgb(251, 251, 255);
  overflow: initial;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 20%;
  margin: 0 auto;
  animation: fadeRight 0.2s;
}

.personal__profile__header {
  padding: 25px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.personal__profile__body {
  margin: 25px;
}
.personal__profile__bottom {
  margin: 25px;
}
.personal__profile__body p {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}
.personal__profile__header h3 {
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.75);
}
.profile-txt-field {
  margin-top: 15px !important;
}
.personal-cancel-btn {
  margin-left: 75% !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  color: #0074d9 !important;
  padding: 7px !important;
  margin-right: 10px !important;
}
.personal-cancel-btn:hover {
  background-color: #d1e3ff !important;
}
.personal-save-btn {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  background-color: #0074d9 !important;
  color: white !important;
  padding: 7px !important;
}
.personal__profile__bottom {
  display: flex;
  align-items: center;
}
