.label-container {
  flex: 5;
  height: 100vh;
  overflow: auto;
}
.skydart-label-header {
  padding-top: 25px;
  padding-left: 19px;
  padding-right: 19px;
}
.skydart-label-header h1 {
  font-size: 18px;
}
.skydart-label-nav {
  padding: 19px;
  display: flex;
}
.skydart-label-tracking {
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px; */
}
.skydart-label-tracking input {
  padding: 12px;
  width: 450px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
}
.skydart-label-tracking input::placeholder {
  color: lightgrey !important;
  font-weight: 600 !important;
}
.skydart-label-tracking input::-ms-input-placeholder {
  color: lightgray !important;
  font-weight: 600 !important;
}
.skydart-label-tracking button {
  padding: 12px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  width: 100px;
  color: white;
  font-size: 14px;
  background-color: #0f6bb6;
}
.skydart-label-tracking button:hover {
  opacity: 0.8;
}
.skydart-label-tracking button:active {
  transform: scale(0.9);
}
.skydart-label-date {
  margin-left: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  cursor: pointer;
  width: 230px;
  border-radius: 10px;
  padding: 5px;
}
.skydart-label-create-shipment {
  margin-left: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #b2d8f1;
  background-image: linear-gradient(135deg, #b2d8f1 0%, #b3b5e2 100%);
  color: black;
  padding: 5px;
  border-radius: 20px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.skydart-label-create-shipment:hover {
  opacity: 0.8;
}
.skydart-label-create-shipment:active {
  transform: scale(1.05);
}
.skydart-label-create-shipment h5 {
  font-weight: 600;
  margin-left: 12px;
}
.skydart-label-date:hover {
  background-color: rgb(240, 240, 240);
}
.skydart-label-date-header h3 {
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px;
}
.skydart-label-date-header {
  display: flex;
  align-items: center;
}
.skydart-label-list {
  margin-left: 19px;
  margin-right: 19px;
}
.skydart-label-list-header-amount h5 {
  font-weight: 600;
  color: rgb(115, 127, 146);
}
.skydart-label-list-body-amount h5 {
  font-weight: 600;
  font-size: 15px;
}
.skydart-label-list-body-empty h5 {
  font-weight: 600;
  font-size: 15px;
}
.skydart-label-list-body-empty1 h5 {
  font-weight: 600;
  font-size: 15px;
}
.skydart-label-list-header-empty {
  padding: 5px;
}
.skydart-label-list-body-empty {
  padding: 5px;
}
.skydart-label-list-body-empty1 {
  padding: 5px;
}
.skydart-label-list-header-amount {
  padding: 5px;
  width: 12%;
}
.skydart-label-list-body-amount {
  padding: 5px;
  width: 12%;
}
.skydart-label-list-body {
  display: flex;
  margin-top: 10px;
  margin-bottom: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border-radius: 15px;
  align-items: center;
  padding: 10px;
}
.skydart-label-list-header {
  display: flex;
  padding: 10px;
  align-items: center;
}
.skydart-label-list-header-empty {
  width: 50%;
}

.skydart-label-list-body-empty {
  width: 25%;
}
.skydart-label-list-body-empty1 {
  width: 50%;
}
.skydart-label-list-body-file {
  margin-top: 2px;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  color: gray;
  text-decoration: none;
  border: 1px solid rgb(231, 231, 231);
}
.skydart-label-list-body-file:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-label-list-body-file p {
  font-size: 12px;
  padding-left: 7px;
  padding-right: 7px;
}
.skydart-label-list-header-label {
  padding: 7px;
  margin-left: auto;
  margin-right: 12px;
}
.skydart-label-list-body-label {
  padding: 7px;
  background-color: rgba(30, 45, 70, 0.63);
  color: white;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 12px;
  cursor: pointer;
  transition: 0.1s;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.skydart-label-list-body-label:hover {
  opacity: 0.8;
}
.skydart-label-list-body-label:active {
  opacity: 0.6;
}
.skydart-label-list-body-label a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
