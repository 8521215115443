.login {
  width: 100%;
}

.login__container {
  width: 30%;
  overflow: initial;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 25%;
  margin: 0 auto;
}
.login__container1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__footer {
  width: 35%;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  top: 25%;
  margin: 0 auto;
}
.login__footer__left {
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding: 7px;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: 0.1s;
}
.login__footer__left:hover {
  color: black;
}
.login__footer__right:hover {
  color: black;
}
.login__footer__right {
  display: flex;
  margin-top: 5px;
  align-items: center;
  padding: 7px;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: 0.1s;
}
.login__footer__left h5 {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
}
.login__footer__right h5 {
  margin-left: 5px;
  font-weight: 600;
}
.signUp__container {
  width: 50%;
  border: 1px solid lightgray;
  background-color: white;
  overflow: initial;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 5%;
}
.sign-in-footer {
  margin: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.loading-circle {
  margin-top: 20%;
  margin-left: 48.5%;
}
.info-alert {
  position: relative;
  margin: 0 auto;
  top: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 45%;
}
.login-btn1 {
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: rgb(36, 110, 207);
  color: white;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  border-radius: 12px;
  margin-left: 10px;
  border: none;
  width: 200px;
  cursor: pointer;
  transition: 0.1s;
}
.login-btn1:hover {
  background-color: rgb(29, 99, 190);
}
.login-btn1:active {
  background-color: rgb(16, 88, 182);
}
.login__header {
  padding: 10px;
  margin-left: 60px;
}
.login__header img {
  height: 5vh;
}
.login__header h5 {
  font-size: 25px;
  font-weight: 600;
  color: darkblue;
}
.login-lang {
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  margin-top: 90px;
  margin-left: 30px;
}
.login-lang-option {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  padding: 5px;
  cursor: pointer;
}
.login-lang-option:hover {
  background-color: lightgray;
}
.login-lang-option h5 {
  font-size: 12px;
}
.login-text-field {
  margin-top: 10px !important;
  background-color: white !important;
}
.login-btn {
  margin: 10px !important;
  background-color: rgb(45, 116, 209) !important;
  font-weight: 600 !important;
  padding: 12px !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  transition: 0.2s !important;
  text-transform: capitalize !important;
}
.sign-up-btn {
  padding: 12px !important;
  border-radius: 5px !important;
  border: none !important;
  background: none !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  color: rgb(95, 108, 124) !important;
  cursor: pointer !important;
  transition: 0.2s !important;
  width: 100% !important;
}
.forget-pw {
  padding: 12px !important;
  border-radius: 5px !important;
  color: rgb(36, 110, 207) !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  cursor: pointer !important;
}
.forget-pw:hover {
  background-color: rgb(224, 237, 255) !important;
}
.sign-up-btn:hover {
  transition: 0.2s !important;
  background-color: rgb(233, 233, 233) !important;
}
.upgrade-account-policy {
  cursor: pointer;
  width: fit-content;
  height: 3vh;
  margin-top: 5px;
  margin-left: 20px;
}
.upgrade-account-policy h3 {
  font-size: 10px;
  font-weight: 500;
  color: rgb(49, 102, 216);
}
.upgrade-account-policy:hover h3 {
  border-bottom: 1px solid;
}

.login-btn:hover {
  transition: 0.2s !important;
  background-color: rgb(44, 100, 173) !important;
}
.retry-btn {
  background-color: white !important;
  color: rgb(94, 94, 94) !important;
  font-weight: 600 !important;
  margin: 15px !important;
  text-transform: capitalize !important;
}
.retry-btn:hover {
  background-color: lightgray !important;
}
.signUp__header {
  padding: 25px;
  margin-left: 10px;
}
.signUp__body__accountType {
  display: flex;
  width: 176%;
  margin-left: 12px;
  margin-bottom: 0px;
}
.signUp__body {
  padding-left: 25px;
  display: flex;
  align-items: center;
}
.signUp__body__container {
  width: 55%;
}
.signUp_outline {
  margin: 10px !important;
  width: 95% !important;
}
.signUp_input {
  margin: 10px !important;
  width: 90% !important;
  padding: 5px !important;
  border: 1px solid lightgray !important;
  outline: none !important;
}
.signUp__option {
  margin: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.prev-logIn {
  padding: 10.5px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  color: rgb(45, 116, 209);
  transition: 0.2s;
}
.prev-logIn:hover {
  transition: 0.2s !important;
  background-color: rgb(215, 224, 252) !important;
}
.next-signUp {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  background-color: rgb(45, 116, 209) !important;
  color: white !important;
}
.img-content {
  border: 1px solid;
  width: 30%;
}
.signUp__next {
  padding-left: 25px;
  display: flex;
  transition: transform 500ms ease-in-out 25ms;
  animation: 0.2s fadeRight;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.75);
}
.login-phone {
  display: none;
}
@keyframes fadeRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0);
  }
}
.signUp__addressForm {
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.signUp__addressFormContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.signUp__addressForm p {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
}
.next-content {
  margin-left: 7px;
  margin-top: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
}
.next-btn-opt {
  width: 50%;
}
.nextBtn {
  width: 320%;
  display: flex;
  justify-content: space-between;
}
.back-singUp {
  color: rgb(36, 110, 207) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}

.addition-address-btn {
  background-color: white !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  color: rgba(0, 0, 0, 0.45) !important;
  margin-left: 100px !important;
}
.addition-address-btn:hover {
  background-color: lightgray !important;
}
.finish-register {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.signUp__finish__option {
  margin: 15px;
}
.signUp__container select {
  padding: 10px;
  border: 1px solid lightgray;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
}
.term__container {
  background-color: white;
  width: 70%;
  margin-left: 14%;
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}
.term__content {
  padding: 10px;
}
.term__content__primary {
  display: flex;
  align-items: center;
}
.term__content__primary h3 {
  font-weight: 600;
}
.term__content__primary h4 {
  font-weight: 600;
  margin-left: 5px;
}
.term__content__secondary {
  display: flex;
  margin-top: 10px;
}
