.app {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.customer-app {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(315deg, #fff, #f3f7fc 74%);
  overflow: auto;
  display: flex;
}
.multi-box {
  box-shadow:
    rgba(100, 149, 237, 0.3) 0px 3px 12px,
    /* Light soft blue */ rgba(144, 238, 144, 0.2) 0px 5px 20px; /* Light soft green */
}

.multi-box1 {
  box-shadow:
    rgba(100, 149, 237, 0.1) 0px 2px 4px,
    /* Soft blue glow */ rgba(144, 238, 144, 0.1) 0px 4px 5px,
    /* Light green glow */ rgba(0, 0, 139, 0.1) 0px 2px 10px,
    /* Subtle dark blue depth */ rgba(255, 255, 255, 0.12) 0px -1px 5px inset; /* Inner white glow for glass effect */
}

.checkbox-wrapper-7 .tgl {
  display: none;
}
.checkbox-wrapper-7 .tgl,
.checkbox-wrapper-7 .tgl:after,
.checkbox-wrapper-7 .tgl:before,
.checkbox-wrapper-7 .tgl *,
.checkbox-wrapper-7 .tgl *:after,
.checkbox-wrapper-7 .tgl *:before,
.checkbox-wrapper-7 .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper-7 .tgl::-moz-selection,
.checkbox-wrapper-7 .tgl:after::-moz-selection,
.checkbox-wrapper-7 .tgl:before::-moz-selection,
.checkbox-wrapper-7 .tgl *::-moz-selection,
.checkbox-wrapper-7 .tgl *:after::-moz-selection,
.checkbox-wrapper-7 .tgl *:before::-moz-selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-7 .tgl::selection,
.checkbox-wrapper-7 .tgl:after::selection,
.checkbox-wrapper-7 .tgl:before::selection,
.checkbox-wrapper-7 .tgl *::selection,
.checkbox-wrapper-7 .tgl *:after::selection,
.checkbox-wrapper-7 .tgl *:before::selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after,
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper-7 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-7 .tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition:
    left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease,
    margin 0.3s ease;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 0 rgba(0, 0, 0, 0.08);
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn {
  background: #86d993;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

.multi-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 12px;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid lightgray;
}
.skydart-bookShipment {
  background-color: #b2d8f1;
  background-image: linear-gradient(135deg, #b2d8f1 0%, #b3b5e2 100%);
  /* overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
  width: 100%; */
}
.newBooking-search {
  outline: none;
}

.newBooking-search:focus {
  outline: 1px solid #356ed1;
  outline-offset: 1px;
}

.elipe-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-weight: 600;
  user-select: none;
}
.skydart-main-primary-left-header {
  background-image: linear-gradient(
    81.84deg,
    rgb(79, 204, 120) -8.4%,
    rgb(11, 60, 194) 50%,
    rgb(17, 83, 170) 35.54%,
    rgb(79, 204, 120) 90.59%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.5;
  text-align: left;
  word-break: break-word;
}

.skydart-main-primary-left-header2 {
  background-image: linear-gradient(
    81.84deg,
    rgb(79, 204, 120) -8.4%,
    rgb(11, 60, 194) 50%,
    rgb(17, 83, 170) 35.54%,
    rgb(79, 204, 120) 90.59%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.5;
  text-align: left;
  word-break: break-word;
}
.skydart-main-primary-left-header1 {
  background-image: linear-gradient(
    81.84deg,
    rgb(79, 204, 120) -8.4%,
    rgb(11, 60, 194) 50%,
    rgb(17, 83, 170) 35.54%,
    rgb(79, 204, 120) 90.59%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.5;
  word-break: break-word;
}
.th {
  padding: 11px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid rgb(227, 227, 227) !important;
  border-bottom: none !important;
}

.td {
  padding: 11px !important;
  border: 1px solid rgb(227, 227, 227) !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}

.td p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 17px;
}
.td:active {
  background-color: white !important;
  outline: 1px solid #002fca;
  outline-offset: 2px;
  box-shadow: 0 0 2px dodgerblue;
  z-index: 9999;
  border-radius: 2px;
}
.resizer {
  right: 0;
  background: rgb(170, 170, 170);
  width: 4px;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
}

.resizer:hover {
  opacity: 1;
}

.resizer:active {
  background-color: rgb(32, 76, 196);
  height: calc(100vh - 14rem);
  opacity: 1;
}

.manifest-tr {
  display: flex !important;
  cursor: default !important;
  width: fit-content !important;
}

.table-wrapper {
  /* width: 80vw; */

  overflow: auto;
  height: calc(100vh - 14rem);
}
.table-wrapper2 {
  /* width: 80vw; */

  overflow: auto;
  height: calc(100vh - 11.5rem);
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1700px) {
  .table-wrapper3 {
    width: calc(100vw - 21.5rem);
    overflow: auto;
    height: calc(100vh - 15.5rem);
    /* min-height: 45vh; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  .table-wrapper3-sidebar {
    width: calc(100vw - 5.1rem);
    overflow: auto;
    height: calc(100vh - 15.5rem);
    /* min-height: 45vh; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1700px) {
  .table-wrapper3 {
    width: calc(100vw - 23.5rem);
    overflow: auto;
    height: calc(100vh - 15.5rem);
    /* min-height: 45vh; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
  }
}
.responsive-show {
  display: none;
}
@media screen and (max-width: 600px) {
  .responsive-hidden {
    display: none;
  }
  .responsive-show {
    display: flex;
  }
}
.table-wrapper3-full {
  width: calc(100vw - 1.5rem);
  overflow: auto;
  height: calc(100vh - 15.5rem);
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 10px;
}
.table-wrapper1 {
  /* width: 80vw; */
  overflow: auto;
  height: calc(100vh - 20rem);
}

.tr3 {
  cursor: default;
  width: fit-content;
}

.tr4-helper {
  position: absolute;
  background-color: rgb(255, 232, 156);
  z-index: 7000;
  width: 100%;
  word-break: break-all;
  left: 0;
  font-weight: 600;
  top: 0;
  display: none;
  padding: 7px;
}

.tr-docs-helper {
  position: absolute;
  background-color: rgb(255, 232, 156);
  z-index: 7000;
  width: 100%;
  word-break: break-all;
  left: 0;
  font-weight: 600;
  top: 0;
  display: none;
  padding: 7px;
}

.tr3:hover .tr4-helper {
  display: block;
}

.skydart-shipment-break-down {
  position: absolute;
  z-index: 9999;
  margin-top: 5px;
  border-radius: 5px;
  margin-left: 7px;
  padding: 5px;
  width: 270px;
  height: 320px;
  overflow-y: scroll;
  /* width: 122px; */
  background-color: white;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.22) 0px 2px 16px 0px;
}

.skydart-shipment-break-down-option {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  user-select: none;
  align-items: center;
}

/* .skydart-shipment-break-down-option:hover {
    background-color: rgb(238, 238, 238);
}

.skydart-shipment-break-down-option:active {
    background-color: lightgray;
} */

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: black;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #4682f3;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.active-page {
  color: white !important;
  background-color: rgb(0, 132, 198) !important;
  border: 1px solid #0479da !important;
}

.paginate {
  display: flex;
  list-style: none;
  margin: 9px;
  margin-left: auto;
}
.paginate1 {
  display: flex;
  list-style: none;
}
.paginate1 li {
  font-size: 16px;
  border-radius: 999px;
  width: 35px;
  height: 35px;
  border: 1px solid rgb(188, 199, 212);
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  transition: 0.1s;
  margin-right: 5px;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
}

.page-item {
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 999px;
  cursor: pointer;
  color: #0074d9;
}

.paginate li {
  padding: 5px;
  font-size: 16px;
  border-radius: 999px;
  width: 35px;
  height: 35px;
  border: 1px solid rgb(188, 199, 212);
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  transition: 0.1s;
  margin-right: 5px;
}

.paginate li:hover {
  background-color: rgb(228, 228, 228);
}

.paginate li:active {
  background-color: rgb(199, 199, 199);
}
.paginate1 li:active {
  background-color: rgb(199, 199, 199);
}
.paginate1 li:hover {
  background-color: rgb(228, 228, 228);
}

.prev-btn-page {
  font-size: 30px !important;
}

.skydart-header {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  position: sticky;
  top: 0;
}

.skydart-header-first-left {
  display: flex;
  align-items: center;
}

.skydart-header-first-left h1 {
  font-size: 18px;
  font-weight: 600;
  color: darkblue;
}

.skydart-header-first-left h3 {
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-header-right-choice {
  display: flex;
  align-items: center;
}

.skydart-header-right-choice h5 {
  font-weight: 500;
  margin-left: 5px;
}

.skydart-header-first {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-header-first-right {
  display: flex;
  align-items: center;
}

.skydart-header-right-choice {
  padding: 7px;
  border: 1px solid rgb(231, 231, 231);
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

.skydart-header-right-choice:hover {
  background-color: rgb(231, 231, 231);
}

.skydart-header-right-choice:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
}

.skydart-header-second {
  margin-left: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-header-second-left h5 {
  font-size: 10px;
  font-weight: 600;
  padding: 3px;
  background-color: rgb(243, 243, 243);
  width: fit-content;
}

.skydart-header-second-right {
  display: flex;
  gap: 0.75em;
}

.skydart-header-second-right-option {
  padding: 5px;
  font-size: 11px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  text-decoration: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.skydart-header-second-right-option:hover {
  color: black;
  text-decoration: underline;
}

.skydart-active {
  color: #00be10 !important;
  text-decoration: none;
  background-color: rgb(215, 233, 255);
  border-radius: 5px;
  text-decoration: none;
  width: 100px;
  padding: 10px;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.skydart-active:active {
  transform: scale(0.9);
}

.skydart-active:hover {
  color: #0068be !important;
  text-decoration: none !important;
  background-color: rgb(215, 233, 255) !important;
}

.skydart-nav-link-header h1 {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.skd-debt-customer-shown {
  position: absolute;
  right: 10px;
  /* background-color: #f9fcff;
background-image: linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%); */
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #f3f7fc 74%);
  width: 750px;
  overflow-y: auto;
  box-shadow:
    rgba(50, 50, 93, 0.15) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.05) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  height: 66.5%;
}

.skd-debt-update-loading {
  display: flex;
  align-items: center;
  animation: supprise 0.1s;
}

.skd-debt-update-loading1 {
  display: flex;
  align-items: center;
  animation: supprise 0.1s;
  background-color: rgb(49, 167, 49);
  color: white;
  padding: 2px;
  border-radius: 5px;
}

@keyframes supprise {
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
  }
}

.skd-debt-customer-shown1 {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 251, 255);
  width: 90px;
  overflow-y: auto;
  box-shadow:
    rgba(50, 50, 93, 0.15) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.05) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  height: 66.5%;
}

.skd-debt-customer-performance {
  padding: 5px;
}

.skd-debt-customer-shipment-fetch {
  padding: 5px;
  margin-top: 15px;
}

.skd-debt-customer-shipment-fetch-container {
  margin-top: 5px;

  border-radius: 10px;
}

.skd-debt-customer-perform-box-headerPrimary {
  display: flex;
  color: rgb(75, 75, 75);
  align-items: center;
}

.skd-debt-customer-perform-summary {
  margin-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.skd-debt-customer-perform-summary p {
  font-size: 12px;
  color: gray;
}

.skd-debt-customer-perform-summary h5 {
  font-size: 16px;
  font-weight: 600;
}

.skd-debt-customer-shown-stat {
  padding: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-performance-box-header {
  padding: 10px;
}

.skd-debt-customer-perform-stat-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skd-debt-customer-perform-stat p {
  font-size: 12px;
  color: gray;
}

.skd-debt-customer-perform-stat h5 {
  font-weight: 600;
  font-size: 13px;
}

.skd-debt-customer-perform-stat {
  margin-top: 15px;
}

.skd-debt-customer-performance-box-option {
  width: 225px;
  border-radius: 10px;
  background-color: white;
  height: 200px;
  box-shadow: rgba(99, 99, 99, 0.15) 0px 2px 8px 0px;
}

.skd-debt-customer-performance-box {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.skd-debt-customer-shown-stat h3 {
  font-size: 15px;
  font-weight: 600;
}

.skd-debt-customer-shown-container {
  padding: 10px;
}

.skd-debt-customer-shown-header h1 {
  font-size: 20px;
}

.skd-debt-customer-shown-remind {
  padding: 5px;
  margin-top: 10px;
}

.skd-debt-customer-shown-fetch {
  margin-top: 10px;
  border-radius: 5px;
  height: 60vh;
  overflow: scroll;
}

.skd-debt-customer-shown-list {
  padding: 5px;
}

.skd-debt-customer-shown-list h3 {
  font-weight: 600;
  font-size: 15px;
}

.skd-debt-customer-shown-remind p {
  font-size: 13px;
}

.skd-debt-customer-shown-clear {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: fit-content;
  justify-content: center;
  border-radius: 5px;
}

.skd-debt-customer-shown-clear:hover {
  background-color: rgb(235, 235, 235);
}

.skd-debt-customer-shown-clear:active {
  transform: scale(0.9);
}

.skd-debt-customer-shown-header {
  display: flex;
  padding: 5px;
  padding-bottom: 10px;
  align-self: start;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.skydart-nav-link-header {
  margin: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skd-debt-customer-shipment-fetch-header {
  width: 1100px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
}

.skd-debt-customer-shipment-fetch-body {
  width: 1100px;
  cursor: pointer;
}

.skd-debt-customer-shipment-fetch-body:hover {
  background-color: rgb(235, 235, 235);
}

.skd-debt-customer-shipment-primary {
  display: flex;
  align-items: center;
}

.skd-debt-customer-shipment-fetch-body:hover .skd-debt-sticky {
  background-color: rgb(235, 235, 235);
}

.skd-debt-customer-shipment-fetch-body1 {
  background-color: #eff3ff;
  width: 1100px;
  cursor: pointer;
}

.skd-debt-customer-shipment-fetch-body1:hover {
  background-color: #e6ecff;
}

.skd-debt-customer-shipment-fetch-body1:hover .skd-debt-sticky1 {
  background-color: #e6ecff;
}

.skd-debt-sticky {
  display: flex;
  position: sticky;
  left: 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 3.2px 0px 1px;
}

.skd-debt-sticky1 {
  display: flex;
  position: sticky;
  left: 0;
  background-color: #eff3ff;
  box-shadow: rgba(0, 0, 0, 0.15) 3.2px 0px 1px;
}

.skd-debt-customer-shipment-fetch-content {
  background-color: white;
  border-radius: 10px;
  height: 320px;
  overflow-y: hidden;
  overflow-x: scroll;
  box-shadow:
    0 0 8px rgb(0 0 0 / 12%),
    0 0 2px rgb(0 0 0 / 12%);
}

.skd-debt-customer-shipment-fetch-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 10px;
}

.skd-debt-customer-shipment-fetch-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-debt-customer-filtering {
  margin-top: 10px;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 10px;
  box-shadow:
    0 0 8px rgb(0 0 0 / 12%),
    0 0 2px rgb(0 0 0 / 12%);
}

.skd-debt-customer-filtering1 {
  display: flex;
  align-items: center;
}

.skd-debt-customer-filtering1-left {
  padding: 10px;
}

.skd-debt-customer-filtering1-left p {
  font-size: 12px;
}

.skd-debt-customer-filtering1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow:
    0 0 8px rgb(0 0 0 / 12%),
    0 0 2px rgb(0 0 0 / 12%);
}

.skd-debt-export-option {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  color: rgb(55, 55, 55);
}

.skd-debt-export-option:hover {
  background-color: rgb(230, 230, 230);
  color: black;
}

.skd-debt-export-option:active {
  background-color: rgb(215, 215, 215);
}

.skd-debt-export-option h5 {
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
}

.skd-debt-customer-filtering-searchBox {
  padding: 7px;
  display: flex;
  align-items: center;
}

.applied-icon {
  margin-left: 7px !important;
  padding: 5px !important;
  background-color: rgb(235, 235, 235) !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  width: 35px !important;
}

.skd-debt-customer-filtering-applied h5 {
  font-weight: 600;
}

.applied-icon:active {
  transform: scale(0.9);
}

.applied-icon:hover {
  background-color: rgb(224, 224, 224) !important;
}

.skd-debt-export-report {
  position: absolute;
  margin-top: 8px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow:
    0 0 8px rgb(0 0 0 / 12%),
    0 0 2px rgb(0 0 0 / 12%);
}

.skd-debt-customer-filtering-applied {
  padding: 7px;
  display: flex;
}

.skd-debt-customer-applied {
  padding: 7px;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
}

.skd-debt-customer-applied:hover {
  background-color: rgb(224, 224, 224) !important;
}

.skd-debt-customer-filtering-title {
  display: flex;
  margin-left: 7px;
  align-items: center;
  padding: 5px;
  user-select: none;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  cursor: pointer;
}

.skd-debt-customer-filtering-input input {
  margin-left: 7px;
  padding: 7.5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  outline: none;
  font-size: 13px;
}

.skd-debt-customer-filtering-input input:focus {
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #002fca;
  outline-offset: 2px;
  box-shadow: 0 0 2px dodgerblue;
}

.skd-debt-customer-filtering-apply {
  margin-left: 7px;
  padding: 7px;
  background-color: #2777f7;
  color: white;
  width: 80px;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-debt-customer-filtering-apply1 {
  margin-left: 7px;
  padding: 7px;
  background-color: #2777f7;
  color: lightgray;
  width: 80px;
  user-select: none;
  cursor: not-allowed;
  opacity: 0.7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-debt-customer-filtering-box {
  position: absolute;
  z-index: 9999;
  background-color: white;
  margin-top: 7px;
  width: 160px;
  padding: 5px;
  border-radius: 7px;
  box-shadow:
    rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skd-debt-customer-filtering-box3 {
  position: absolute;
  z-index: 9999;
  background-color: white;
  margin-top: 7px;
  width: 160px;
  height: 230px;
  overflow-y: scroll;
  padding: 5px;
  border-radius: 7px;
  box-shadow:
    rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skd-debt-customer-filtering-box-is {
  position: absolute;
  z-index: 9999;
  background-color: white;
  margin-top: 7px;
  width: 100px;
  padding: 5px;
  border-radius: 7px;
  box-shadow:
    rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skd-debt-customer-filtering-box-option {
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.skd-debt-customer-filtering-title1 {
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #002fca;
  outline-offset: 2px;
  box-shadow: 0 0 2px dodgerblue;
  display: flex;
  margin-left: 7px;
  align-items: center;
  padding: 5px;
  user-select: none;
  border-radius: 5px;
  cursor: pointer;
}

.skd-debt-customer-filtering-box-option:hover {
  background-color: rgb(235, 235, 235);
}

.skd-debt-customer-filtering-box-option:active {
  background-color: rgb(228, 228, 228);
}

.skd-debt-customer-filtering-apply:hover {
  background-color: #2067d8;
}

.skd-debt-customer-filtering-apply:active {
  transform: scale(0.96);
}

.skd-debt-customer-filtering-apply h5 {
  font-size: 13px;
  font-weight: 700;
}

.skd-debt-customer-filtering-apply1 h5 {
  font-size: 13px;
  font-weight: 700;
}

.skd-debt-customer-filtering-title:hover {
  border: 1px solid lightgray;
}

.skd-debt-customer-filtering-title h5 {
  font-weight: 500;
  margin-right: 5px;
  font-size: 13px;
}

.skd-debt-customer-filtering-title1 h5 {
  font-weight: 500;
  margin-right: 5px;
  font-size: 13px;
}

.skd-debt-customer-shipment-fetch-fixed {
  display: flex;
}

.skd-debt-customer-shipment-fetch-checkbox-header {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-date-header {
  padding: 10px;
  width: 80px;
  border-left: 1px solid rgb(230, 230, 230);
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-hawb-header {
  padding: 10px;
  width: 100px;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-destination-header {
  padding: 10px;
  width: 110px;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-weight-header {
  padding: 10px;
  width: 80px;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  justify-content: center;
}

.skd-debt-customer-shipment-fetch-type-header {
  padding: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-value-header {
  padding: 10px;
  width: 80px;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-payment-header {
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 60px;
  border-right: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-note-header {
  padding: 10px;
  width: 335px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-debt-customer-shipment-fetch-fixed h5 {
  font-size: 11px;
}

.skydart-nav-filter {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 7px;
  transition: 0.2s;
  cursor: pointer;
  background-color: rgb(231, 231, 231);
}

.skydart-nav-control {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.love-lucy {
  padding: 5px;
  border: 1px solid lightgray;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.1s;
}

.love-lucy:hover {
  background-color: lightgray;
}

.skydart-nav-control h3 {
  font-weight: 600;
  font-size: 15px;
}

.skydart-nav-control input {
  padding: 7px;
  margin-left: 10px;
  width: 100%;
  border: 1px solid #0068be;
  outline: none;
  border-radius: 5px;
  padding-right: 25px;
  transition: 0.1s;
}

.skydart-nav-control input:focus {
  border: 1px solid lightgrey;
}

.skydart-search-control {
  display: flex;
  align-items: center;
}

.skydart-nav-filter:hover {
  background-color: lightgray;
}

.control-dropdown {
}

.skydart-nav-filter h5 {
  font-size: 11px;
  font-weight: 600;
}

.skydart_skydart__new__booking__description {
  width: 350px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border-left: 1px solid lightgrey;
}

.skydart_skydart__new__booking__description:hover .skydart-bill-hawb-header-helper1 {
  display: block;
}

.skydart_skydart__new__booking__description h5 {
  padding: 5px;
}

.skydart-nav-link-info {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.skydart-nav-link-info1 {
  margin: 10px;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-left: 7px solid #025da8;
}

.skydart-main-shipment {
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 3px;
}

.skydart-shipment-fetch {
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-right: 20px;
  display: flex;
}

.skydart-main-shipment-filter {
  display: flex;
}

.skydart-main-shipment-filter-left {
  margin: 5px;
  display: flex;
  align-items: center;
  width: 70%;
  flex-wrap: wrap;
  flex-grow: 10px;
}

.skydart-main-shipment-filter-right {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: 0.1s;
  cursor: pointer;
  padding: 3px;
}

.skydart-main-shipment-filter-right:hover {
  background-color: rgb(233, 233, 233);
}

.skydart-main-shipment-sort-date {
}

.skydart-main-shipment-sort-date:hover {
  background-color: rgb(216, 216, 216);
}

.skydart-main-shipment-filter-right input {
  border: none;
  outline: none;
  padding: 3px;
  background-color: #f5f5f5;
  border-radius: 5px;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
}

.skydart-main-shipment-filter-date {
  padding: 8px;
  width: 100%;
}

.skydart-main-shipment-filter-date h5 {
  font-weight: 450;
  /* margin-left: 12px; */
  font-size: 14px;
  user-select: none;
}

.skydart-main-search {
  position: absolute !important;
  margin-left: 5px !important;
}

.skydart-main-clear-search {
  position: absolute !important;
  margin-top: -1px !important;
  cursor: pointer !important;
  right: 5px !important;
  transition: 0.1s !important;
  animation: popup 0.1s !important;
  border-radius: 999px !important;
}

.skydart-main-clear-search:hover {
  background-color: lightgrey;
}

.skydart-fetch-status {
  margin-left: 10px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
}

.skydart-fetch-status1 {
  margin-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}

.skydart-fetch-status:nth-child(9) {
  margin-right: 10px;
}

.skydart-fetch-status1:nth-child(9) {
  margin-right: 10px;
}

.check-length {
  padding: 3px;
  margin-top: -20px;
  border-radius: 999px;
  margin-left: -20px;
  background-color: #0068be;
  color: white;
  position: absolute;
}

.skydart-shipment-tool {
  margin-left: 20px;
  margin-right: 20px;
  border-top-left-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 7px;
  background-color: white;
}

.skydart-shipment-tool-left {
  display: flex;
}

.check-length h5 {
  font-weight: 600;
  font-size: 10px;
}

.skydart-fetch-left {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-fetch-left1 {
  display: flex;
  align-items: center;
  color: #0068be;
}

.skydart-bill {
  margin-left: 20px;
  margin-right: 20px;
  height: 70vh;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  overflow-y: scroll;
  overflow-x: auto;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: 1px solid lightgray;
}

.skd-user-shipment-fetch-container-body {
  display: flex;
}

.skd-user-shipment-fetch-container-body:hover {
  background-color: rgb(250, 250, 250);
}

.skd-user-shipment-fetch-container-body:hover .skd-user-shipment-fetch-sticky-body {
  background-color: rgb(250, 250, 250);
}

.skd-user-shipment-fetch-container-body:hover .skd-user-shipment-fetch-sticky-body3 {
  background-color: rgb(250, 250, 250);
}

.skydart-bill-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  width: 1400px;
  z-index: 9999px;
  background-color: white;
  border-top: 1px solid lightgray;
}

.skydart-bill-pagination {
}

.skydart-bill-container {
  /* border-bottom: 1px solid lightgray; */
  width: 5500px;
}

.skydart-bill-checkbox-header input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none !important;
  appearance: none !important;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff !important;
  /* Not removed via appearance */
  margin: 0 !important;
  font: inherit !important;
  color: #0068be !important;
  width: 1.15em !important;
  height: 1.15em !important;
  border: 0.15em solid lightgray !important;
  border-radius: 3px !important;
  transform: translateY(-0.075em) !important;
  display: grid !important;
  place-content: center !important;
  cursor: pointer !important;
}

.skydart-bookShipment-draft-checkbox input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none !important;
  appearance: none !important;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff !important;
  /* Not removed via appearance */
  margin: 0 !important;
  font: inherit !important;
  color: #0068be !important;
  width: 1.15em !important;
  height: 1.15em !important;
  border: 0.15em solid lightgray !important;
  border-radius: 3px !important;
  transform: translateY(-0.075em) !important;
  display: grid !important;
  place-content: center !important;
  cursor: pointer !important;
}

.skydart-new-booking-content-title input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none !important;
  appearance: none !important;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff !important;
  /* Not removed via appearance */
  margin: 0 !important;
  font: inherit !important;
  color: #0068be !important;
  width: 1.15em !important;
  height: 1.15em !important;
  border: 0.15em solid lightgray !important;
  border-radius: 5px !important;
  transform: translateY(-0.075em) !important;
  display: grid !important;
  place-content: center !important;
  cursor: pointer !important;
}

.skd-user-shipment-last-header input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none !important;
  appearance: none !important;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff !important;
  /* Not removed via appearance */
  margin: 0 !important;
  font: inherit !important;
  color: #0064d9 !important;
  width: 1.15em !important;
  height: 1.15em !important;
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  transform: translateY(-0.075em) !important;
  display: grid !important;
  margin-top: 3px !important;
  place-content: center !important;
  cursor: pointer !important;
}

.skydart-new-booking-content-title input[type='checkbox']:hover {
  border: 0.15em solid #0068be !important;
}

.skydart-bill-checkbox-header input[type='checkbox']:hover {
  border: 0.15em solid #0068be !important;
}

.skydart-bill-checkbox-header input[type='checkbox']:checked {
  border: 0.15em solid #0068be !important;
}

.skydart-bookShipment-draft-checkbox input[type='checkbox']:checked {
  border: 0.15em solid #0068be !important;
}

.skd-user-shipment-last-header input[type='checkbox']:checked {
  border: 1px solid lightgray !important;
}

.skydart-new-booking-content-title input[type='checkbox']:checked {
  border: 0.15em solid #0068be !important;
}

.skydart-bill-checkbox-header input[type='checkbox']::before {
  content: '' !important;
  width: 0.65em !important;
  height: 0.65em !important;
  transform: scale(0) !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  transform-origin: bottom left !important;
  background-color: #0068be !important;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important;
}

.skydart-bookShipment-draft-checkbox input[type='checkbox']::before {
  content: '' !important;
  width: 0.65em !important;
  height: 0.65em !important;
  transform: scale(0) !important;
  transition: 0.1s !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  transform-origin: bottom left !important;
  background-color: #0068be !important;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important;
}

.skydart-new-booking-content-title input[type='checkbox']::before {
  content: '' !important;
  width: 0.65em !important;
  height: 0.65em !important;
  transform: scale(0) !important;
  transition: 0.1s !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  transform-origin: bottom left !important;
  background-color: #0068be !important;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important;
}

.skd-user-shipment-last-header input[type='checkbox']::before {
  content: '' !important;
  width: 0.65em !important;
  height: 0.65em !important;
  transform: scale(0) !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  transform-origin: bottom left !important;
  background-color: #0064d9 !important;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important;
}

.skd-user-shipment-last-header input[type='checkbox']:checked::before {
  transform: scale(1) !important;
}

.skydart-bookShipment-draft-checkbox input[type='checkbox']:checked::before {
  transform: scale(1) !important;
}

.skydart-bill-checkbox-header input[type='checkbox']:checked::before {
  transform: scale(1) !important;
}

.skydart-new-booking-content-title input[type='checkbox']:checked::before {
  transform: scale(1) !important;
}

.skydart-bill-checkbox-header input[type='checkbox']:focus {
  border: 0.15em solid #0068be !important;
}

.skydart-new-booking-content-title input[type='checkbox']:focus {
  border: 0.15em solid #0064d9 !important;
}

.skd-user-shipment-last-header input[type='checkbox']:focus {
  border: 0.15em solid lightgray !important;
  outline: 1px solid #0064d9 !important;
  outline-offset: 1px;
  box-shadow: 0px 0px 3px 0px dodgerblue !important;
}

.skydart-shipment-tool-option {
  padding: 5px;
}

.skydart-selected-notification {
  padding: 5px;
}

.skydart-shipment-tool-right-option {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.skydart-shipment-tool-right-option h5 {
  font-weight: 600;
  font-size: 13px;
}

.skydart-shipment-tool-create-shipment {
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #0479da;
  color: white;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s;
}

.skydart-shipment-tool-create-shipment:hover {
  background-color: #006fca;
}

.skydart-main-search-show {
  padding: 10px;
}

.skydart-main-search-show-option {
  padding: 10px;
  cursor: pointer;
  color: gray;
  border-radius: 3px;
}

.skydart-main-search-show-option h5 {
  font-weight: 600;
  font-size: 13px;
}

.skydart-main-search-show-option:hover {
  color: black;
  background-color: rgb(230, 230, 230);
}

.skydart-shipment-tool-edit-shipment {
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: rgb(230, 230, 230);
  color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  transition: 0.1s;
  cursor: pointer;
}

.skydart-shipment-tool-edit-shipment1 {
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: rgb(230, 230, 230);
  justify-content: center;
  color: gray;
  transition: 0.1s;
  cursor: not-allowed;
}

.filter-list-shipment {
  padding: 3px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}

.filter-list-shipment:hover {
  background-color: rgb(230, 230, 230);
}

.filter-list-shipment:active {
  transform: scale(0.9);
}

.skydart-shipment-tool-edit-shipment1 h5 {
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.skydart-new-booking-consumer-book {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: green;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.skydart-new-booking-consumer-book:hover {
  opacity: 0.8;
}

.skydart-new-booking-consumer-book:active {
  transform: scale(0.9);
}

.skydart-new-booking-consumer-book button .skydart-notification {
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #0479da;
  color: white;
  justify-content: center;
  transition: 0.1s;
  margin-left: 20px;
}

.skydart-notification h5 {
  font-weight: 600;
  font-size: 11px;
  text-align: center;
}

.skydart-shipment-tool-edit-shipment:hover {
  background-color: lightgray;
}

.skydart-shipment-tool-edit-shipment h5 {
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.skydart-shipment-tool-req-res {
  display: flex;
  gap: 0.15em;
}

.req {
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
  color: rgba(0, 0, 0, 0.75);
}

.req1 {
  background-color: rgb(230, 230, 230);
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  cursor: not-allowed;
  transition: 0.1s;
}

.res {
  background-color: rgb(230, 230, 230);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  transition: 0.1s;
  color: rgba(0, 0, 0, 0.75);
}

.res1 {
  background-color: rgb(230, 230, 230);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: not-allowed;
  transition: 0.1s;
  color: rgba(0, 0, 0, 0.75);
}

.req:hover {
  background-color: lightgrey;
}

.res:hover {
  background-color: lightgray;
}

.skydart-shipment-tool-create-shipment h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart-fetch-left h5 {
  margin-left: 5px;
  font-size: 11px;
}

.skydart-fetch-left1 h5 {
  margin-left: 5px;
  font-size: 12px;
}

.skydart-main-shipment-filter-left input {
  padding: 7px;
  border-radius: 20px;
  border: 1px solid rgb(230, 230, 230);
  outline: none;
  width: 100%;
  padding-left: 27px;
  padding-right: 25px;
}

.skydart-main-shipment-filter-left input:focus {
  outline: 1px solid #005ca7;
  outline-offset: 2px;
  box-shadow: 0 0 3px #1395ff;
  border: 1px solid rgb(230, 230, 230);
}

.skydart-main-search-container {
  position: absolute;
  background-color: white;
  margin-top: 600px;
  z-index: 9999;
  width: 430px;
  border-radius: 10px;
  overflow-y: scroll;
  height: 550px;
  box-shadow:
    rgba(17, 17, 26, 0.3) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skydart-clear-search-button {
  margin-left: 10px;
  padding: 5px;
  color: #0165b8;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 5px;
}

.skydart-clear-search-button:active {
  transform: scale(0.95);
}

.skydart-shipment-break-down {
  position: absolute;
  z-index: 9999;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
  width: 230px;
  height: 320px;
  overflow-y: scroll;
  /* width: 122px; */
  background-color: white;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skydart-shipment-break-down h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart-shipment-break-down-option {
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
}

.skydart-shipment-break-down-option:hover {
  background-color: rgb(238, 238, 238);
}

.skydart-clear-search-button:hover {
  background-color: rgb(230, 240, 255);
}

.skydart-clear-search-button h5 {
  font-size: 12px;
}

.skydart-main-search-container-header {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.skydart-main-search-container-for-class {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-main-search-container-for-class:hover {
  background-color: rgb(233, 233, 233);
}

.skydart-main-search-container-header h3 {
  font-size: 15px;
}

.skydart-main-search-container-for-class h5 {
  font-weight: 600;
  font-size: 14px;
}

.warning-status {
  padding: 5px;
}

.warning-status strong {
  color: #0068be;
  text-decoration: underline;
  font-weight: 600;
  background: rgb(233, 243, 255);
}

.warning-status h5 {
  margin-left: 16px;
  font-weight: 600;
  color: black;
}

.skydart-new-booking {
  margin: 20px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
}

.skydart-new-booking-header {
  padding: 10px;
  display: flex;
  align-items: center;
}

.skydart-new-booking-filter {
  padding-left: 10px;
}

.skydart-new-booking-content {
  margin: 10px;
  border-top: 1px solid rgb(230, 230, 230);
}

.skydart-new-booking-filter p {
  font-size: 12px;
  margin-right: 5px;
}

.skydart-new-booking-filter input {
  padding: 5px;
  border: none;
  outline: none;
  width: 100%;
}

.skydart-new-booking-header h5 {
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}

.skydart-new-booking-date {
  display: flex;
  align-items: center;
}

.skydart-new-booking-date input {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  border: 2px solid lightgray;
  outline: none;
  border-radius: 5px;
}

.skydart-new-booking-date input:focus {
  border: 2px solid dodgerblue;
}

.skydart-new-booking-search {
  display: flex;
  align-items: center;
  border: 2px solid lightgray;
  transition: 0.2s;
  border-radius: 5px;
  width: 22%;
  margin-top: 5px;
}

.skydart-new-booking-sort {
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-radius: 5px;
  width: 5%;
  margin-top: 5px;
  margin-left: 5px;
  background-color: rgb(230, 230, 230);
}

.skydart-new-booking-sort select {
  padding: 5px;
  border: none;
  background-color: rgb(230, 230, 230);
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.skydart-new-booking-content-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 5px;
  position: sticky;
  top: 0;
  background-color: white;
}

.skydart-new-booking-content-body {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 5px;
}

.skydart-new-booking-export {
  margin-left: 5px;
  padding: 6px;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  cursor: pointer;
  margin-top: 5px;
}

.skydart-new-booking-export h5 {
  font-size: 12px;
  font-weight: 600;
}

.skydart-new-booking-content-body:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.skydart-new-booking-content-body:hover {
  background-color: rgb(238, 238, 238);
}

.skydart-new-booking-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-new-booking-content-title h5 {
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}

.skydart-new-booking-content-caption h5 {
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}

.skydart-new-booking-content-title:nth-child(1) {
  width: 3%;
}

.skydart-new-booking-content-title:nth-child(2) {
  width: 2%;
}

.skydart-new-booking-content-title:nth-child(3) {
  width: 3%;
}

.skydart-new-booking-content-title:nth-child(4) {
  width: 12%;
}

.skydart-new-booking-content-title:nth-child(5) {
  width: 10%;
}

.skydart-new-booking-content-title:nth-child(6) {
  width: 10%;
}

.skydart-new-booking-content-title:nth-child(7) {
  width: 10%;
}

.skydart-new-booking-content-title:nth-child(8) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(9) {
  width: 12%;
}

.skydart-new-booking-content-title:nth-child(10) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(11) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(12) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(13) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(14) {
  width: 5%;
}

.skydart-new-booking-content-title:nth-child(15) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(1) {
  width: 3%;
}

.skydart-new-booking-content-caption:nth-child(2) {
  width: 2%;
}

.skydart-new-booking-content-caption:nth-child(3) {
  width: 3%;
}

.skydart-new-booking-content-caption:nth-child(4) {
  width: 12%;
  white-space: nowrap;
  overflow: hidden;
}

.skydart-new-booking-content-caption:nth-child(5) {
  width: 10%;
}

.skydart-new-booking-content-caption:nth-child(6) {
  width: 10%;
}

.skydart-new-booking-content-caption:nth-child(7) {
  width: 10%;
}

.skydart-new-booking-content-caption:nth-child(8) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(9) {
  width: 12%;
}

.skydart-new-booking-content-caption:nth-child(10) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(11) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(12) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(13) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(14) {
  width: 5%;
}

.skydart-new-booking-content-caption:nth-child(15) {
  width: 5%;
}

.skydart-new-booking-body {
  height: 55vh;
  overflow-y: scroll;
}

.filter-list-icon {
  cursor: pointer !important;
  margin-top: 2px !important;
  transition: 0.1s !important;
}

.filter-list-icon:hover {
  background-color: lightgray !important;
}

.skydart-new-booking-title-left {
  display: flex;
  align-items: center;
}

.skydart-new-booking-title-left select {
  border: none;
  outline: none;
  width: 25%;
}

.sort-list-icon {
  cursor: pointer !important;
  margin-top: 5px !important;
  transition: 0.1s !important;
  font-size: 17px !important;
}

.sort-list-icon:hover {
  background-color: lightgray !important;
}

.skydart-new-booking-filter-option {
  position: absolute;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  margin-top: 250px;
  z-index: 9999;
  border-radius: 5px;
}

.skydart-bill-type-container {
  position: absolute;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 5px;
  margin-top: 135px;
}

.sorted-type-list {
  width: 120px;
}

.scheduling-date {
  background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50%
    no-repeat;
}

.scheduling-date::-webkit-inner-spin-button {
  display: none;
}

.scheduling-date::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.scheduling-date {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 130px;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 6px;
}

.skydart-user-update-loading {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  right: 25px;
  top: 90%;
  background-color: white;
  padding: 5px;
  height: 45px;
  align-items: center;
  border-radius: 5px;
  display: flex;
  width: 300px;
  border-right: 7px solid #2771c5;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.skydart-user-update-err {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  right: 25px;
  top: 90%;
  background-color: white;
  padding: 5px;
  height: 45px;
  align-items: center;
  border-radius: 5px;
  display: flex;
  width: 300px;
  border-right: 7px solid #f3c032;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.skydart-user-update-err-left {
  padding: 7px;
  color: white;
  background-color: #f3c032;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.skydart-user-update-loading-left {
  padding: 7px;
  color: white;
  background-color: #2771c5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgb(211, 213, 227);
  color: #2771c5;
}

.skydart-user-update-err-right {
  width: 255px;
  margin-left: 5px;
}

.skydart-user-update-loading-right {
  width: 255px;
  margin-left: 5px;
}

.type-sort {
  padding: 5px;
  cursor: pointer;
  transition: 0.1s;
}

.type-sort:hover {
  background-color: lightgrey;
}

.skydart-new-booking-filter-from {
  position: absolute;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  margin-top: 50px;
  border-radius: 5px;
}

.skydart-new-booking-filter-container {
  padding: 5px;
  display: flex;
  align-items: center;
}

.skydart-new-booking-filter-container input {
  padding: 3px;
  border: none;
  outline: none;
  width: 100%;
}

.sorted-customer-list {
  height: 20vh;
  width: 320px;
  overflow-y: scroll;
  border-bottom: 1px solid lightgray;
}

.sorted {
  padding: 10px;
  cursor: pointer;
  padding-left: 10px;
}

.sorted:hover {
  background-color: rgb(230, 230, 230);
}

.sorted-btn {
  margin: 5px;
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.sorted-btn button {
  padding: 6px;
  border: none;
  font-weight: 600;
  background-color: rgb(215, 233, 255);
  color: #0068be;
  outline: none;
  transition: 0.1s;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
}

.sorted-btn h5 {
  font-size: 13px;
  padding: 5px;
  border: none;
  font-weight: 600;
  background-color: whitesmoke;
  outline: none;
  transition: 0.1s;
  cursor: pointer;
  border-radius: 5px;
}

.sorted-btn h5:hover {
  background-color: lightgrey;
}

.sorted-btn button:hover {
  background-color: rgb(182, 212, 250);
}

.skydart-edit-shipment-header {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-edit {
  border-bottom: 1px solid lightgrey;
}

.skydart-edit-shipment-header h3 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-edit-shipment-body {
  padding: 10px;
}

.shipment-edit-shipment-body-option {
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}

.shipment-edit-shipment-body-option h5 {
  font-weight: 600;
  font-size: 11px;
}

.skydart-shipment-booked {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.2s;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.35);
}

.skydart-create-print {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.2s;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.26);
}

.skd-debt-customer-shipment-secondary {
  display: flex;
  margin-left: auto;
}

.skd-debt-customer-export {
  display: flex;
  margin-right: 5px;
  user-select: none;
  text-decoration: none;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  background-color: rgb(230, 230, 230);
  color: black;
}

.skd-debt-customer-export1 {
  display: flex;
  margin-right: 5px;
  user-select: none;
  text-decoration: none;
  padding: 7px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.5;
  align-items: center;
  background-color: rgb(230, 230, 230);
  color: black;
}

.skd-debt-customer-payment {
  cursor: pointer;
  padding: 7px;
  user-select: none;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  background-color: #1178cc;
  color: white;
  opacity: 0.9;
}

.skd-debt-customer-payment-container {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  margin-top: 9px;
  width: 435px;
  z-index: 9999;
  margin-left: -293px;
  animation: comeDown 0.2s;
  box-shadow:
    rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.2) 0px 2px 16px 0px;
}
.retriveShipment {
  animation: fadeIn 0.2s;
}
.retriveShipment1 {
  animation: popup 0.1s;
}
.skd-debt-customer-payment-bottom {
  padding: 5px;
  display: flex;
  align-items: center;
}

.skd-debt-customer-payment-bottom-cancel {
  margin-right: 5px;
  padding: 7px;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  width: 60px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-debt-customer-payment-bottom-update {
  margin-right: 8px;
  padding: 7px;
  background-color: #318bda;
  color: white;
  border-radius: 5px;
  width: 60px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-debt-customer-payment-bottom-update1 {
  margin-right: 8px;
  padding: 7px;
  background-color: #318bda;
  color: white;
  border-radius: 5px;
  width: 60px;
  opacity: 0.5;
  user-select: none;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-debt-customer-payment-bottom-update:hover {
  background-color: #2e84cf;
}

.skd-debt-customer-payment-bottom-update:active {
  background-color: #2a7dc5;
}

.skd-debt-customer-payment-bottom-cancel:hover {
  background-color: rgb(230, 230, 230);
}

.skd-debt-customer-payment-bottom-cancel:active {
  background-color: rgb(215, 215, 215);
}

.skd-debt-customer-payment-bottom-update p {
  font-size: 13px;
}

.skd-debt-customer-payment-bottom-update1 p {
  font-size: 13px;
}

.skd-debt-customer-payment-bottom-cancel p {
  font-size: 13px;
}

.skd-debt-customer-payment-bottom-right {
  margin-left: auto;
  display: flex;
}

.skd-debt-customer-payment-bottom p {
}

.skd-debt-customer-payment-header {
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 5px;
  padding-bottom: 0px;
}

.skd-debt-customer-payment-section {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.skd-debt-customer-payment-option {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.skd-debt-customer-payment-option:hover {
  background-color: rgb(245, 245, 245);
}

@keyframes comeDown {
  from {
    margin-top: 0px;
    opacity: 0;
  }

  to {
    margin-top: 9px;
    opacity: 1;
  }
}
.doxScript-box {
  animation: 0.3s doxShipScript ease;
}
@keyframes doxShipScript {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }
  50% {
    opacity: 0.5;
    width: 100%;
    height: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
    height: 380px;
  }
}

.skd-debt-arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  margin-top: -8px;
  user-select: none;
  margin-left: 312px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
}

.skd-debt-customer-payment-text {
  padding: 5px;
}

.skd-debt-customer-payment-text p {
  font-size: 11px;
}

.skd-debt-customer-payment-text textarea {
  margin-top: 5px;
  padding: 5px;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid rgb(201, 210, 231);
  outline: none;
  width: 97%;
  height: 90px;
  transition: 0.1s;
  resize: none;
  cursor: default;
  font-family: Arial, Helvetica, sans-serif;
}

.skd-debt-customer-payment-text textarea:focus {
  border: 1px solid #1367b1;
  cursor: text;
}

.skd-debt-filtering {
  margin-left: 5px;
  padding: 5px;
  width: 30px;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.skd-debt-filtering:hover {
  background-color: rgb(219, 219, 219);
}

.skd-debt-filtering:active {
  background-color: lightgray;
}

.selected-length-skd-debt {
  margin-right: 5px;
  background-color: #63be09;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 999px;
}

.selected-length-skd-debt p {
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
}

.skd-debt-customer-payment h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skd-debt-customer-payment:hover {
  opacity: 0.8;
}

.skd-debt-customer-payment:active {
  transform: scale(0.95);
}

.skd-debt-customer-export:hover {
  background-color: rgb(215, 215, 215);
}

.skd-debt-customer-export:active {
  transform: scale(0.95);
}

.skd-debt-customer-export h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skd-debt-customer-export1 h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skydart-debt {
  /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    animation: fadeIn 0.2s;
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.35); */
}

.skydart-debt-render {
  background-color: #f5f5f5;
  width: 100%;
  height: 100vh;
  display: flex;
}

.skydart-debt-side {
  padding: 10px;
  background-color: white;
  border-right: 1px solid rgb(230, 230, 230);
}

.skydart-create-print-container {
  background: white;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: rgba(0, 0, 0, 0.2) 2.4px 2.4px 3.2px;
  border-radius: 5px;
  margin-top: 15%;
  width: 35%;
  margin-left: 30%;
  margin-bottom: 70px;
  overflow: hidden;
  /* animation: popup 0.1s; */
}

.skydart-attachment-container {
  background: white;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: rgba(0, 0, 0, 0.5) 2.4px 2.4px 3.2px;
  border-radius: 5px;
  margin-top: 12%;
  width: 90%;
  margin-left: 7%;
  margin-bottom: 70px;
  overflow: hidden;
}

.skydart-attachment-header {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.skydart-attachment-header h3 {
  font-weight: 600;
  font-size: 17px;
}

.skydart-attachment-option-header {
  border-right: 1px solid lightgray;
}

.skydart-attachment-option-header h5 {
  font-size: 11px;
  font-weight: 600;
}

.skydart-attach-first {
  display: flex;
}

.skydart-manage-user-box {
  background: white;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: rgba(0, 0, 0, 0.35) 2.4px 2.4px 3.2px;
  border-radius: 5px;
  margin-top: 20%;
  width: 35%;
  margin-left: 32%;
  margin-bottom: 70px;
  overflow: hidden;
  animation: popup 0.1s;
}

.skydart-debt-side-choice {
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
  color: black;
}

.skd-nav-active {
  padding: 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: rgb(217, 235, 255);
  color: #0064d9;
}

.skydart-manage-user-box-header {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-debt-side-choice:hover {
  background-color: rgb(236, 236, 236);
}

.skydart-create-print-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-create-print-header h3 {
  font-weight: 600;
  font-size: 18px;
}

.skydart-create {
  position: fixed;
  top: 0;
  left: 60px;
  bottom: 0;
  right: 0;
  animation: fadeIn 0.2s;
  overflow: hidden;
}

.skydart-create-print-body {
  padding: 10px;
  display: flex;
  align-items: center;
}

.skydart-create-print-button {
  padding: 7px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  background-color: #1278cc;
  color: white;
  transition: 0.1s;
}

.skydart-create-print-button:hover {
  background-color: #0b61a8 !important;
}

.skd-user-date {
  position: absolute;
  z-index: 9999;
  margin-left: -285px;
  background-color: white;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-top: 10px;
  border-radius: 10px;
}

.skd-user-date-content {
  display: flex;
}

.skd-user-unselect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
}

.skd-user-date-left {
  padding: 5px;
  border-right: 1px solid rgb(230, 230, 230);
  color: rgb(173, 211, 255);
}

.skd-user-date-left-option {
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}

.skd-user-date-left-option {
  color: black;
}

.skd-user-date-shown {
  margin-top: 5px;
}

.skd-user-date-update-button {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.skd-user-shipment-err {
  width: 55%;
  position: fixed;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-user-date-update-cancel {
  padding: 7px;
  margin-right: 5px;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  width: 220px;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.skd-user-date-update-cancel:hover {
  background-color: rgb(230, 230, 230);
}

.skd-user-date-update-cancel:active {
  transform: scale(0.98);
}

.skd-user-date-update-cancel h5 {
  font-weight: 600;
}

.skd-user-date-update-succeed:hover {
  opacity: 0.8;
}

.skd-user-date-update-succeed:active {
  transform: scale(0.98);
}

.skd-user-date-update-succeed h5 {
  font-weight: 600;
}

.skd-user-date-update-succeed {
  padding: 7px;
  background-color: rgb(35, 123, 206);
  border-radius: 5px;
  width: 250px;
  color: white;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.skd-user-date-shown-range {
  padding: 5px;
  display: flex;
  align-items: center;
}

.skd-user-date-shown-range h5 {
  margin-right: 7px;
  padding: 7px;
  color: black;
  border-radius: 7px;
  border: 1px solid rgb(230, 230, 230);
}

.skd-user-date-left-option:hover {
  background-color: rgb(230, 230, 230);
}

.skd-user-date-left-option:active {
  background-color: rgb(218, 218, 218);
}

.skd-user-date-left-option h5 {
  font-size: 14px;
  font-weight: 450;
}

.skydart-create-print-button:hover {
  background-color: #046dc4;
}

.skydart-create-print-button1 {
  padding: 7px;
  margin-right: 5px;
  margin-left: 10px;
  width: fit-content;
  border-radius: 5px;
  background-color: rgb(236, 236, 236);
  cursor: pointer;
  margin-bottom: 10px;
}

.skydart-create-print-button1:hover {
  background-color: lightgrey;
}

.skydart-create-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 100vh;
  animation: sliceUp 0.1s;
}

.skydart-create-render {
  display: flex;
}

.skydart-create-side {
  padding: 5px;
  background-color: rgb(75, 75, 75);
  height: 100vh;
}

.skydart-create-side-option {
  background-color: rgb(124, 124, 124);
  color: white;
  transition: 0.1s;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}

.skydart-create-side-option1 {
  background-color: white;
  color: rgb(75, 75, 75);
  transition: 0.1s;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}

.skydart-create-title {
  background-color: white;
  height: 100vh;
  width: 280px;
  border-right: 1px solid lightgray;
}

.skydart-create-side-option1:hover {
  opacity: 0.8;
}

.skydart-create-title-header {
  padding: 13px;
  display: flex;
  border-bottom: 1px solid lightgray;
  align-items: center;
}

.skydart-refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 4px;
  width: 30px;
  background-color: rgb(245, 245, 245);
  margin-left: 10px;
}

.skydart-refresh-button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px;
  width: 25px;
  height: 25px;
  background-color: rgb(245, 245, 245);
  margin-left: 5px;
}

.skydart-refresh-button:active {
  transform: scale(0.9);
}

.skydart-refresh-button1:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-refresh-button1:active {
  transform: scale(0.9);
}

.skydart-debt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-debt-date {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: space-between;
}

.skydart-debt-body {
  margin-top: 20px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.skydart-debt-fetch {
  margin-top: 10px;
  width: 100%;
  background-color: white;
  box-shadow:
    0 0 5px 0 rgb(0 0 0 / 10%),
    0 0 1px 0 rgb(0 0 0 / 10%);
}

.skydart-debt-export-option {
  margin-left: auto;
  padding: 5px;
  display: flex;
}

.skydart-debt-search {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 300px;
}

.skydart-debt-search input {
  padding: 7px;
  border-radius: 999px;
  border: 1px solid lightgray;
  outline: none;
  width: 100%;
  padding-left: 30px;
}

.skydart-debt-search input:focus {
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #006fca;
  outline-offset: 2px;
  box-shadow: 0 0 2px #417fd1;
}

.skydart-debt-date h5 {
  font-weight: 600;
}

.skydart-debt-date input {
  padding: 6px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(235, 235, 235);
}

.skydart-debt-fetch-header {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border-bottom: 1px solid lightgray;
}

.skydart-debt-fetch-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.skd-debt-paginate {
  border-top: 1px solid lightgray;
}

.skd-debt-right-option {
  margin-right: 10px;
}

.skd-debt-right-option p {
  font-size: 13px;
}

.skydart-debt-fetch-title1 {
  display: flex;
  align-items: center;
  background-color: white;
}

.skydart-debt-fetch-content {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.skydart-debt-fetch-content1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgb(219, 234, 255);
  background-color: rgb(219, 234, 255);
}

.skydart-debt-fetch-content:active {
  background-color: rgb(235, 235, 235) !important;
}

.skydart-debt-fetch-content:hover {
  background-color: rgb(245, 245, 245);
}

.skydart-debt-fetch-content:hover .debt-customer-option {
  display: block;
}

.debt-sort-icon {
  font-size: 16px !important;
  margin-top: -5px !important;
}

.debt-title h5 {
  font-weight: 600;
  text-align: center;
  margin-right: 5px;
  margin-top: -5px;
  margin-left: auto;
}

.debt-customer {
  width: 30%;
  padding: 7px;
  display: flex;
  align-items: center;
  position: relative;
}

.debt-customer:hover .debt-customer-show-onHover {
  display: block;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-left: 200px;
  margin-top: 35px;
  width: 230px;
  border-radius: 5px;
}

.debt-customer-show-onHover p {
  font-size: 12px;
  color: white;
}

.debt-customer-show-onHover {
  position: absolute;
  display: none;
  z-index: 9999;
}

.debt-title {
  width: 10%;
  display: flex;
  padding: 7px;
  align-items: center;
}

.debt-customer h5 {
  margin-top: -4px;
  margin-right: 5px;
  font-weight: 600;
}

.skydart-debt-export {
  display: flex;
  align-items: center;
  background-color: #4495ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  transition: 0.1s;
}

.skydart-debt-export:nth-child(2) {
  padding: 9px;
  background-color: white;
  border: 1px solid lightgray;
  color: black;
}

.skydart-debt-export:nth-child(2):hover {
  background-color: rgb(230, 230, 230);
}

.skydart-debt-export:hover {
  background-color: #3083f0;
}

.skydart-debt-export h5 {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
  text-align: center;
}

.skydart-refresh-button:hover {
  background-color: rgb(235, 235, 235);
}

.skydart-create-side-option:hover {
  background-color: rgb(145, 145, 145);
}

.skydart-create-clear {
  padding: 3px !important;
}

.skydart-create-clear1 {
  padding: 3px !important;
}

.skd-debt {
  min-height: 30vh;
  max-height: 55vh;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-y: scroll;
  box-shadow:
    rgba(50, 50, 93, 0.15) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
}

.skd-debt::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.skd-debt::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@keyframes sliceUp {
  from {
    margin-top: 20px;
  }

  to {
    margin-top: 0px;
  }
}
.script-template {
  animation: sliceUp 0.25s;
}

.skd-user-paginate {
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  border-radius: 10px;
}

.skd-user-paginate-container {
  padding: 5px;
  display: flex;
  align-items: center;
}

.skydart-show-row {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.2s;
  overflow-y: scroll;
  background-color: none;
}

.skydart-shipment-note-container {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 7%;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: white;
  animation: fadeIn 0.2s;
  width: 41%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.skydart-show-row-container {
  margin-left: 70%;
  margin-right: 70%;
  margin-top: 23.5%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 10%;
}

.summary-skydart {
  padding-left: 10px;
  font-weight: 600;
  color: gray;
  font-size: 11px;
}

.shipment-edit-shipment-action {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-shipment-action-left {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.skydart-action-option select {
  padding: 5px;
  border: none;
  outline: none;
  background-color: rgb(230, 230, 230);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.1s;
}

.skydart-action-option select:hover {
  background-color: lightgrey;
}

.skydart-action-option button {
  padding: 7px;
  border: none;
  outline: none;
  background-color: rgb(230, 230, 230);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.1s;
}

.skydart-action-option button:hover {
  background-color: lightgrey;
}

.skydart-shipment-action-right {
  display: flex;
  gap: 0.3em;
}

.shipment-edit-shipment-body-option input {
  width: 55%;
  padding: 3px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  outline: none;
  transition: 0.1s;
}

.shipment-edit-shipment-body-option input:focus {
  border: 2px solid rgb(77, 108, 209);
}

.skydart-edit-invoice {
  animation: fadeRight 0.2s;
}

.skydart-edit-invoice-container {
  padding: 10px;
}

.skydart-edit-invoice-container-header h3 {
  font-weight: 600;
  font-size: 15px;
}

.skydart-edit-invoice-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skydart-edit-invoice-content h5 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.invoice-declared-primary input {
  padding: 5px;
  width: 96%;
  border: 2px solid lightgrey;
  transition: 0.1s;
  outline: none;
  border-radius: 5px;
  margin-top: 5px;
}

.invoice-declared-primary input:focus {
  border: 2px solid rgb(77, 108, 209);
}

.skydart-invoice-footer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-invoice-footer-header h5 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.print-invoice-skydart {
  padding: 5px;
  border: none;
  outline: none;
  background-color: #0068be;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s;
  margin-top: 5px;
}

.update-invoice-skydart {
  padding: 5px;
  border: none;
  outline: none;
  background-color: green;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s;
  margin-top: 5px;
}

.update-invoice-skydart:hover {
  background-color: darkgreen;
}

.print-invoice-skydart:hover {
  background-color: #025da8;
}

.shipment-selected-container1 {
  display: none;
}

.hawb-bill {
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}

.invoice-term {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}

@media print {
  .shipment-selected-container1 {
    display: block;
  }

  .hawb-bill {
    box-shadow: none !important;
  }

  .invoice-term {
    box-shadow: none !important;
  }

  .bill {
    width: 880px !important;
    page-break-after: always !important;
  }

  .invoice2 {
    /* height: 28.5cm !important; */
    padding-bottom: 0px !important;
    margin-bottom: 5px !important;
    page-break-after: always !important;
  }
  .invoice-valid {
    background-color: red !important;
  }

  .skydart-shipping-mark-container {
    box-shadow: none !important;
  }

  .skydart-booking-hawb {
    page-break-after: always !important;
  }
}

@keyframes fadeRight {
  from {
    margin-left: 20px;
  }

  to {
    margin-left: 0;
  }
}

.skydart-bill-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
}

.skydart-bill-header3 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
}

.skydart-bill-body {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid rgb(236, 236, 236); */
  /* border-bottom: 1px solid rgb(235,235,235) !important; */
  margin-top: -2px;
}

.selected-shipment {
  display: flex;
  align-items: center;
  background-color: rgb(122, 195, 255);
  /* border-bottom: 1px solid rgb(122, 195, 255); */
  /* border-bottom: 1px solid rgb(122,195,255); */
  margin-top: -2px;
}

.selected-shipment .skydart-sticky-body {
  background-color: rgb(122, 195, 255);
  /* border-bottom: 1px solid rgb(122, 195, 255); */
}

.selected-shipment .skydart-sticky-body2 {
  background-color: rgb(122, 195, 255);
  /* border-bottom: 1px solid rgb(122, 195, 255); */
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: black;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #4682f3;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.selected-shipment h5 {
  font-weight: 700;
  font-size: 12px;
}

.selected-shipment:hover {
  background-color: rgb(101, 186, 255);
}

.selected-shipment:hover .skydart-sticky-body {
  background-color: rgb(101, 186, 255);
}

.selected-shipment:hover .skydart-sticky-body2 {
  background-color: rgb(101, 186, 255);
}

.skydart-bill-body:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.skydart-bill-body:hover {
  background-color: rgb(235, 235, 235);
}

.skydart-bill-body:hover .skydart-sticky-body {
  background-color: rgb(235, 235, 235);
}

.skydart-bill-body:hover .skydart-sticky-body2 {
  background-color: rgb(235, 235, 235);
}

.skydart-bill-body:hover .skydart-bill-hawb-edit {
  opacity: 1;
}

.selected-shipment:hover .skydart-bill-hawb-edit {
  opacity: 1;
}

.skydart-bill-hawb-edit {
  opacity: 0;
  margin-top: 1px;
  position: absolute;
}

.skydart-bill-body:nth-last-child(1) {
  border-bottom: none;
}

.req-help {
  position: absolute;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 9px;
  font-weight: 600;
  margin-top: -25px;
  border-radius: 3px;
  display: none;
}

.skydart-bill-export {
  position: absolute;
  border-radius: 5px;
  margin-top: -20px;
  margin-left: -50px;
  background-color: white;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

.skydart-break-container {
  position: absolute;
  border-radius: 5px;
  margin-top: -13px;
  width: 180px;
  margin-left: 205px;
  background-color: white;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

.skydart-break-header {
  padding: 5px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.skydart-bill-export-container {
  padding: 5px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.skydart-bill-export-option {
  padding: 5px;
  transition: 0.1s;
  cursor: pointer;
  border-radius: 5px;
}

.skydart-break-option {
  padding: 5px;
  transition: 0.1s;
  cursor: pointer;
  border-radius: 5px;
}

.skydart-break-option h5 {
  font-size: 11px;
  font-weight: 600;
}

.skydart-break-option:hover {
  background-color: rgb(235, 235, 235);
}

.skydart-bill-export-option h5 {
  font-weight: 600;
  font-size: 11px;
}

.skydart-bill-export-option:hover {
  background-color: rgb(235, 235, 235);
}

.req:hover .req-help {
  display: block;
}

.req1:hover .req-help {
  display: block;
}

.res:hover .req-help {
  display: block;
}

.res1:hover .req-help {
  display: block;
}

.skydart-bill-customer-header {
  display: flex;
  align-items: center;
}

.skydart-bill-hawb-header-helper {
  position: absolute;
  font-size: 10.5px;
  padding: 3px;
  margin-top: 0px;
  top: 0;
  right: 0;
  display: none;
  margin-left: 10px;
  z-index: 9999;
  color: white;
  background-color: rgb(55, 55, 55);
}

.skydart-bill-hawb-header-helper1 {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  margin-left: 20px;
  z-index: 2;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background-color: white;
  width: 200px;
}

.skd-bill-helper-option {
  padding: 5px;
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
}

.skd-bill-helper-option p {
  font-size: 12px;
}

.skydart-bill-customer-header:hover .skydart-bill-hawb-header-helper {
  display: block;
}

.skydart-skydart-new-booking-check-icon {
  animation: popup 0.2s;
  background-color: rgb(255, 217, 210);
  padding: 3px;
  border-radius: 20px;
}

.skydart-skydart-new-booking-check-icon1 {
  animation: popup 0.2s;
  background-color: rgb(230, 252, 232);
  padding: 3px;
  border-radius: 20px;
}

.skydart_skydart__new__booking__customer:hover .skydart-bill-hawb-header-helper {
  display: block;
  margin-left: -1px;
  margin-top: 0;
}

.skydart-bill-customer-header-left {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.skydart-bill-info-header {
  display: flex;
}

.skydart-bill-payment-header {
  display: flex;
}

.skydart-bill-header h5 {
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-bill-header3 h5 {
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-bill-body h5 {
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-bill-carrier-header {
  display: flex;
}

.skydart-bill-rate-header {
  display: flex;
}

.skydart-bill-checkbox-header {
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-sticky-header {
  display: flex;
  align-items: center;
  width: 15%;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid transparent;
  z-index: 9999;
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.skydart-sticky-header1 {
  display: flex;
  align-items: center;
  width: 15%;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid rgb(230, 230, 230);
  z-index: 9999;
  /* box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15); */
}

.hihi {
  display: flex;
  align-items: center;
  width: 15%;
}

.skydart-sticky-body {
  display: flex;
  align-items: center;
  width: 15%;
  position: sticky;
  background-color: white;
  left: 0;
  border-top: 1px solid rgb(236, 236, 236);
  border-right: 1px solid transparent;
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.skydart-sticky-body2 {
  display: flex;
  align-items: center;
  border-right: 1px solid rgb(230, 230, 230);
  width: 15%;
  position: sticky;
  background-color: white;
  left: 0;
  border-top: 1px solid rgb(236, 236, 236);
  /* box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15); */
}

.skydart-bill-no-header {
  width: 5%;
  padding: 13px;
  border-right: 1px solid lightgray;
}

.skydart-bill-cus-search {
  font-size: 17px !important;
  padding: 3px !important;
  cursor: pointer !important;
  transition: 0.1s !important;
  margin-right: 2px !important;
  border-radius: 5px !important;
}

.skydart-bill-cus-search:hover {
  background-color: lightgrey !important;
}

.skydart-bill-cus-sort {
  font-size: 17px !important;
  padding: 3px !important;
  margin-right: 5px !important;
  cursor: pointer !important;
  transition: 0.1s !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}

.skydart-bill-cus-sort1 {
  font-size: 17px !important;
  margin-right: 2px !important;
  cursor: pointer !important;
  transition: 0.1s !important;
  border-radius: 5px !important;
}

.skydart-bill-sort-container {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.35) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.skydart-bill-cus-sort:hover {
  background-color: lightgrey !important;
}

.skydart-bill-cus-sort1:hover {
  background-color: lightgrey !important;
}

.skydart-bill-sort-content {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.skydart-bill-sort-content:hover {
  background-color: lightgrey;
}

.skydart-bill-sort-content h5 {
  margin-left: 5px;
  font-weight: 600;
  font-size: 13px;
}

.skydart-bill-class-header {
  width: 10%;
  padding: 13px;
  border-right: 1px solid lightgray;
}

.skydart-bill-customer-header {
  width: 45%;
  word-wrap: break-word;
  overflow: hidden;
}

.skydart-bill-hawb-header {
  width: 18%;
  padding: 13px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.skydart-bill-date-header {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-bill-from-header {
  width: 3%;
  padding: 10px;
}

.skydart-bill-empty-header {
}

.skydart-bill-to-header {
  width: 200px;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
}

.skydart-bill-status-header {
  width: 2.5%;
  padding: 10px;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-bill-type-header {
  width: 100px;
  padding: 10px;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
}

.skydart-bill-weight-header {
  width: 100px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-pcs-header {
  width: 70px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-packing-header {
  width: 110px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-airline-header {
  width: 110px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-service-header {
  width: 110px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-agent-header {
  width: 110px;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-mawb-header {
  width: 3%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-sin-header {
  width: 3%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-rate-header {
  width: 26%;
  border-right: 1px solid lightgray;
}

.skydart-bill-ship-rate-header {
  width: 10%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-address-header {
  width: 12%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-remote-header {
  width: 9%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-surcharge-header {
  width: 9%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-fsc-header {
  width: 7%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-fscValue-header {
  width: 9%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-vat-header {
  width: 7%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-vatValue-header {
  width: 10%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-total-header {
  width: 12%;
  padding: 10px;
  border-right: 1px solid lightgray;
}

.skydart-bill-last-update-header {
  width: 17%;
}

.skydart-bill-sale-header {
  width: 180px;
  padding: 10px;
  border-right: 1px solid lightgrey;
  white-space: nowrap;
  overflow: hidden;
}

.skydart-bill-perform-header {
  width: 2%;
  padding: 10px;
  border-right: 1px solid lightgrey;
}

.skydart-bill-payment-header {
  width: 5%;
}

.skydart-bill-pickup-header {
  width: 30%;
  padding: 10px;
  border-right: 1px solid lightgrey;
}

.skydart-bill-note-header {
  width: 2%;
  padding: 10px;
  border-right: 1px solid lightgrey;
}

.skydart-bill-note-header select {
  width: 20px;
  border: none;
  background: none;
  outline: none;
}

.skydart-date-range-container {
  position: absolute;
  background-color: white;
  right: 20px;
  margin-top: 5px;
  display: flex;
  z-index: 9999;
  display: flex;
  border-radius: 10px;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skydart-date-range-preset {
  padding: 5px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 5px;
}

.skydart-date-range-preset h5 {
  font-weight: 600;
}

.skydart-date-range-preset:hover {
  background-color: lightgrey;
}

.selecting-date {
  margin-bottom: 5px !important;
}

/* .selecting-date:nth-child(3) {
    background: red !important;
}
.selecting-date:nth-last-child(1) {
    background: red !important;
} */

.selecting-date:hover {
  background-color: rgb(221, 221, 221) !important;
  color: black !important;
  border-radius: 5px !important;
}

.selecting-date1 {
  margin-bottom: 5px !important;
}

.selecting-date1:hover {
  background-color: rgb(221, 221, 221) !important;
  color: black !important;
  border-radius: 5px !important;
}

.skydart-date-range-right-option p {
  font-size: 12px;
  margin-left: 20px;
}

.skydart-date-range-right-option1 p {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 600;
}

.skydart-date-range-right-option {
  padding: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.skydart-date-range-right-option1 {
  padding: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgb(225, 233, 255);
}

.skydart-date-range-right-option:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-date-range-right-option:active {
  background-color: rgb(219, 219, 219);
}

.skd-user-date-right {
  margin-left: 10px;
  margin-right: 10px;
}

.skydart-date-range-left {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.skydart-date-range-right {
  background-color: rgb(250, 250, 250);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 153px;
}

.skydart-date-range-left-header {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.skydart-date-range-left-header h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart-date-range-left-header input {
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  transition: 0.2s;
  border: 2px solid rgb(236, 236, 236);
  outline: none;
}

.skydart-date-range-left-header input:focus {
  border: 2px solid #0068be;
}

.skydart-date-range-left-calendar {
  margin-top: 10px;
}

.skydart-show-row-container {
  padding: 5px;
}

.skydart-create-title-type {
  padding: 5px;
  border-bottom: 1px solid rgb(235, 235, 235);
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.skydart-booking-form-header {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 1em;
}

.skydart-booking-account h5 {
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
}

.skydart-booking-form-header-left {
  padding: 3px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: #0173d1;
  background-color: rgb(213, 231, 255);
}

.skydart-booking-form-header-left h5 {
  font-size: 11px;
  margin-left: 5px;
  font-weight: 600;
}

.skydart-booking-form {
  flex: 1;
  height: 100vh;
  overflow: hidden;
}

.skydart-create-title-type h3 {
  font-weight: 600;
  font-size: 12px;
  margin-left: 5px;
  color: black;
}

.skydart-create-title-type-list {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);
  border-left: 3px solid transparent;
}

.skydart-create-title-type-list1 {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgb(27, 87, 177);
  background-color: rgb(231, 241, 255);
  border-left: 3px solid rgb(51, 116, 214);
}

.skydart-create-title-type-list1:hover {
  background-color: rgb(200, 223, 255);
}

.skydart-create-title-type-list1:hover h5 {
  text-decoration: underline;
}

.skydart-create-title-type-list:hover {
  background-color: rgb(233, 233, 233);
  color: black;
}

.skydart-create-title-type-list:hover h5 {
  text-decoration: underline;
}

.skydart-create-title-type-list1 h5 {
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 7px;
  color: black;
}

.skydart-create-title-type-list h5 {
  font-size: 11px;
  font-weight: 600;
  margin-left: 7px;
}

.skydart-show-row-option {
  padding: 5px;
  transition: 0.1s;
  border-radius: 5px;
  color: gray;
}

.skydart-show-row-option:hover {
  color: black;
  background-color: lightgrey;
}

.skydart-show-row-option h5 {
  font-weight: 600;
}

@keyframes sliceDown {
  from {
    margin-top: 70px;
  }

  to {
    margin-top: 75px;
  }
}
.text-country {
  animation: sliceDown10 0.15s;
}
.text-country1 {
  animation: sliceUp10 0.15s;
}
.hr-line-down {
  animation: sliceDownHr 2.5s;
}
@keyframes sliceDownHr {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }

  to {
    top: -201px;
    opacity: 1;
  }
}
@keyframes sliceUp10 {
  from {
    bottom: -190px;
    opacity: 0;
  }

  to {
    bottom: -201px;
    opacity: 1;
  }
}
@keyframes sliceDown10 {
  from {
    top: -190px;
    opacity: 0;
  }

  to {
    top: -201px;
    opacity: 1;
  }
}
.single-check:checked {
}

.pop {
  opacity: 0;
  margin-top: 1px;
}

.skydart-hawb-edit-option {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.skydart-hawb-edit-option {
  cursor: pointer;
}

.skydart-hawb-edit-option:hover {
  background-color: lightgrey;
  color: black;
  text-decoration: underline;
}

.paginate {
  display: flex;
  list-style: none;
}

.page-item {
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 999px;
  cursor: pointer;
  color: #0074d9;
}

.page-item1 {
  font-size: 12px;
  border: 1px solid #0074d9;
  color: white;
  border-radius: 999px;
  cursor: pointer;
  background-color: #0064d9;
}

.page-list {
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: #0074d9;
}

/* .active {
    color: white;
    background-color: red;
} */

.prev-btn-page {
  font-size: 25px !important;
}

.filter-applied {
  position: absolute;
  padding: 2px;
  margin-top: -27px;
  margin-left: 15px;
  background-color: rgb(226, 46, 46);
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  animation: popup 0.1s;
}

.skydart-bill-select-container {
  position: absolute;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.skydart-bill-select-option {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.skydart-bill-select-option:hover {
  background-color: lightgrey;
}

.skydart-bill-select-option h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skydart-booking-account-input {
  display: flex;
  align-items: center;
  width: 25%;
  margin-left: auto;
}

.skydart-booking-country-container {
  position: absolute;
  margin-top: 20px;
  margin-left: -85%;
  height: 25vh;
  width: 85%;
  overflow-y: auto;
  z-index: 9999;
  border-radius: 5px;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.skydart-booking-country h5 {
  font-weight: 600 !important;
  margin-left: 7px !important;
  font-size: 13px !important;
}

.skydart-booking-country {
  padding: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.skydart-booking-country:hover {
  background-color: #0175d4;
}

.skydart-booking-country:hover h5 {
  color: white !important;
}

.skydart-booking-account-input input {
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid lightgrey;
  transition: 0.1s;
  width: 100%;
  padding-left: 25px;
}

.skydart-booking-account-input input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-booking-account-option {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.skydart-booking-account-option h5 {
  margin-left: 5px;
}

.skydart-booking-account-option:hover {
  background-color: lightgrey;
}

.skydart-booking-account-render {
  position: absolute;
  margin-top: 200px;
  width: 17%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 3px;
  padding: 5px;
  background-color: white;
  height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.skydart-booking-nav-header {
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
}

.skydart-booking-edit {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.skydart-booking-edit1 {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  color: rgb(27, 87, 177);
  background-color: rgb(231, 241, 255);
}

.skydart-booking-edit:hover {
  background-color: #ececec;
}

.skydart-booking-edit h5 {
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.skydart-booking-edit1 h5 {
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.skydart-booking-box {
  height: 78%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
}

.skydart-booking-box1 {
  height: 78%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#label-file {
  display: none;
}

.label-attaching {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  justify-content: center;
  border-radius: 7px;
  background-color: rgb(235, 235, 235);
  color: black;
  margin-left: 5px;
  transition: 0.1s;
}

.label-attaching:hover {
  opacity: 0.8;
}

.label-attaching p {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
}

.skydart-booking-button {
  background-color: white;
  border-top: 1px solid lightgray;
  height: 100vh;
}

.skydart-booking-box-left {
  width: 60%;
}

.skydart-booking-box-right {
  width: 40%;
  margin-top: 5px;
}

.skydart-booking-box-first {
  margin: 10px;
  padding: 10px;
  margin-top: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
}

.skydart-booking-box-right-header {
  margin: 10px;
  padding: 10px;
  margin-top: 15px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.03) 3px 2.4px 3.2px;
  border-left: 5px solid #2c79df;
}

.skydart-booking-box-right-second-header {
  display: flex;
  align-items: center;
  margin: 5px;
  border-bottom: 1px solid rgb(228, 228, 228);
  color: rgba(0, 0, 0, 0.75);
}

.skydart-pack-value {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.skydart-pack-num-header {
  display: flex;
  margin-top: 3px;
}

.skydart-pack-num-header h5 {
  font-weight: 600;
}

.skydart-pack-num-body {
  display: flex;
  background-color: rgb(235, 235, 235);
  padding-top: 5px;
  align-items: center;
  padding-bottom: 5px;
  margin-top: 2px;
}

.skydart-pack-num-body-option {
  width: 25%;
}

.skydart-pack-num-body-option input {
  width: 50%;
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  padding: 3px;
  margin-left: 3px;
  border: 1px solid rgb(201, 201, 201);
}

.skydart-pack-num-body-option input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-pack-num-body-dim {
  width: 50%;
  display: flex;
  gap: 0.25em;
}

.skydart-pack-num-body-dim input {
  width: 30%;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 3px;
  border: 1px solid rgb(201, 201, 201);
  font-size: 12px;
}

.skydart-pack-num-body-dim input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-pack-num-header-option {
  width: 25%;
  display: flex;
  align-items: center;
}

.skydart-pack-copy {
  font-size: 10px !important;
  cursor: pointer !important;
  transition: 0.1s !important;
  border-radius: 1px !important;
}

.skydart-pack-copy:hover {
  background-color: lightgray;
}

.skydart-pack-option {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.35em;
  margin-right: 5px;
}

.skydart-pack-total-body-charge input {
  width: 50%;
  margin-left: 5px;
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  padding: 3px;
  border: 1px solid rgb(201, 201, 201);
  background-color: rgb(241, 241, 241);
}

.skydart-pack-total-body-charge input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-pack-num-header-dim {
  width: 50%;
}

.skydart-pack-total-header-charge {
  width: 25%;
  display: flex;
  align-items: center;
}

.skydart-pack-total-body-charge {
  width: 25%;
  display: flex;
  align-items: center;
}

.skydart-pack-value h5 {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
  font-size: 10px;
}

.skydart-booking-box-right-second-header h5 {
  margin-left: 5px;
  font-weight: 700;
  font-size: 13px;
}

.skydart-booking-box-right-second {
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.03) 3px 2.4px 3.2px;
}

.skydart-booking-box-right-total-pack {
  margin-top: 7px;
  margin-left: 5px;
}

.skydart-booking-box-right-total-pack h5 {
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
}

.skydart-booking-box-right-total-pack input {
  margin-top: 5px;
  padding: 3px;
  border: 1px solid lightgray;
  border-radius: 3px;
  outline: none;
  width: 88%;
}

.skydart-pack-add-btn {
  display: flex;
  margin-top: 7px;
}

.skydart-pack-add-btn button {
  margin-left: auto;
  margin-right: 0px;
  border: none;
  background-color: #4990ec;
  color: white;
  font-weight: 600;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
}

.skydart-pack-add-btn button:active {
  transform: scale(0.95);
}

.skydart-pack-add-btn button:hover {
  background-color: #105fc5;
}

.skydart-booking-box-right-total-pack input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-booking-box-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.skydart-booking-box-right-render-more {
  cursor: pointer;
  transition: 0.1s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skydart-booking-box-right-top-last {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
}

.skydart-box-right {
  padding: 5px;
  background-color: #2c79df;
  color: white;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 5px;
}

.skydart-box-right:hover {
  background-color: #1763c7;
}

.skydart-box-right h5 {
  font-weight: 600;
  font-size: 11px;
}

.skydart-booking-box-right-render-more:hover {
  background-color: lightgray;
}

.skydart-booking-box-first h5 {
  font-size: 15px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-booking-text-input {
  margin-top: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.skydart-booking-text-input1 {
  margin-top: 5px;
}

.skydart-booking-text-input1 input {
  padding: 8px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3px;
  outline: none;
  font-size: 15px;
  margin-right: 7px;
  width: 97%;
  margin-top: 5px;
}

.skydart-booking-text-input1 h5 {
  font-weight: 500;
  font-size: 11px;
  margin-right: 5px;
}

.skydart-booking-text-input input {
  padding: 8px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3px;
  outline: none;
  font-size: 15px;
  margin-right: 8px;
  width: 90%;
}

.skydart-booking-text-input select {
  padding: 8px;
  border: none;
  outline: none;
  background-color: none;
  font-weight: 600;
  font-size: 13px;
  color: #0363b8;
  cursor: pointer;
}

.skydart-booking-text-input input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-booking-text-input1 input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-booking-button-header {
  padding: 10px;
  padding-bottom: 0px;
}

.skydart-country-icon {
  width: 20px !important;
  margin-left: 5px !important;
  position: absolute !important;
}

.skydart-booking-button-header h5 {
  font-weight: 500;
  font-size: 11px;
}

.skydart-booking-button-body {
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid rgb(233, 233, 233);
}

.skydart-booking-container {
  margin-left: auto;
  margin-right: 15px;
  display: flex;
}

.skydart-booking-shipment {
  margin: 5px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #0074d9;
  color: white;
  cursor: pointer;
}

.skydart-booking-print {
  margin: 5px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #b12d2d;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.skydart-booking-print1 {
  margin: 5px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #b12d2d;
  color: white;
  opacity: 0.6;
  cursor: not-allowed;
  display: flex;
  align-items: center;
}

.skydart-booking-shipment1 {
  margin: 5px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #0074d9;
  color: white;
  opacity: 0.6;
  cursor: not-allowed;
}

.skydart-booking-shipment:hover {
  background-color: #0163b9;
}

.refresh-booking-shipment {
  margin: 5px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  cursor: pointer;
}

.skydart-booking-box-right-top-text {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.skydart-booking-box-right-top-text h5 {
  font-weight: 420;
  font-size: 12px;
}

.skydart-invoice-declare-container {
  margin: 5px;
  padding: 5px;
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
  margin-bottom: 10px;
}

.skydart-invoice-declare-invoice-header {
  display: flex;
}

.skydart-invoice-declare-name-header {
  width: 35%;
}

.skydart-invoice-declare-brand-header {
  width: 35%;
}

.skydart-invoice-declare-name-header h5 {
  font-weight: 600;
  font-size: 11px;
}

.skydart-invoice-declare-brand-header h5 {
  font-weight: 600;
  font-size: 11px;
  text-decoration: underline;
}

.skydart-invoice-declare-brand-header input {
  width: 84%;
  border: 1px solid rgb(214, 214, 214);
  outline: none;
  padding: 4px;
  font-size: 11px;
  border-radius: 3px;
}

.skydart-invoice-declare-brand-header select {
  width: 93%;
  border: 1px solid rgb(214, 214, 214);
  outline: none;
  padding: 4px;
  font-size: 11px;
  border-radius: 3px;
}

.skydart-invoice-declare-brand-header input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-invoice-declare-brand-header select:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-invoice-long h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart-invoice-long input {
  padding: 5px;
  border: 1px solid rgb(214, 214, 214);
  outline: none;
  width: 94%;
  border-radius: 3px;
  margin-top: 2px;
}

.skydart-invoice-long input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-term-selecting {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.skydart-term-selecting select {
  padding: 5px;
  border-radius: 3px;
  border: none;
  background-color: rgb(236, 236, 236);
  cursor: pointer;
  outline: none;
  transition: 0.1s;
}

.skydart-term-selecting select:hover {
  background-color: lightgrey;
}

.skydart-term-selecting input {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid lightgrey;
  outline: none;
  background-color: white;
  transition: 0.1s;
}

.skydart-term-selecting input:focus {
  border: 1px solid #2c79df;
  box-shadow: 0 0 3px #2c79df;
}

.skydart-new-booking-return-label {
  padding: 12px;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow:
    0 0 5px 0 rgb(0 0 0 / 10%),
    0 0 1px 0 rgb(0 0 0 / 10%);
}

.skydart-new-booking-return-label-left {
  display: flex;
  padding: 5px;
  margin-left: 10px;
  border: 1px solid rgb(230, 230, 230);
  user-select: none;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}

.skydart-new-booking-return-label-left:hover {
  background-color: rgb(235, 235, 235);
}

.skydart-new-booking-return-label-left:active {
  background-color: rgb(220, 220, 220);
}

.skydart-new-booking-return-label-input {
  display: flex;
  align-items: center;
}

.skydart-new-booking-label-input {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  outline: none;
  width: 320px;
}

.new-booking-err-plan {
  position: absolute;
  font-size: 10px;
  color: red;
  margin-left: 65px;
  margin-top: 42px;
}

.skydart-new-booking-label-input-err {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid red;
  border-radius: 5px;
  outline: none;
  width: 320px;
}

.skydart-new-booking-return-label-button {
  margin-left: auto;
}

.skydart-newbooking-refresh-icon {
  width: 25px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
}

.skydart-newbooking-helper {
  position: absolute;
  font-size: 12px;
  background-color: rgb(75, 75, 75);
  color: white;
  padding: 5px;
  display: none;
  z-index: 9999;
  margin-top: 70px;
  border-radius: 3px;
}

.skydart-newbooking-refresh-icon:hover .skydart-newbooking-helper {
  display: block;
}

.skydart-newbooking-refresh-icon1 {
  width: 25px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  cursor: not-allowed;
  opacity: 0.4;
}

.skydart-newbooking-refresh-icon:hover {
  background-color: rgb(220, 220, 220);
}

.skydart-newbooking-refresh-icon:active {
  transform: scale(0.9);
}

.skydart-new-booking-button-publish {
  margin-right: 5px;
  padding: 9px;
  background-color: #0173d1;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 0.9;
  margin-left: 5px;
  border-radius: 7px;
}

.skydart-new-booking-button-publish1 {
  margin-right: 5px;
  padding: 9px;
  background-color: #0173d1;
  color: white;
  border: none;
  margin-left: 5px;
  cursor: not-allowed;
  opacity: 0.6;
  border-radius: 7px;
}

.skydart-new-booking-loading-progress {
  width: 250px;
  z-index: 9999;
  position: absolute;
  margin-left: 1px;
}

.skydart-new-booking-button-publish:hover {
  opacity: 0.8;
}

.skydart-new-booking-button-publish:active {
  transform: scale(0.96);
}

.skydart-new-booking-label-input:focus {
  outline: 1px solid #005ca7;
  outline-offset: 2px;
  box-shadow: 0px 0px 3px dodgerblue;
}

.skydart-new-booking-return-label-left h5 {
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}

.skydart-new-booking-return-label-left p {
  font-size: 8px;
  color: gray;
  margin-top: -8px;
  position: absolute;
}

.skydart__new__booking__header {
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.skydart__new__booking__header__left {
  width: 80%;
}

.skydart__new__booking__header__right {
  width: 20% !important;
  margin-left: 10px !important;
  margin-top: 53px !important;
  height: 72vh !important;
  animation: fadeIn 0.2s !important;
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}

.skydart-new-booking-hawb-list {
  padding: 5px;
  background-color: white;
  position: absolute;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.12) 0px 2px 16px 0px;
  width: 250px;
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  user-select: none;
  z-index: 9999;
  margin-left: 0px;
  border-radius: 5px;
  margin-top: 5px;
}

.skydart-new-booking-hawb-empty {
  width: 100%;
  height: 80px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skydart-new-booking-hawb-list-option {
  padding: 7px;
  cursor: pointer;
  border-radius: 3px;
}

.skydart-new-booking-hawb-list-option h5 {
  font-weight: 480;
  font-size: 13px;
}

.skydart-new-booking-hawb-list-option:hover {
  background-color: rgb(240, 240, 240);
}

.skd-edit-new-booking-pack-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skydart__new__booking__header h3 {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
}

.skydart__new__booking__body {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
}

.skydart__new__booking__body__left {
  margin-top: 15px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow:
    0 0 5px 0 rgb(0 0 0 / 10%),
    0 0 1px 0 rgb(0 0 0 / 10%);
}

.skydart__new__booking__container__header {
  padding: 5px;
  border-bottom: 1px solid rgb(233, 233, 233);
  display: flex;
  align-items: center;
}

.skydart__new__booking__container__header__option {
  margin: 2px;
  padding: 7px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: rgb(233, 243, 255);
  color: #0360ac;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
}

.skydart-new-booking-noti {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -13px;
  padding: 2px;
  top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  background-color: red;
}

.skydart-new-booking-noti p {
  font-size: 11px;
  font-weight: 600;
}

.skydart__new__booking__container__header__option2 {
  margin: 2px;
  position: relative;
  padding: 7px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.skydart__new__booking__container__header__option h5 {
  margin-left: 5px;
}

.skydart__new__booking__container__header__option2 h5 {
  margin-left: 5px;
}

.skydart_skydart_new_select {
  border-radius: 999px;
  background-color: #0176d6;
  color: white;
  opacity: 0.9;
  padding: 3px;
}

.skydart_skydart_new_select h5 {
  font-size: 12px;
  font-weight: 600;
}

.skydart__new__booking__container__header__option2:hover {
  background-color: rgb(238, 238, 238);
}

.skydart__new__booking__container__header__option1 {
  margin: 2px;
  padding: 7px;
  background-color: white;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  border: 1px solid rgb(233, 233, 233);
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  transition: 0.1s;
}

.skydart__new__booking__container__header__option2:active {
  transform: scale(0.9);
}

.skd-user-shipment-fetch-sticky-header {
  display: flex;
  align-items: center;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid rgb(230, 230, 230);
  z-index: 9999;
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.skd-user-shipment-fetch-header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  background-color: white;
}

.skd-user-shipment-fetch-header1 {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
}

.skd-user-shipment-fetch-sticky-header3 {
  display: flex;
  align-items: center;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid rgb(230, 230, 230);
  z-index: 9999;
}

.skd-user-shipment-fetch-sticky-body {
  display: flex;
  align-items: center;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid rgb(230, 230, 230);
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.skd-user-shipment-fetch-sticky-body3 {
  display: flex;
  align-items: center;
  background-color: white;
  left: 0;
  position: sticky;
  border-right: 1px solid rgb(230, 230, 230);
}

.skydart__new__booking__container__header__option1:hover {
  background-color: rgb(233, 233, 233);
}

.skydart__new__booking__container__header__option1 h5 {
  font-size: 11px;
  font-weight: 700;
  margin-left: 5px;
}

.skydart__new__booking__container__header__option2 h5 {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}

.skydart__new__booking__container__header__option h5 {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}

.skydart__new__booking__option__wrap {
  display: flex;
  align-items: center;
}

.skydart__new__booking__option__wrap1 {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* .skydart__new__booking__body {
    display: flex;
} */
.skydart__new__booking__search__box {
  margin: 5px;
  display: flex;
  align-items: center;
  width: 20%;
}

.skydart__new__booking__search__box input {
  padding: 7px;
  border: none;
  outline: none;
  margin-right: 5px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  padding-left: 25px;
  width: 100%;
  margin-left: 5px;
  transition: 0.02s;
}

.skydart__new__booking__search__box input:focus {
  border: 1px solid #0068be;
}

.skydart__new__booking__date__box input {
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  font-family: Arial;
  width: 40%;
}

.skydart__new__booking__filter__container {
  display: flex;
  align-items: center;
}

.skydart__new__booking__date__box {
  margin: 5px;
  display: flex;
  align-items: center;
}

.skydart__new__booking__date__box h5 {
  font-size: 11px;
  font-weight: 600;
  width: 30%;
  color: gray;
}

.skydart__new__booking__shipment__header {
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(230, 230, 230);
  border-left: 3px solid white;
}

.skydart-new-booking-sticky {
  display: flex;
  align-items: center;
  align-self: start;
  position: sticky;
  background-color: white;
  left: 5px;
  z-index: 2;
  box-shadow: 3px 0 3px -2px rgba(0, 0, 0, 0.2);
}

.skydart__new__booking__shipment__body:hover .skydart-new-booking-sticky {
  background-color: rgb(237, 237, 237);
  transition: 0.1s;
}

.skydart__new__booking__shipment__body:hover .skydart_skydart__new__booking__description {
  background-color: rgb(237, 237, 237);
  background-image: none;
  transition: 0.1s;
}

.selected-shipment1:hover .skydart_skydart__new__booking__description {
  background-color: rgb(212, 231, 255);
  background-image: none;
  transition: 0.1s;
}

.skydart__new__booking__shipment__body {
  display: flex;
  border-left: 3px solid white;
  transition: 0.1s;
  border-bottom: 1px solid rgb(240, 240, 240);
  cursor: pointer;
}

.skydart-multi-cancel {
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: rgb(245, 245, 245);
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid rgb(230, 230, 230);
  margin-right: 10px;
}

.skydart-multi-booked {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-left: auto;
  background-color: rgb(245, 245, 245);
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid rgb(230, 230, 230);
  margin-right: 5px;
}

.skydart-multi-cancel:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-multi-cancel:active {
  background-color: lightgray;
}

.skydart-multi-booked:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-multi-booked:active {
  background-color: lightgray;
}

.skydart-multi-booked h5 {
  margin-left: 5px;
  font-size: 12px;
}

.skydart-multi-cancel h5 {
  margin-left: 2px;
  font-size: 12px;
}

.skydart__new__booking__shipment__body_multi {
  border-left: 3px solid white;
  border-bottom: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px;
  transition: 0.1s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.skydart-booking-multi-content-bottom {
  animation: fadeIn 0.1s;
  display: flex;
}

.skydart-new-booking-tree-container {
  padding: 5px;
}

.skydart-new-booking-tree-container button {
  border-radius: 2px;
  padding: 2px;
  cursor: pointer;
  animation: 0.1s;
  border: 1px solid rgb(230, 230, 230);
}

.skydart-new-booking-tree-container button:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-new-booking-tree-container button:active {
  transform: scale(0.9);
}

.skydart-new-booking-tree {
  border-left: 2px solid black;
}

.selected-shipment1_multi {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.1s;
  border-left: 3px solid #0360ac;
  border-bottom: 1px solid transparent;
}

.multi-label-container-newBooking h5 {
  padding: 3px;
  margin-right: 5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 999px;
  background-color: rgb(243, 243, 243);
  text-align: center;
  width: 130px;
  margin-top: 5px;
}

.multi-label-loading {
  display: flex;
  margin-left: 12px;
}

.skydart-booking-dus-booked-button button {
  margin: 5px;
  padding: 7px;
  border: none;
  font-weight: 600;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  font-size: 15px;
  border-radius: 999px;
  outline: none;
  cursor: pointer;
  background-color: #005ca7;
  color: white;
  width: 510px;
}

.skydart-booking-agent-container {
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  margin-left: 5px;
}

.skydart-booking-agent-container:hover {
  background-color: rgb(220, 220, 220);
}

.skydart__new__booking__date__box select {
  margin-right: 5px;
  padding: 7px;
  border: 1px solid rgb(230, 230, 230);
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.default-date-input-skd {
  border: 1px solid lightgray !important;
  width: 115px !important;
  display: flex !important;
  align-items: center !important;
  padding: 5px !important;
  margin-right: 5px !important;
}

.skd-new-booking-single-date {
  display: flex;
  align-items: center;
}

.skydart__new__booking__date__box select:hover {
  background-color: lightgray;
}

.skydart-booking-agent-container:active {
  background-color: lightgray;
}

.skydart-booking-dus-booked-button button:hover {
  opacity: 0.8;
}

.skydart-booking-dus-booked-button button:active {
  opacity: 0.5;
}

.multi-label-container-newBooking h5:first-child {
  color: darkblue;
  background-color: white;
}

.multi-label-container-newBooking h5:active {
  outline: 1px solid #002fca;
  outline-offset: 1.5px;
}

.multi-label-container-newBooking h5:hover {
  background-color: rgb(230, 230, 230);
}

.multi-label-container-newBooking {
  display: flex;
  margin-left: 3px;
  flex-wrap: wrap;
}

.selected-shipment1 {
  background-color: rgb(233, 243, 255);
  transition: 0.1s;
  color: #0360ac;
  display: flex;
  border-left: 3px solid #0360ac;
  border-bottom: 1px solid rgb(233, 243, 255);
}

.skydart-bill-edit-header1:hover {
  border-radius: 5px;
  background-color: black;
  cursor: pointer;
  color: white;
}

.skydart-bill-edit-header1 {
  padding: 1px;
  transition: 0.1s;
}

.selected-shipment1 .skydart_skydart__new__booking__description {
  background-color: rgb(233, 243, 255);
  background-image: none;
}

.selected-shipment1 .skydart-new-booking-sticky {
  background-color: rgb(233, 243, 255);
}

.skydart-booking-multi-content-bottom-sticky {
  width: 790px;
  position: sticky;
  left: 5px;
  display: flex;
  background-color: white;
  z-index: 2;
}

.multi-dus-booking-input {
  margin: 5px !important;
  width: 250px !important;
}

.skydart-booking-multi-input {
  margin-top: 5px;
}

.selected-shipment1:hover {
  background-color: rgb(212, 231, 255);
  border-left: 3px solid #0360ac;
}

.selected-shipment1:hover .skydart-new-booking-sticky {
  background-color: rgb(212, 231, 255);
}

.selected-shipment1 h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart-booking-service-background {
}

.skydart__new__booking__shipment__body:hover {
  background-color: rgb(237, 237, 237);
  border-left: 3px solid rgb(245, 245, 245);
  transition: 0.1s;
}

.skydart__new__booking__shipment__body:hover .pop {
  opacity: 1;
}

.skydart__new__booking__shipment__header h5 {
  font-weight: 700;
  font-size: 12px;
  color: gray;
}

.skydart__new__booking__shipment__body h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart__new__booking__shipment__body_multi h5 {
  font-weight: 600;
  font-size: 12px;
}

.selected-shipment1_multi h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart_skydart__new__booking__no {
  width: 35px;
  margin-left: 5px;
  margin-right: 5px;
}

.skydart_skydart__new__booking__no h5 {
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
}

.skydart_skydart__new__booking__customer {
  width: 190px;
  padding: 5px;
  position: relative;
}

.skydart_skydart__new__booking__hawb {
  width: 120px;
  padding: 5px;
}

.skydart_skydart__new__booking__dusAWB {
  padding: 5px;
  width: 120px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.skydart_skydart__new__booking__date {
  width: 125px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.skydart_skydart__new__booking__destination {
  width: 150px;
  padding: 5px;
}

.skydart_skydart__new__booking__info {
  width: 55px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.skydart_skydart__new__booking__note {
  width: 100px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 5px;
}

.skydart_skydart__new__booking__type {
  width: 90px;
  margin-left: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.skydart_skydart__new__booking__booked {
  padding: 2px;
}

.skydart-new-wrap {
  overflow-y: scroll;
  overflow-x: auto;
  height: calc(100vh - 17rem);
}

.skydart-booking-multi-label {
  padding: 5px;
}

.skydart_skydart_drop {
  margin-left: auto !important;
  font-size: 23px !important;
  cursor: pointer !important;
  border-radius: 7px !important;
  color: gray !important;
}

.skydart_skydart_drop:hover {
  background-color: rgb(223, 223, 223);
  color: black;
}

.skydart-rate-container {
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: white;
  width: 650px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.skydart-bill-action {
  position: absolute;
  background-color: white;
  margin-left: 60px;
  margin-top: -20px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.skydart_new_booking_filter_container {
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  border-radius: 5px;
  margin-left: -20px;
  margin-top: 18px;
  width: 80px;
  z-index: 9999;
}

.skydart-new-booking-return-label-file-preview {
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 20px;
  margin-left: 10px;
  transition: 0.1s;
}

.skydart-new-booking-return-label-file-preview:hover {
  background-color: rgb(230, 230, 230);
}

.skydart-packing-radio-left {
  display: flex;
  margin-right: 5px;
  align-items: center;
}

.skydart-packing-radio-left label {
  margin-left: 10px;
}

.skydart-packing-show-bag-option p {
  font-size: 11px;
}

.skydart-packing-radio {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.skydart-packing-show-bag-option {
  padding: 10px;
}

.skydart-new-booking-return-label-file-preview h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skydart-bill-action-option {
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.skydart-bill-action-option1 {
  padding: 5px;
  border-radius: 3px;
}

.skydart-bill-action-option1 h3 {
  font-size: 15px;
  border-bottom: 1px solid lightgray;
}

.skydart-bill-action-option:hover {
  background-color: rgb(230, 230, 230);
}

.skydart_new_booking_filter {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.skydart_new_booking_filter:hover {
  background-color: lightgray;
}

.skydart_new_booking_filter_option {
  padding: 5px;
}

.skydart_new_type {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.skydart_new_type:hover {
  background-color: rgb(240, 240, 240);
}

.skydart_new_type h5 {
  font-weight: 600;
  font-size: 12px;
}

.skydart__new__booking__container__action {
  position: absolute;
  background-color: white;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  border-radius: 5px;
  margin-top: 125px;
  margin-left: -10px;
  width: 10%;
  z-index: 9999;
}

.skydart__new__booking__container__option5 {
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
}

.label-input-text {
  margin-left: 5px;
  padding: 7px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  outline: none;
}

.label-input-text:focus {
  outline: 1px solid #005ca7;
  outline-offset: 1.5px;
}
.label-input-text:focus {
  outline: 1px solid #005ca7;
  outline-offset: 1.5px;
}
.label-input-text1:focus {
  outline: 1px solid #005ca7;
  outline-offset: 1.5px;
}
.skydart__new__booking__container__option5:hover {
  background-color: lightgray;
}

.skydart__new__booking__header__right {
  width: 20%;
  padding: 10px;
  height: 100% !important;
}

.skydart__new__booking__header__right h5 {
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.skydart__new__booking__receive__account {
  margin-top: 10px;
  margin-left: 1px;
}

.skydart__new__booking__header__right__top {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.skydart__new__booking__receive__box {
  display: flex;
  padding: 2px;
  align-items: center;
  margin-top: 5px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 3px;
  width: 97%;
}

.skydart__new__booking__receive__box input {
  padding: 5px;
  width: 100%;
  border: none;
  font-size: 11px;
  outline: none;
  background-color: white;
}

.skydart__new__booking__label__list {
  margin-top: 10px;
  margin-left: 2px;
}

.skydart__new__booking__label__list textarea {
  font-family: Arial;
  margin-top: 5px;
  padding: 5px;
  border: 1px soldi lightgray;
  border-radius: 3px;
  width: 95%;
  height: 20vh;
  font-size: 11px;
  background-color: white;
  resize: none;
}

.skydart_label_link {
  margin-top: 5px;
  padding-bottom: 10px;
}

.skydart_label_link input {
  margin-top: 7px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  width: 94%;
  font-size: 12px;
  background-color: white;
}

.skydart_label_link input:focus {
  border: 1px solid #027de2;
  box-shadow: 0 0 3px #027de2;
}

.skydart_label_link button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 7px;
  color: white;
  background-color: #0178da;
  cursor: pointer;
  width: 98%;
  font-size: 15px;
  font-weight: 600;
  margin-left: 2px;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skydart_label_link button:hover {
  background-color: #037fe4;
}

.skydart-receiving-box {
  position: absolute;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  margin-top: 500px;
  animation: sliceDown 0.1s;
  height: 60vh;
  margin-left: -5px;
  overflow-y: scroll;
  width: 18%;
  border-radius: 5px;
}

.skydart-receiving-box-option {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.skydart-receiving-box-option:hover {
  background-color: rgb(233, 233, 233);
}

.skydart_booking_list_header {
  padding: 15px;
  height: 30vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
}

.skydart_booking_list {
  margin-top: 15px;
}

.skydart_book_list {
  padding: 5px;
  cursor: pointer;
}

.skydart_book_list:hover {
  background-color: rgb(233, 233, 233);
}

.skydart-label-container {
  margin: 10px;
  display: flex;
  width: 800px;
}

.invoice2 {
  width: 21.2cm;
  padding-left: 35px;
  padding-bottom: 20px;
}
.invoice3 {
  /* width: 25cm; */
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.unit-after-edit {
  display: none !important;
}

.skydart-shipping-mark {
  margin: 20px;
}

.new-shipping-mark {
  margin: 15px;
  margin-top: 25px;
}

.skydart-shipping-mark-container-header {
  display: flex;
  border-bottom: 1px solid;
  justify-content: space-between;
}

.new-shipping-mark-container {
  width: 700px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}

.skydart-shipping-mark-container {
  padding: 10px;
  width: 800px;
  margin-left: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}

.new-shipping-mark-shipFrom {
  padding: 10px;
  position: relative;
}

.skydart-shipping-mark-container-header h1 {
  font-weight: 600;
  color: black;
  font-size: 47px;
  text-align: center;
}

.rotate-content {
  position: absolute;
  transform: rotate(-90deg);
  top: 52px;
  font-weight: 700;
  font-size: 13px;
  left: -15px;
}

.new-shipping-mark-shipFrom-info {
  margin-left: 23px;
}

.new-shipping-mark-shipFrom-info h5 {
  font-weight: 600;
  font-size: 14px;
}

.skydart-shipping-mark-container-header h3 {
  font-size: 30px;
  font-weight: 650;
  padding: 10px;
  color: black;
}

.skydart-shipping-mark-container-header h5 {
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.skydart-shipping-mark-body-option {
  padding: 10px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  width: 15%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skydart-shipping-mark-body-option h5 {
  font-size: 12px;
  font-weight: 600;
}

.skydart-shipping-mark-container-body {
  display: flex;
  align-items: center;
}

.skydart-shipping-mark-body-option1 {
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  width: 50%;
}

.skydart-shipping-mark-body-option1 h5 {
  font-size: 12px;
  font-weight: 600;
}

.skydart-shipping-mark-dim {
  display: flex;
  align-items: center;
  border-top: 1px solid;
}

.skydart-shipping-mark-dim h3 {
  font-size: 15px;
}

.shipping-mark-dim {
  width: 35%;
  border-left: 1px solid;
  text-align: center;
  padding: 10px;
}

.shipping-mark-dim:nth-child(1) {
  border-left: none;
}

.shipping-mark-dim:nth-last-child(1) {
  border-right: none;
}

.skydart-shipping-mark-booking-info {
  padding: 3px;
  display: flex;
}

.skydart-shipping-mark-booking-info h5 {
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}

.skydart-shipping-mark-booking-info h5:nth-child(1) {
  color: gray;
  font-weight: 500;
}

.skydart-shipping-mark-container-info {
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.dox-service {
  margin: 9px;
  padding: 7px;
  width: 63%;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgb(228, 228, 228);
  transition: 0.1s;
}

.dox-service:hover {
  background-color: lightgray;
}

.skydart-user {
  padding: 10px;
  background-color: #f5f7fa;
  /* background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 4%); */
  height: 100%;
}

.skydart-user-header {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  position: sticky;
  top: 10%;
}

.skydart-user-customer {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  margin-top: 10px;
}

.skd-user-shipment-action {
  background-color: white;
  display: flex;
  border-bottom: 1px solid rgb(230, 230, 230);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-fetch-user {
  margin-left: auto;
  display: flex;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  cursor: pointer;
  transition: 0.1s;
  border: 1px solid lightgray;
  align-items: center;
}

.dropdown-fetch-user1 {
  display: flex;
  border-radius: 5px;
  border: 1px solid transparent;
  color: lightgray;
  align-items: center;
}

.dropdown-fetch-user:active {
  transform: scale(0.95);
}

.dropdown-fetch-user:hover {
  background-color: rgb(230, 230, 230);
}

.skd-user-shipment-action-left {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 7px;
  border-radius: 7px;
  background-color: rgb(240, 240, 240);
  cursor: pointer;
  user-select: none;
}

.skd-user-help {
  display: none;
  position: absolute;
}

.skd-user-shipment-action-left:hover .skd-user-help {
  display: block;
  animation: fadeIn 0.1s;
  margin-top: 80px;
  margin-left: -8px;
  font-size: 11px;
  font-weight: 600;
  z-index: 9999;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
}

.skd-user-shipment-fetch {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 30vh;
  max-height: 64vh;
  overflow: auto;
}

.skd-users {
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-users1 {
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
}

.skd-user-shipment-fetch1 {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  border-radius: 10px;
  min-height: 30vh;
  max-height: 70vh;
  overflow: auto;
}

.skd-user-shipment-fetch::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

.skd-user-shipment-fetch::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-user-shipment-action-left:hover {
  background-color: rgb(230, 230, 230);
}

.skd-user-shipment-fetch-hawb {
  display: flex;
  align-items: center;
  width: 150px;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 10px;
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-fetch-country {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 10px;
  width: 120px;
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-fetch-country-action {
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  position: absolute;
  background-color: white;
  width: 200px;
  border-radius: 5px;
  margin-left: 120px;
}

.skd-user-shipment-fetch-country-option {
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  user-select: none;
  margin: 5px;
  border-radius: 5px;
}

.skd-user-shipment-fetch-country-option:hover {
  background-color: rgb(230, 230, 230);
}

.skd-user-shipment-fetch-country-option:active {
  background-color: rgb(220, 220, 220);
}

.skd-user-shipment-fetch-country-option h5 {
  margin-left: 5px;
  font-weight: 600;
}

.skd-user-shipment-fetch-country-action input {
  margin: 5px;
  padding: 7px;
  width: 85%;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
}

.skd-user-shipment-fetch-country-action input:focus {
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #037fe4;
  outline-offset: 1px;
  box-shadow: 0px 0px 3px 0px dodgerblue;
}

.skd-user-shipment-fetch-date {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  width: 120px;
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-fetch-type {
  padding: 10px;
  display: flex;
  width: 100px;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-fetch-weight {
  padding: 10px;
  display: flex;
  width: 170px;
  border-bottom: 1px solid rgb(230, 230, 230);
  align-items: center;
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-fetch-rest-header {
  display: flex;
}

.skd-user-shipment-fetch-weight h5 {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600;
}

.skd-user-shipment-fetch-type h5 {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600;
}

.skd-user-shipment-fetch-date h5 {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600;
  color: #0064d9;
}

.skd-user-shipment-fetch-country h5 {
  font-size: 13px;
  margin-left: 5px;
  font-weight: 600;
}

.skd-user-shipment-fetch-hawb h5 {
  color: #0064d9;
  font-size: 13px;
  margin-left: 5px;
  font-weight: 600;
}

.skd-user-shipment-action-left:active {
  background-color: lightgray;
}

.skd-user-shipment-filter-payment {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-left: auto;
  padding: 6px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 7px;
  background-color: white;
  cursor: pointer;
  user-select: none;
}

.skd-user-shipment-filter-payment1 {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-left: auto;
  padding: 6px;
  border-radius: 7px;
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #002fca;
  outline-offset: 2px;
  box-shadow: 0 0 2px dodgerblue;
  background-color: white;
  cursor: pointer;
  user-select: none;
}

.skd-user-shipment-fetch-container {
  width: 3010px;
}

.skd-user-shipment-filter-payment:hover {
  border: 1px solid lightgray;
}

.skd-user-shipment-filter-payment h5 {
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
}

.skd-user-shipment-filter-payment1 h5 {
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
}

.skd-user-shipment-action-left h5 {
  font-weight: 600;
  font-size: 13px;
}

.user-more-filter-clear {
  font-size: 18px !important;
  margin-left: auto !important;
  border: 1px solid lightgray !important;
  border-radius: 2px !important;
  margin-right: 5px !important;
  cursor: pointer !important;
  background-color: rgb(235, 235, 235);
}

.user-more-filter-clear:active {
  transform: scale(0.9);
}

.user-more-filter-clear:hover {
  background-color: lightgray;
}

.skydart-user-customer-left {
  width: 22%;
  background-color: white;
  min-height: fit-content;
  max-height: 85vh;
  position: sticky;
  top: 12%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 45px 1px; */
}

.skydart-user-render {
  height: 65vh;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.skydart-user-customer-right {
  width: 75%;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
}

.skydart-user-selection h5 {
  font-weight: 600;
  font-size: 12px;
}

.cus-type-stat {
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
}

.cus-type-stat h5 {
  font-size: 11px;
  color: gray;
  width: 20%;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
}

.skydart-user-customer-right1 {
  width: 75%;
  overflow-y: auto;
  height: 80vh;
  margin-top: 7px;
  margin-left: 5px;
}

.skd-user-shipment {
  width: 58%;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.skd-user-shipment-header {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  display: flex;
  align-items: center;
}

.skd-user-info-header {
  padding: 5px;
  display: flex;
}

.skd-user-info-header1 {
  padding: 5px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
}

.skd-user-info {
  border-radius: 10px;
  width: 18%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
  transition: 0.1s;
  margin-left: auto;
  animation: swipeLeft 0.2s;
}

@keyframes swipeLeft {
  from {
    margin-right: -50px;
  }

  to {
    margin-right: 0px;
  }
}

.skydart-user-nav {
  padding: 2px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 110px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
  transition: 0.1s;
}

.skydart-user-nav:hover {
  background-color: rgb(219, 219, 219);
}

.skydart-user-nav1 {
  padding: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 110px;
  cursor: pointer;
  transition: 0.1s;
  color: #0360ac;
  background-color: rgb(211, 231, 255);
}

.skydart-user-nav1:hover {
  background-color: rgb(187, 217, 255);
}

.skydart-user-nav h3 {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.skydart-user-nav1 h3 {
  font-size: 12px;
  margin-left: 5px;
  color: #0360ac;
}

.skd-user-shipment-header-left {
  display: flex;
  align-items: center;
  width: 35%;
}

.skd-user-shipment-header-left input {
  padding: 5px;
  margin-left: 5px;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  border-radius: 999px;
}

.skd-user-shipment-header-left input:focus {
  border: 1px solid rgb(230, 230, 230) !important;
  outline: 1px solid #002fca !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 2px dodgerblue !important;
}

.skydart-user-customer-search {
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  width: 100%;
}

.skd-user-shipment-header-right-date {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  background-color: rgb(235, 235, 235);
}

.skd-user-shipment-header-right-date1 {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  background-color: white;
}

.skd-user-info-export-excel-table {
  background-color: white !important;
  width: 500px !important;
  height: 70vh !important;
  overflow: scroll !important;
  position: absolute !important;
  display: none !important;
  margin-left: 9999px !important;
}

.skd-user-info-export-excel-table th {
  font-size: 12px;
}

.skd-user-info-export-excel-table-info-container {
}

.skd-user-info-export-excel-table-info-container h3 {
  font-size: 16px;
}

.skd-user-info-export-excel-table-info-container p {
  font-size: 14px;
}

.skd-user-info-export-excel-table-header {
  padding: 5px;
  font-size: 18px;
  font-weight: 450;
  color: rgb(55, 55, 55);
}

.skd-user-info-export-excel-table-info {
}

.skd-user-info-header-left {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 7px;
  background-color: #00a200;
  color: white;
  justify-content: center;
  width: 80px;
  cursor: pointer;
}

.skd-user-inf-header-right-more-option {
  padding: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.skd-user-inf-header-right-more-option h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skd-user-inf-header-right-more-option:hover {
  background-color: rgb(230, 230, 230);
}

.user-more-filter-controller {
  position: absolute;
  z-index: 9999;
  background-color: white;
  margin-top: 7px;
  width: 240px;
  height: 200px;
  overflow-y: scroll;
  border-radius: 5px;
  margin-left: -200px;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skd-user-shipment-action-option-container {
  position: absolute;
  z-index: 9999;
  background-color: white;
  margin-top: 2px;
  width: 300px;
  margin-left: 3px;
  border-radius: 5px;
  box-shadow:
    rgba(14, 30, 37, 0.02) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.22) 0px 2px 16px 0px;
}

.skd-user-shipment-filter-payment-container {
  background-color: white;
  margin-top: 2px;
  position: absolute;
  margin-left: -2px;
  width: 190px;
  padding: 7px;
  z-index: 9999;
  border-radius: 10px;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.skd-user-shipment-filter-payment-option {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 2px;
  border-radius: 3px;
}

.skd-user-shipment-filter-payment-option:hover {
  background-color: rgb(240, 240, 240);
}

.skd-user-shipment-filter-payment-option1 {
  background-color: rgb(213, 233, 255);
  padding: 5px;
  margin-top: 2px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-more-filter-controller-option {
  padding: 10px;
  cursor: pointer;
}

.skd-user-shipment-action-option-wrap {
  height: 200px;
  overflow-y: scroll;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-user-shipment-last-button {
  margin: 7px;
  display: flex;
}

.skd-user-shipment-last-controller-cancel {
  padding: 8px;
  width: 125px;
  border-radius: 7px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(230, 230, 230);
  justify-content: center;
  cursor: pointer;
}

.skd-user-shipment-last-controller-cancel:hover {
  background-color: rgb(225, 225, 225);
}

.skd-user-shipment-last-controller-apply:hover {
  opacity: 1;
}

.skd-user-shipment-last-controller-apply {
  padding: 8px;
  width: 125px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: #0175d4;
  opacity: 0.9;
  color: white;
  justify-content: center;
  cursor: pointer;
}

.skd-user-shipment-last-controller-apply1 {
  padding: 8px;
  width: 125px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: rgb(245, 245, 245);
  justify-content: center;
  cursor: not-allowed;
  color: lightgray;
}

.skd-user-shipment-last-controller-cancel:active {
  transform: scale(0.96);
}

.skd-user-shipment-last-controller-apply:active {
  transform: scale(0.96);
}

.skd-user-shipment-last-controller-cancel h5 {
  font-weight: 600;
  font-size: 14px;
  user-select: none;
}

.skd-user-shipment-last-controller-apply1 h5 {
  font-weight: 600;
  font-size: 14px;
  user-select: none;
}

.skd-user-shipment-last-controller-apply h5 {
  font-weight: 600;
  font-size: 14px;
  user-select: none;
}

.skd-user-shipment-action-option-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.skd-user-shipment-action-clear {
  font-size: 20px !important;
  padding: 2px !important;
  border-radius: 5px !important;
  background-color: rgb(235, 235, 235) !important;
  cursor: pointer !important;
}

.skd-user-shipment-action-clear:hover {
  background-color: rgb(220, 220, 220) !important;
}

.skd-user-shipment-action-clear:active {
  background-color: lightgray !important;
}

.skd-user-shipment-action-option-body {
  padding: 10px;
  cursor: pointer;
  color: gray;
}

.skd-user-shipment-action-option-body1 {
  color: white;
  background-color: #0175d4;
  opacity: 0.9;
  cursor: pointer;
  padding: 10px;
}

.skd-user-shipment-last-header {
  padding: 7px;
  display: flex;
  align-items: center;
}

.skd-user-shipment-last-header p {
  font-size: 11px;
  margin-left: 5px;
}

.skd-user-info-export-button {
  padding: 8px;
  display: flex;
}

.skd-user-info-export-button-cancel {
  margin-left: auto;
  padding: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgb(240, 240, 240);
  width: 65px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  border-radius: 5px;
}

.skd-user-info-export-button-apply {
  padding: 7px;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #2887e6;
  width: 85px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.skd-user-info-export-button-apply1 {
  padding: 7px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  background-color: #2887e6;
  width: 85px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.skd-user-info-export-button-apply2 {
  background-color: #2887e6;
  width: 85px;
  text-decoration: none;
  color: white;
  display: flex;
  font-size: 14px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  border-radius: 5px;
  margin-left: 5px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.skd-user-info-export-button-apply2:hover {
  opacity: 0.9;
}

.skd-user-info-export-button-apply2:active {
  transform: scale(0.95);
}

.skd-user-info-export-button-cancel:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-info-export-button-cancel:active {
  transform: scale(0.95);
}

.skd-user-info-export-button-apply:hover {
  opacity: 0.9;
}

.skd-user-info-export-button-apply:active {
  transform: scale(0.95);
}

.skd-user-info-export-button-apply1:hover {
  opacity: 0.9;
}

.skd-user-info-export-button-apply1:active {
  transform: scale(0.95);
}

.skd-user-info-export-button-cancel h5 {
  user-select: none;
  font-weight: 600;
  font-size: 14px;
}

.skd-user-info-export-button-apply h5 {
  user-select: none;
  font-weight: 600;
  font-size: 14px;
}

.skd-user-shipment-action-option-body:active {
  background-color: rgb(228, 228, 228) !important;
}

.skd-user-shipment-action-option-body:hover {
  background-color: rgb(235, 235, 235);
  color: black;
}

.skd-user-shipment-action-option-body h5 {
  font-weight: 600;
  font-size: 12px;
}

.skd-user-shipment-action-option-body1 h5 {
  font-weight: 600;
  font-size: 12px;
}

.skd-user-shipment-action-option-header h3 {
  font-weight: 600;
  font-size: 15px;
  color: #005ca7;
}

.user-more-filter-controller-option1 {
  padding: 10px;
  background-color: #0064d9;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}

.user-more-filter-controller-option1 h5 {
  font-weight: 600;
  text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.user-more-filter-controller-option:hover {
  background-color: rgb(230, 230, 230);
}

.user-more-filter-controller-option:active {
  background-color: rgb(225, 225, 225);
}

.user-more-filter-controller-option h5 {
  font-weight: 600;
  text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.skd-user-info-header-left:hover {
  opacity: 0.9;
}

.skd-user-info-header-left:active {
  transform: scale(0.97);
}

.skd-user-info-body {
  padding: 5px;
}

.skd-user-info-body-info {
  display: flex;
  padding: 5px;
}

.skd-user-info-body-info input {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 7px;
  font-size: 12px;
}

.skd-user-info-body-info input:focus {
  outline: 1px solid #0064d9;
  outline-offset: 2px;
  box-shadow: 0px 0px 3px 0px dodgerblue;
}

.skd-user-info-body-info h5 {
  font-weight: 600;
  font-size: 11px;
  margin-left: 5px;
}

.skd-user-info-wrap {
  border-bottom: 1px solid lightgray;
  height: 70vh;
  overflow-y: scroll;
  scroll-behavior: initial;
}

.skd-user-info-wrap::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.skd-user-info-wrap::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-user-info-header-right {
  margin-left: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  width: 30px;
}

.skd-user-info-header-right1 {
  margin-left: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  width: 30px;
}

.skd-user-info-header-right-help {
  position: absolute;
  margin-top: 70px;
  font-size: 11px;
  padding: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  display: none;
}

.skd-user-info-header-right1 {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  margin-left: 7px;
  width: 25px;
}

.skd-user-info-header-right-more {
  position: absolute;
  background-color: white;
  margin-left: -162px;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skd-user-info-header-right-hover {
  position: absolute;
  margin-top: 110px;
  background-color: white;
  cursor: default;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  display: none;
  animation: hoverDown 0.1s;
}

.skd-user-info-header-right-hover input {
  margin: 5px;
  width: 86.6%;
  padding: 7px;
  border-radius: 3px;
  border: 1px solid rgb(225, 225, 225);
  outline: none;
}

.skd-user-info-header-right-hover button {
  margin: 5px;
  width: 94%;
  border-radius: 5px;
  padding: 5px;
  background-color: #0173d1;
  color: white;
  cursor: pointer;
  border: none;
}

.skd-user-info-header-right-hover button:hover {
  opacity: 0.8;
}

@keyframes hoverDown {
  from {
    margin-top: 80px;
  }

  to {
    margin-top: 110px;
  }
}

.skd-user-inf-header-right-more-option:hover .skd-user-info-header-right-hover {
  display: block;
}

.skd-user-info-header-right1:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-info-header-right1:hover .skd-user-info-header-right-help {
  display: flex;
}

.skd-user-info-header-right:hover .skd-user-info-header-right-help {
  display: flex;
}

.skd-user-info-header-right1:active {
  transform: scale(0.9);
}

.skd-user-info-header-right1:active .skd-user-info-header-right-help {
  transform: scale(1);
}

.skd-user-info-header-right:active .skd-user-info-header-right-help {
  transform: scale(1);
}

.skd-user-info-header-right:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-info-header-right:active {
  transform: scale(0.9);
}

.skd-user-info-header-left h5 {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  margin-left: 5px;
}

.skd-debt-paginate {
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.skd-user-shipment-header-right-date:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-shipment-header-right-date:active {
  background-color: lightgray;
}

.skd-user-shipment-header-right-date p {
  font-size: 14px;
}

.skd-user-shipment-header-right-date1 p {
  font-size: 14px;
}

.skd-user-shipment-header-right-date1:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-shipment-header-right-date1:active {
  background-color: lightgray;
}

.skd-user-shipment-container {
  margin-top: 12px;
}

.skd-user-shipment-container1 {
  margin-top: 0;
}

.search-customer {
  padding: 7px !important;
  padding-left: 30px !important;
  border: 1px solid rgb(230, 230, 230) !important;
  border-radius: 5px !important;
  width: 100% !important;
  outline: none !important;
}

.skydart-user-selection {
  width: 100%;
  display: flex;
}

.skydart-user-customer-left-title {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skydart-user-customer-left-title h3 {
  font-size: 16px;
  color: dimgray;
}

.skydart-user-selection-option {
  margin-left: 5px;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  user-select: none;
}

.skydart-user-selection-option1 {
  margin-left: 5px;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  background-color: rgb(200, 223, 255);
  color: rgb(51, 116, 214);
  align-items: center;
  cursor: pointer;
  justify-content: center;
  user-select: none;
}

.skydart-user-selection-option:hover {
  background-color: rgb(235, 235, 235);
}

.search-customer:focus {
  border: 1px solid rgb(230, 230, 230) !important;
  outline: 1px solid #006fca !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 2px dodgerblue !important;
}

.skydart-user-more-filter {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
  background-color: rgb(235, 235, 235);
}

.skydart-user-more-filter:hover {
  background-color: rgb(225, 225, 225);
}

.skydart-user-more-filter:active {
  transform: scale(0.9);
}

.skydart-user-more-filter1 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}

.skydart-user-more-filter1:hover {
  background-color: rgb(225, 225, 225);
}

.skydart-user-more-filter1:active {
  transform: scale(0.9);
}

.skydart-customer-search-icon {
  margin-left: 5px !important;
  position: absolute !important;
}

.skydart-user-container {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.skydart-user-container:hover {
  background-color: rgb(240, 240, 240);
}

.selected-user {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 235, 255);
  border-radius: 5px;
}

.selected-user h5 {
  font-weight: 600;
  color: #005ca7;
}

.skydart-user-option {
  padding: 5px;
  cursor: pointer;
  width: 100%;
}

/* .skydart-user-option:hover {
    background-color: rgb(238, 237, 237);
} */
.skydart-user-option h5 {
  font-weight: 600;
}

.skydart-user-customer-right-header {
  padding: 15px;
}

.skydart-user-customer-right-header1 {
  padding: 15px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
  border-right: 10px solid #0360ac;
}

.skydart-user-customer-right-status {
  padding: 15px;
  background-color: white;
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
}

.skydart-user-customer-right-header-left h3 {
  font-weight: 600;
}

.skydart-user-option1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.skydart-user-option1 h3 {
  font-weight: 600;
  font-size: 12px;
  padding-top: 5px;
  color: rgb(78, 78, 78);
}

.skydart-user-option1 h5 {
  font-size: 15px;
  font-weight: 700;
}

.skydart-user-info {
  display: flex;
  align-items: center;
}

.skydart-ship-stat {
  padding-top: 5px;
}

.skydart-user-shipping-status-body {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.skydart-user-controller {
  margin-top: 5px;
}

.skydart-user-controller h5 {
  font-weight: 600;
  margin-right: 5px;
  font-size: 12px;
  color: gray;
  width: 40%;
}

.skydart-user-controller select {
  padding: 10px;
  width: 25%;
  border: none;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  cursor: pointer;
  margin-right: 5px;
  outline: none;
  transition: 0.1s;
}

.skydart-user-controller select:hover {
  background-color: lightgray;
}

.skydart-user-controller button {
  padding: 10px;
  border: none;
  border: 1px solid #d3d3d3;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
  background-color: white;
  transition: 0.1s;
  width: 150px;
  cursor: pointer;
  color: black;
}

.skydart-user-controller button:active {
  transform: scale(0.95);
}

.skydart-user-controller button:hover {
  background-color: #cecece;
}

.debit-note2 {
  background-color: whitesmoke;
}

.debit-note2 h3 {
  font-family: Roboto, sans-serif;
}

.debit-note2 h5 {
  font-family: Roboto, sans-serif;
}

.skydart-user-shipping-status-body-right input {
  margin: 5px;
  padding: 5px;
  border: none;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

.export-pdf {
  margin-left: 5px;
  padding: 8px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  background-color: #0e7bd4;
  cursor: pointer;
  color: white;
}

.export-pdf1 {
  margin-left: 5px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid lightgray;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  color: black;
}

.export-pdf1:hover {
  background-color: #cfcfcf;
}

.export-pdf:hover {
  background-color: #0961aa;
}

.skydart-customer-debit-header {
  display: flex;
}

.skydart-customer-debit-header-option {
  padding: 5px;
  border-right: 1px solid;
  border-top: 1px solid;
  width: 9%;
}

.skydart-customer-debit-header-option:nth-last-child(1) {
  border-right: none;
}

.skydart-customer-debit-header-option h5 {
  font-weight: 450;
  font-size: 11px;
  text-align: center;
}

#pdf-export {
  height: 29cm;
}

.skydart-user-type {
  position: absolute;
  background-color: white;
  padding: 10px;
  margin-top: 40%;
  width: 70%;
  animation: sliceUp 0.1s;
  border-radius: 5px;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skydart-user-type-option {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.skydart-user-type-option:hover {
  background-color: lightgray;
}

.skydart-user-type-option h3 {
  font-weight: 600;
  font-size: 15px;
}

.skydart-customer-debit-sum {
  padding: 7px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
}

.debit-period {
  display: flex;
  gap: 0.5em;
}

.debit-period input {
  font-family: Arial;
  border: none;
  outline: none;
  font-weight: 600;
  width: 50%;
  padding-left: 20px;
}

.skydart-payment-status {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
  padding-top: 7px;
  gap: 0.5em;
}

.skydart-debt-content {
  padding: 10px;
}

.skydart-debt-content h3 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
}

.skydart-payment-status h5 {
  font-weight: 600;
}

.skydart-payment-status-option {
  cursor: pointer;
  padding: 5px;
  transition: 0.1s;
  border-radius: 5px;
}

.skydart-payment-status-option1 {
  cursor: pointer;
  padding: 5px;
  transition: 0.1s;
  border-radius: 5px;
  background-color: #3667a0;
  color: white;
}

.skydart-payment-status-option:hover {
  background-color: rgb(236, 236, 236);
}

.skydart-payment-status-container {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.skydart-create-surcharge {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.skydart-create-surcharge-container-header {
  padding: 5px;
}

.skydart-create-surcharge-option {
  padding: 5px;
  display: flex;
  align-items: center;
}

.skydart-create-surcharge-option h5 {
  width: 30%;
}

.skydart-create-surcharge-option input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  width: 200px;
}

.skydart-create-surcharge-option input:focus {
  border: 1px solid #0064d9;
  box-shadow: 0px 0px 5px 0px #0064d9;
}

.skydart-create-surcharge-option button {
  padding: 7px;
  margin-top: 5px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #0074d9;
  cursor: pointer;
  color: white;
  font-weight: 600;
  background-color: #0074d9;
  transition: 0.1s;
}

.skydart-create-surcharge-option button:hover {
  background-color: #0064d9;
}

.skydart-no-book-btn {
  padding: 7px;
  width: 10%;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.skydart-no-book-btn:hover {
  background-color: lightgray;
}

.skydart-yes-book-btn {
  padding: 7px;
  margin-left: 10px;
  border: 1px solid #0280e7;
  background-color: #0280e7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
}

.skydart-yes-book-btn:hover {
  background-color: #0280e3;
}

.skydart-tracing-option {
  position: absolute;
  padding: 5px;
  background-color: white;
  margin-top: -20px;
  margin-left: -28px;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  width: 170px;
  border-radius: 5px;
}

.skydart-tracing-setting {
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.skydart-tracing-setting:hover {
  background-color: rgb(230, 230, 230);
}

.tracking-status-header {
  padding: 5px;
  font-weight: 600;
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.skydart-tracking {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid;
}

.skydart-tracking h5 {
  font-weight: 600;
}

.skydart-tracking-shipping-area {
  display: flex;
  align-items: center;
  gap: 0.25em;
  margin-left: 5px;
}

.skydart-tracking-content {
  display: flex;
  margin-top: 5px;
}

.skydart-tracking-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1.5px solid lightgray;
  cursor: pointer;
}

.skydart-tracking-status-container1 {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1.5px solid lightgray;
  cursor: pointer;
}

.skydart-tracking-status-container:hover {
  border: 1.5px solid rgb(57, 108, 190);
}

.skydart-track-detail {
  display: flex;
}

.skydart-track-detail-right h3 {
  font-size: 13px;
}

.skydart-track-detail-right p {
  font-size: 10px;
}

.skydart-track-detail-right {
  margin-left: 5px;
}

.skydart-header-retrieved {
  margin-left: 10px;
  display: flex;
  padding: 5px;
  border-radius: 999px;
  width: 65%;
  background-color: rgb(233, 233, 233);
}

.skydart-header-retrieved input {
  padding: 5px;
  border-radius: 999px;
  border: none;
  outline: none;
  width: 100%;
  background-color: rgb(233, 233, 233);
}

.skydart-header-retrieved-container {
  position: absolute;
  margin-top: 35px;
  animation: sliceUp 0.1s;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  width: 60%;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skydart-header-retrive-content {
  padding: 5px;
}

.retrive-header {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.retrive-header:hover {
  background-color: lightgray;
}

.getshipment-btn {
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  background-color: #0074d9;
  color: white;
}

.getshipment-btn:hover {
  opacity: 0.9;
}

.not-allow-get {
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  font-weight: 600;
  cursor: not-allowed;
  background-color: whitesmoke;
  color: gray;
  width: 100%;
}

.skydart-booking-address-rendered {
  padding: 5px;
  position: absolute;
  margin-top: 5px;
  height: 17vh;
  width: 98%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  background-color: white;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.skydart-booking-address-rendered-container {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
}

.skydart-booking-address-rendered-container:hover {
  background-color: rgb(233, 233, 233);
}

.hawb-bill {
  margin: 35px;
  border: 1px solid;
  background-color: #fff;
}

.new-hawb-bill {
  border: 1px solid;
  background-color: #fff;
  border-radius: 3px;
  margin: 15px;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.bill {
  width: 880px;
}

.new-bill {
  width: 570px !important;
}
.new-bill1 {
  width: 580px;
  scale: 0.5;
  border-radius: 10px;
  margin-left: -30px;
}

.skydart-bookShipment-draft {
  width: 733px;
}

.skydart-bookShipment-draft-container {
  margin: 15px;
  background-color: rgb(255, 255, 255);
  height: 500px;
  /* width: 705px;
    overflow-x: scroll; */
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.new-invoice {
  width: 570px;
  border-radius: 3px;
}

.hawb-bill-content {
  display: flex;
}

.hawb-bill-left {
  width: 50%;
  border-right: 1px solid;
}

.hawb-bill-left-header {
  padding: 5px;
  background-color: rgb(140, 186, 255);
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.hawb-bill-address-info {
  padding: 5px;
}

.hawb-bill-right {
  width: 50%;
}

.hawb-bill-address-detail h5 {
  font-size: 12px;
  width: 35%;
}

.hawb-bill-address-detail {
  display: flex;
}

.hawb-bill-address-detail p {
  font-size: 12px;
}

.hawb-bill-right-primary-option {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.hawb-bill-right-primary-option img {
  height: 50px;
}

.hawb-bill-right-logo {
  display: flex;
  align-items: center;
}

.hawb-bill-right-primary-text {
  margin-left: 10px;
}

/* .bill {
    width: 880px;
} */
.hawb-bill-little-helper {
  margin-top: 5px;
}

.hawb-bill-little-helper p {
  font-weight: 700 !important;
  font-size: 11px;
  margin-left: 5px !important;
  white-space: nowrap !important;
}

.document-parcel1 {
  font-size: 9px !important;
}

.hawb-bill-right-primary-text h3 {
  text-align: center;
  font-weight: 600;
  color: darkblue;
  font-size: 20px;
}

.hawb-bill-number {
  display: flex;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: 22px;
}

.hawb-bill-number-left {
  padding: 5px;
  background-color: rgb(140, 186, 255);
  border-right: 1px solid;
}

.hawb-bill-number-right {
  padding: 10px;
}

.hawb-bill-number-right h4 {
  font-weight: 600;
  text-align: center;
  margin-left: 80px;
  font-size: 18px;
}

.skydart-bookShipment-draft-content {
  display: flex;
  padding: 7px;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  cursor: pointer;
}

.skydart-bookShipment-draft-content:hover {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.skydart-bookShipment-draft-content:hover .skydart-bookShipment-draft-checkbox input[type='checkbox'] {
  -webkit-appearance: none !important;
  appearance: none !important;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff !important;
  /* Not removed via appearance */
  margin: 0 !important;
  font: inherit !important;
  color: #0068be !important;
  width: 1.15em !important;
  height: 1.15em !important;
  border: 0.15em solid gray !important;
  border-radius: 3px !important;
  transform: translateY(-0.075em) !important;
  display: grid !important;
  place-content: center !important;
  cursor: pointer !important;
}

.hawb-bill-package-header {
  padding: 6.7px;
  background-color: rgb(140, 186, 255);
  border-bottom: 1px solid;
  display: flex;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
}

.hawb-bill-package-header-option {
  width: 100%;
}

.hawb-bill-package-header-option h5 {
  font-size: 10px;
  text-align: center;
}

.hawb-bill-package-body-option {
  width: 100%;
  border-right: 1px solid;
  padding: 3.5px;
}

.hawb-bill-package-body-option h5 {
  font-size: 15px;
  text-align: center;
}

.hawb-bill-package-body {
  display: flex;
  border-bottom: 1px solid;
}

.hawb-bill-package-description {
  padding: 5px;
  border-bottom: 1px solid;
  background-color: rgb(140, 186, 255);
}

.hawb-bill-package-description h5 {
  font-size: 10px;
  margin-left: 10px;
}

.hawb-bill-items {
  padding: 10px;
  height: 160px;
  overflow: hidden;
}

.hawb-bill-items-option {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.hawb-bill-items-option h5 {
  font-size: 11px;
  font-weight: 600;
}

.hawb-bill-footer {
  display: flex;
}

.hawb-bill-footer-left {
  width: 100%;
  border-right: 1px solid;
  border-top: 1px solid;
  display: flex;
}

.hawb-bill-footer-left-option {
  padding: 5px;
}

.hawb-bill-footer-pay-option {
  display: flex;
  background-color: rgb(140, 186, 255);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid;
  border-top: 1px solid;
}

.hawb-bill-footer-agreement-option {
  padding: 5px;
  border-left: 1px solid;
}

.hawb-bill-footer-right {
  width: 100%;
  border-top: 1px solid;
  display: flex;
  justify-content: space-between;
}

.hawb-bill-footer-right-option {
  padding: 5px;
  height: 37px;
}

.hawb-bill-footer-shipment-date {
  padding: 5px;
  border-top: 1px solid;
  background-color: rgb(140, 186, 255);
  display: flex;
  width: 250px;
  border-right: 1px solid;
}

.hawb-bill-footer-shipment-date h5 {
  width: 100%;
}

.hawb-bill-footer-right-shipment-type {
  background-color: rgb(140, 186, 255);
  padding: 15px;
  border-left: 1px solid;
}

.invoice-term-header-left-option {
  padding: 3px;
  border-bottom: 1px solid;
}

.invoice-term {
  padding: 10px;
}

.invoice-term h1 {
  font-size: 25px;
  font-weight: 600;
  padding: 3px;
}

.invoice-term-header {
  margin-top: 15px;
  border: 2px solid;
  display: flex;
}

.invoice-term-header-left {
  width: 30%;
  border-right: 1px solid;
}

.invoice-term-header-left-option,
.invoice-term-header-right-option {
  padding: 3px;
  border-bottom: 1px solid;
}

.invoice-term-header-right {
  width: 70%;
}

.invoice-term-shipping-info {
  margin-top: 20px;
  display: flex;
}

.invoice-term-shipping-info-container {
  width: 100%;
}

.invoice-term-shipping-info-header h5 {
  text-align: center;
  font-weight: 600;
}

.invoice-term-shipping-info-body {
  border-width: 2px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
}

.invoice-term-shipping-info-body-option {
  display: flex;
  border-bottom: 1px solid;
}

.invoice-term-shipping-info-body-option h5 {
  font-weight: 600;
  font-size: 12px;
}

.invoice-term-shipping-info-body-option-divider {
  width: 50%;
  white-space: nowrap;
  border-right: 1px solid;
  padding: 3px;
  overflow: hidden;
}

.invoice-term-full-description-of-good {
  margin-top: 15px;
}

.invoice-term-full-description-of-good-header h5 {
  font-weight: 600;
  text-align: center;
}

.invoice-term-full-description-of-good-content {
  border-width: 2px 2px 2px;
  border-style: none solid solid;
  border-color: initial;
  border-image: initial;
  border-top: none;
}

.invoice-full-description-header {
  display: flex;
  border-top: 2px solid;
}

.invoice-full-description-header-option1 {
  width: 30%;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.invoice-full-description-header-option {
  width: 14%;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.invoice-full-description-header-option1 h5 {
  font-weight: 700;
  padding: 3px;
}

.invoice-full-description-header-option h5 {
  text-align: center;
}

.invoice-full-description-body {
  display: flex;
}

.invoice-full-description-body-option1 {
  width: 30%;
  border-right: 1px solid;
}

.invoice-full-description-body-option1 h5 {
  font-weight: 700;
  margin-left: 3px;
}

.invoice-full-description-body-option3 {
  width: 14%;
  border-right: 1px solid;
}

.invoice-full-description-body-option3 h5 {
  font-weight: 450;
  margin-left: 3px;
}

.invoice-full-description-body-option {
  width: 14%;
  border-right: 1px solid;
}

.invoice-full-description-body-option h5 {
  text-align: center;
  font-weight: 450;
}

.invoice-full-description-body-option2 {
  width: 14%;
  display: flex;
  border-right: 1px solid;
}

.invoice-full-description-body-option2 h5 {
  margin-left: auto;
  font-weight: 450;
}

.invoice-description-detail {
  display: flex;
  border-top: 2px solid;
}

.invoice-description-detail-left {
  width: 30%;
  border-right: 1px solid;
}

.invoice-description-detail-left h5 {
  margin-left: 3px;
}

.invoice-description-detail-right {
  width: 70.5%;
}

.invoice-description-detail-right h5 {
  font-weight: 450;
  margin-left: 3px;
}

.invoice-value-container {
  display: flex;
  border-width: 2px 2px 2px;
  border-style: none solid solid;
  border-color: initial;
  border-image: initial;
  border-top: none;
}

.invoice-value-container-left {
  width: 30%;
  border-right: 1px solid;
}

.invoice-value-container-left h5 {
  margin-left: 3px;
}

.invoice-value-container-right {
  width: 70.5%;
}

.invoice-value-container-right-option {
  display: flex;
}

.invoice-value-container-right-option h5 {
  margin-left: auto;
  font-weight: 600;
}

.invoice-value-text-last-content {
  margin-top: 15px;
  margin-left: 5px;
}

.invoice-value-text-last-content h5 {
  font-weight: 450;
  font-size: 13px;
}

.shipment-note {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.2s;
  background-color: rgba(0, 0, 0, 0.55);
}

.skd-user-info-export {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.1s;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}

.skd-user-info-export-container {
  background-color: white;
  border-radius: 10px;
  margin-top: -30px;
  width: 450px;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.skd-user-info-export-header h3 {
  font-weight: 600;
  font-size: 17px;
}

.skd-user-info-export-clear {
  padding: 3px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  color: gray !important;
}

.skd-user-info-export-clear:hover {
  color: black !important;
  background-color: rgb(235, 235, 235) !important;
}

.skd-user-info-export-clear:active {
  transform: scale(0.92) !important;
  background-color: rgb(225, 225, 225) !important;
}

.skd-user-info-export-header {
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skd-user-info-export-name {
  padding: 5px;
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
}

.skd-user-info-export-select {
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.skd-user-info-export-name input {
  margin-top: 7px !important;
  padding: 5px !important;
  width: 100% !important;
  border-radius: 7px !important;
  border: 1px solid lightgray !important;
  outline: none !important;
}

.skd-user-info-export-name input:hover {
  border: 1px solid rgb(210, 210, 210);
}

.skd-user-info-export-name input:focus {
  border: 1px solid rgb(230, 230, 230) !important;
  outline: 1px solid #002fca !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 3px dodgerblue !important;
}

.skd-user-info-export-select-option {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.skd-user-info-export-select-option:hover {
  background-color: rgb(240, 240, 240);
}

.skd-user-info-export-select-option:active {
  background-color: rgb(225, 225, 225);
}

.skd-user-no-data-range {
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
  background-color: rgb(230, 230, 230);
  user-select: none;
}

.skd-user-no-data-range h5 {
  font-weight: 600;
}

.skd-user-no-data-range:hover {
  background-color: rgb(218, 218, 218);
}

.skd-user-no-data-range:active {
  background-color: lightgray;
}

.clear-export-name-skd-user {
  position: absolute;
  right: 11px;
  bottom: 32px;
  cursor: pointer;
}

.skd-user-info-export-name h5 {
  font-weight: 600;
  font-size: 14px;
}

.skd-user-info-export-select h5 {
  font-weight: 600;
  font-size: 14px;
}

.shipment-note-container {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 7%;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: white;
  animation: fadeIn 0.2s;
  width: 41%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shipment-note-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.shipment-note-header h3 {
  font-weight: 600;
  font-size: 17px;
}

.shipment-note-payment {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.shipment-note-payment h5 {
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0, 0.75);
}

.shipment-note-payment select {
  margin-top: 5px;
  padding: 7px;
  border-radius: 5px;
  border: 2px solid lightgray;
  outline: none;
  transition: 0.2s;
}

.shipment-note-payment textarea {
  margin-top: 10px;
  padding: 10px;
  outline: none;
  border: 2px solid lightgray;
  border-radius: 5px;
  height: 15vh;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  transition: 0.1s;
}

.shipment-note-payment textarea:focus {
  border: 2px solid #005ca7;
}

.shipment-note-payment button {
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  background-color: rgb(57, 108, 190);
  color: white;
}

.shipment-note-payment select:focus {
  border: 2px solid rgb(77, 108, 209);
}

.customer-change-warn {
  height: 120px;
  background-color: #ffeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.customer-change-warn-content {
  padding: 15px;
  color: #c71717;
}

.customer-change-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.customer-change-warn-content p {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.customer-change-body h5 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.customer-change-body select {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ececec;
  margin-top: 5px;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
}

.change-customer-btn,
.disable-change-customer-btn {
  margin-top: 15px;
  border: none;
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
  width: 15%;
  border-radius: 5px;
  margin-left: auto;
}

.disable-change-customer-btn {
  color: #ccc;
}

.change-customer-btn {
  color: #fff;
  background-color: #c94343;
  transition: 0.2s;
}

.shipment-rate-content {
  padding: 15px;
  display: flex;
}

.shipment-rate-collection {
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.shipment-rate-content h5 {
  font-weight: 600;
  font-size: 11px;
  color: grey;
}

.shipment-rate-collection h5 {
  text-align: center;
}

.shipment-rate-collection input,
.shipment-rate-collection select {
  border: 2px solid #d3d3d3;
  outline: none;
  border-radius: 5px;
}

.shipment-rate-collection input {
  width: 100%;
  transition: 0.1s;
  text-align: right;
}

.shipment-com-note {
  margin-left: 15px;
}

.note-collection {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.note-collection h5 {
  font-size: 11px;
  color: grey;
  width: 10%;
}

.note-collection input {
  border: 2px solid #d3d3d3;
  outline: none;
  border-radius: 5px;
  width: 13.5%;
  transition: 0.1s;
}

.shipment-rate-collection-button {
  padding: 15px;
  display: flex;
}

.shipment-rate-collection-button button {
  margin: 5px 5px 5px auto;
  padding: 9px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  background: #396cbe;
  cursor: pointer;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
}

.shipment-note-container-header {
  padding: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shipment-note-container-header h3 {
  font-weight: 600;
}

.shipment-container-flight-info {
  padding: 10px;
}

.shipment-container-flight-info-option {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.shipment-container-flight-info h5 {
  font-weight: 600;
}

.shipment-container-flight-info input {
  padding: 5px;
  border: 2px solid #d3d3d3;
  outline: none;
  border-radius: 5px;
  transition: 0.1s;
  font-family: Arial, Helvetica;
  margin-top: 5px;
}

.second {
  width: 100%;
  padding: 10px;
}

.second__container__head {
  display: flex;
  align-items: center;
}

.second__container__option {
  width: 60%;
}

.second__container__option h5 {
  font-weight: 600;
  color: grey;
  font-size: 12px;
}

.shipment-bag-export {
  display: flex;
  margin-top: 7px;
}

.shipment-bag-export {
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  border: none;
  outline: none;
  background: none;
}

.shipment-bag-export:active {
  color: red;
}

.shipment-container-flight-info button {
  padding: 5px;
  border: 2px solid #0462c0;
  color: #fff;
  font-weight: 600;
  outline: none;
  border-radius: 5px;
  background-color: #0462c0;
  cursor: pointer;
  transition: 0.1s;
  margin-top: 10px;
}

.shipment-container-flight-info button:active {
  transform: scale(0.9);
}

.shipment-container-flight-info-option button:hover {
  opacity: 0.8;
}

.shipment-note-content {
  padding: 15px;
}

.shipment-note-content h5 {
  word-break: break-word;
  font-weight: 600;
  font-size: 11px;
  color: grey;
}

.shipment-note-content textarea {
  margin-top: 5px;
  border: 2px solid #e6e6e6;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  width: 95%;
  padding: 10px;
  height: 50px;
  transition: 0.2s;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.shipment-note-content textarea:focus {
  border: 2px solid #005ca7;
}

.shipment-note-content select {
  width: 38%;
}

.shipment-note-content input,
.shipment-note-content select {
  margin-top: 5px;
  padding: 7px;
  border: 2px solid #e6e6e6;
  outline: none;
  border-radius: 5px;
  transition: 0.2s;
}

.shipment-note-update-btn,
.shipment-save-btn {
  padding: 10px;
  border: none;
  border-radius: 7px;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
  transition: 0.1s;
  cursor: pointer;
}

.shipment-note-update-btn {
  color: #fff;
  background: #396cbe;
}

.shipment-note-update-btn1 {
  background: none;
  margin-left: auto;
  padding: 10px;
  border: none;
  border-radius: 7px;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
  transition: 0.1s;
  cursor: pointer;
  color: #d3d3d3;
}

.shipment-export-enter-file-name {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  padding-bottom: 5px;
  align-items: center;
}

.shipment-export-enter-file-name input {
  padding: 8px;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  width: 93%;
  outline: none;
  transition: 0.2s;
  margin-right: 7px;
}

.shipment-export-enter-file-name input:focus {
  border: 2px solid #005ca7;
}

.trans__home__container__header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sale-search-box,
.sale-search-box1 {
  display: flex;
  align-items: center;
  background-color: #d3d3d3;
  padding: 3px;
  border-radius: 5px;
}

.sale-search-box {
  width: 78%;
}

.trans__container__right {
  display: flex;
  align-items: center;
}

.export-trans-btn {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  border: none;
  font-weight: 600;
  background-color: #1e71ec;
  color: #fff;
  cursor: pointer;
}

.export-trans-btn,
.trans__container__right {
  display: flex;
  align-items: center;
}

.reload-icon {
  padding: 10px !important;
  cursor: pointer !important;
  border-radius: 999px !important;
  margin-left: 5px !important;
  opacity: 0.5 !important;
  transition: 0.1s !important;
}

.translate {
  display: none;
  position: absolute;
  background-color: #fbfbff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
  width: 30%;
  height: 2vh;
  left: 70%;
  margin-left: -10px;
}

.reload-icon1 {
  padding: 10px !important;
  cursor: pointer !important;
  border-radius: 999px !important;
  opacity: 0.5 !important;
  transition: 0.1s !important;
}

.login__header h5 {
  font-size: 23px;
  font-weight: 600;
  color: #00008b;
}

.login__header h3,
.login__info h5 {
  font-weight: 600;
  font-size: 15px;
}

.login__info {
  margin-left: 50px;
  margin-right: 50px;
}

.login-btn {
  margin-top: 10px !important;
  width: 90% !important;
  margin-left: 20px !important;
  background-color: #347be7 !important;
  font-weight: 600 !important;
  padding: 10px !important;
  color: #fff !important;
  border: none !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: 0.2s !important;
  text-transform: capitalize !important;
}

.login-text-field {
  width: 90% !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
}

.good-morning-status {
  background-color: white;
  color: white;
  animation: sliceLeft 0.2s;
  height: 90vh;
  overflow-y: auto;
}

.loading-slice {
  animation: popup 0.1s;
}
.new-save-list {
  animation: sliceLeft1 0.2s !important;
}
@keyframes sliceLeft1 {
  from {
    right: -20px;
  }

  to {
    right: 0;
  }
}

@keyframes sliceLeft {
  from {
    margin-left: -100px;
  }

  to {
    margin-left: 0;
  }
}

.good-morning-shipment-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.good-morning-shipment-content1 {
  display: flex;
  align-items: center;
  color: white;
  background-color: rgb(24, 134, 243);
}

.good-morning-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: black;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
}

.good-morning-option h3 {
  margin-left: 5px;
  font-size: 13px;
}

.good-morning-container-header {
  padding: 10px;
}

.good-morning-container-header h1 {
  font-size: 20px;
}

.good-morning-option-btn {
  margin: 5px;
  display: flex;
}

.good-morning-option-btn button {
  padding: 12px;
  margin-left: auto;
  font-weight: 600;
  margin-top: 5px;
  width: 100%;
  font-size: 15px;
  background-color: #0176d6;
  color: white;
  border-radius: 10px;
  border: none;
}

.debit-note1-content {
  padding: 10px;
}

.debit-note1-body-option {
  display: flex;
}

.debit-note1-content-nav-option {
  display: flex;
}

.debit-note1-content-header {
  display: flex;
  justify-content: space-between;
}

.skydart-user-manage {
  background-color: rgb(27, 107, 177);
  color: white;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}

.skydart-user-manage h5 {
  font-weight: 600;
}

.skydart-user-manage:hover {
  opacity: 0.9;
}

.skydart-manage-plan {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.skydart-manage-plan {
  display: flex;
  padding: 5px;
}

.skydart-manage-plan select {
  margin: 5px;
  padding: 8px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}

.skydart-manage-plan select:hover {
  background-color: lightgray;
}

.skydart-manage-plan1 {
  display: flex;
  padding: 10px;
  border-top: 1px solid lightgray;
}

.skydart-manage-plan1 button {
  margin-left: auto;
  padding: 8px;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  background-color: rgb(51, 116, 214);
  color: white;
}

.book-question {
  margin-left: auto !important;
  border-radius: 999px !important;
  padding: 3px !important;
  cursor: pointer !important;
}

.book-question:hover {
  background-color: lightgray !important;
}

.skd-rate-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.skd-rate-header h3 {
  font-weight: 600;
  font-size: 15px;
}

.skd-rate-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skd-rate-body input {
  padding: 5px;
}

.skd-rate-header button {
  margin: 5px;
  padding: 6px;
  background-color: rgb(27, 87, 177);
  color: white;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.1s;
}

.skd-rate-header button:hover {
  opacity: 0.8;
}

#file-input {
  display: none;
}

.upload-icon {
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.upload-rate-icon {
  padding: 3px !important;
  font-size: 20px !important;
}

.upload-icon:hover {
  background-color: lightgray;
}

.rate-helper {
  position: absolute;
  padding: 5px;
  border: 1px solid;
  font-size: 11px;
  border-radius: 5px;
  font-weight: 600;
  margin-top: -65px;
  margin-left: 10px;
  background-color: rgb(75, 75, 75);
  color: white;
  opacity: 0;
  transition: 0.1s;
}

.upload-icon:hover .rate-helper {
  opacity: 1;
}

.skd-rate-body-rate-just {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-bottom: 5px;
}

.skd-rate-option:nth-child(1) {
  width: 40%;
}

.skd-rate-option:nth-child(2) {
  width: 35%;
}

.skd-rate-option:nth-child(3) {
  width: 15%;
}

.skd-rate-option {
  padding: 5px;
}

.skd-rate-body-header {
  width: 100%;
}

.skd-rate-option h5 {
  font-weight: 600;
}

.skd-rate-option p {
  font-size: 11px;
  font-weight: 600;
  color: black;
  margin-top: 5px;
  word-wrap: break-word;
}

.skd-rate-body-rate-just1:hover {
  background-color: lightgray;
}

.skd-rate-body-rate-just1 {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.recently-rate {
  height: 30vh;
  overflow-y: scroll;
}

.skd-rate-header select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  font-size: 13px;
  cursor: pointer;
  transition: 0.1s;
}

.skd-rate-header select:hover {
  background-color: lightgray;
}

.skd-record {
  background-color: red;
  position: sticky;
  bottom: 0px;
  border: none;
  border-top: 1px solid lightgray;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 15px; */
}

.so-lieu {
  font-weight: 700;
  font-size: 12px;
  color: #005ca7;
  width: 100%;
  border-radius: 3px;
  /* text-align: right; */
  padding: 7px;
  background-color: rgb(252, 252, 252);
  border: 1px solid lightgray;
}

.skydart-date-range-input {
  margin-right: 30px;
}

.skydart-date-range-header {
  padding: 10px;
}

.skydart-date-range-input input {
  width: 100%;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background-color: white;
  border: 1px solid lightgray;
  margin-top: 5px;
  outline: none;
  font-size: 14px;
  color: rgb(55, 55, 55);
}

.skydart-date-range-input h5 {
  font-weight: 600;
  margin-left: 2px;
}

.skydart-date-picker-context {
  display: flex;
  flex-direction: column;
}

.skydart-date-shortcut {
  margin-left: auto !important;
}

.phone-input-search {
  padding: 7px;
  border: 1px solid lightgray;
  margin-left: 5px;
}

.skydart-date-shortcut button {
  padding: 7px;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.1s;
  margin-left: 5px;
}

.skydart-date-shortcut button:hover {
  background-color: rgb(225, 225, 225);
}

.skydart-date-shortcut button:active {
  background-color: lightgray;
}

.date-month-back {
  position: absolute;
  border-radius: 3px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  cursor: pointer;
}

.date-month-back1 {
  position: absolute;
  border-radius: 3px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  cursor: pointer;
  right: 15px;
  margin-top: -3px;
}

.date-month-back:hover {
  background-color: lightgray;
}

.date-month-back:active {
  transform: scale(0.9);
}

.date-month-back1:hover {
  background-color: lightgray;
}

.date-month-back1:active {
  transform: scale(0.9);
}

.skydart-date-range-button {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.skydart-date-range-cancel {
  margin-left: auto;
  padding: 9px;
  border: none;
  font-size: 14px;
  background-color: rgb(230, 230, 230);
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(55, 55, 55);
  width: 90px;
}

.good-morning-cleared {
}

.skydart-date-range-apply {
  padding: 9px;
  width: 90px;
  border: none;
  font-size: 14px;
  background-color: #0074d9;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.skydart-date-range-cancel:hover {
  background-color: lightgray;
}

.skydart-date-range-apply:hover {
  background-color: #0581e7;
}

.skydart-date-range-cancel:active {
  transform: scale(0.95);
}

.skydart-date-range-apply:active {
  transform: scale(0.95);
}

.skydart-searched-result {
  border-radius: 999px;
  margin-left: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  animation: popup 0.1s;
  background-color: rgb(230, 230, 230);
}

.skydart-searched-result h5 {
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}

.skydart-searched-clear {
  cursor: pointer !important;
  transition: 0.1s !important;
  font-size: 15px !important;
  padding: 5px !important;
  border-radius: 999px !important;
  margin-left: 5px !important;
}

.skydart-searched-clear:active {
  transform: scale(0.95);
}

.skydart-searched-clear:hover {
  background-color: lightgrey !important;
}

.skydart-quick-search-mawb-sin {
  display: flex;
}

.skd-edit-new-booking {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: fadeIn 0.2s;
  background-color: rgba(0, 0, 0, 0.35);
}

.skd-edit-new-booking-container {
  background: white;
  margin-bottom: 0.5cm;
  border-radius: 3px;
  width: 1100px;
  overflow: hidden;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.skd-edit-new-booking-container1 {
  background: white;
  margin-bottom: 0.5cm;
  border-radius: 3px;
  width: 500px;
  overflow: hidden;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.skd-edit-new-booking-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(245, 245, 245);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-edit-new-booking-header h5 {
  font-weight: 600;
  font-size: 15px;
  color: rgb(75, 75, 75);
}

.skd-edit-new-booking-header-icon {
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: gray !important;
  background-color: rgb(230, 230, 230) !important;
  cursor: pointer !important;
}

.skd-edit-new-booking-header-icon:hover {
  color: black !important;
}

.skd-edit-new-booking-header-icon:active {
  background-color: lightgray !important;
}

.skd-edit-new-booking-body {
  height: 600px;
  display: flex;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.phone-clear-input {
  margin-left: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: rgb(230, 230, 230);
  justify-content: center;
  border-radius: 5px;
}

.phone-clear-input:active {
  transform: scale(0.9);
}

.skd-edit-new-booking-left {
  padding: 5px;
  width: 23%;
  height: 99%;
  overflow-y: scroll;
  border-right: 1px solid rgb(230, 230, 230);
}

.skd-edit-new-booking-left::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.skd-edit-new-booking-left::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-edit-new-booking-right::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.skd-edit-new-booking-right::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-edit-new-booking-center {
  width: 45%;
  height: 100%;
  border-right: 1px solid rgb(230, 230, 230);
  overflow-y: scroll;
}

.skd-edit-new-booking-center::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.skd-edit-new-booking-center::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.skd-edit-new-booking-left-option {
  padding: 5px;
  border-left: 3px solid #0175d4;
  cursor: pointer;
  display: flex;
  color: #0175d4;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-edit-new-booking-left-option3 {
  padding: 5px;
  border-left: 3px solid green;
  cursor: pointer;
  display: flex;
  background-color: rgb(223, 255, 227);
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-edit-new-booking-term {
  margin-top: 5px;
}

.skd-edit-new-booking-left-option1 {
  padding: 5px;
  border-left: 3px solid transparent;
  cursor: pointer;
  color: gray;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.skd-edit-new-booking-left-option2 {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  word-break: break-word;
  margin-top: 10px;
}

.skd-edit-new-booking-left-option2:hover {
  background-color: rgb(225, 225, 225);
}

.skd-edit-new-booking-left-option1:hover {
  color: black;
  border-left: 3px solid black;
}

.skd-edit-new-booking-left-option h5 {
  font-size: 13px;
  font-weight: 600;
}

.skd-edit-new-booking-left-option3 h5 {
  font-size: 13px;
  font-weight: 600;
  color: darkgreen;
}

.skd-edit-new-booking-left-option2 h5 {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 600;
}

.skd-edit-new-booking-left-option1 h5 {
  font-size: 13px;
  font-weight: 600;
}

.skd-edit-new-booking-right {
  background-color: rgb(235, 235, 235);
  width: 40%;
  height: 100%;
  overflow-y: scroll;
}

.skd-edit-new-booking-left-selection {
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: rgb(245, 245, 245);
}

.skd-edit-new-booking-left-selection-box {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.skd-edit-new-booking-left-selection-box:active {
  transform: scale(0.98);
}

.skd-edit-new-booking-left-selection-box:hover {
  background-color: rgb(235, 235, 235);
}

.skd-edit-new-booking-left-selection-box h5 {
  font-size: 12px;
  font-weight: 600;
}

.skd-edit-new-booking-center-type {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9999;
}

.skd-edit-new-booking-center-type select {
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  cursor: pointer;
  border: none;
  transition: 0.1s;
  outline: none;
}

.skd-edit-new-booking-center-type select:hover {
  background-color: lightgray;
}

.skd-edit-new-booking-center-shipping-info-header {
  background-color: rgb(245, 245, 245);
}

.skd-edit-new-booking-center-shipping-info-header h5 {
  font-weight: 600;
  font-size: 11px;
  margin-left: 10px;
}

.skd-edit-new-booking-country {
  padding: 5px;
}

.skd-edit-new-booking-country select {
  width: 100%;
  padding: 7px;
  border: 1px solid lightgray;
  outline: none;
  margin-top: 5px;
  cursor: pointer;
}

.skd-edit-new-booking-country select:focus {
  border: 1px solid blue;
}

.skd-edit-new-booking-center-shipping-info-body {
  padding: 5px;
}

.skd-edit-new-booking-country h5 {
  font-size: 10px;
  font-weight: 600;
  color: black;
}

.skd-edit-new-booking-country input {
  padding: 5px;
  margin-top: 5px;
  width: 100%;
  border: 1px solid lightgray;
  outline: none;
}

.skd-edit-new-booking-country input:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-right-header {
  padding: 10px;
}

.skd-edit-new-booking-right-header h5 {
  font-weight: 600;
}

.skd-edit-new-booking-pack-main {
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
  margin-top: 5px;
}

.skd-edit-new-booking-right-invoice {
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
  margin-top: 5px;
}

.skd-edit-new-booking-right-invoice-header {
  display: flex;
  align-items: center;
}

.skd-edit-new-booking-right-invoice-header-left h5 {
  font-weight: 600;
  font-size: 12px;
}

.skd-edit-new-booking-pack-main1 {
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
  margin-top: 5px;
}

.skd-edit-new-booking-right-dim {
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
  margin-top: 5px;
}

.skd-edit-new-booking-right-dim1 {
  display: flex;
}

.skd-edit-new-booking-pack-main1 input {
  width: 75px;
  margin-left: auto;
  padding: 3px;
  border: 1px solid lightgray;
  outline: none;
}

.skd-edit-new-booking-pack-main1 input:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-pack-main1 h5 {
  font-size: 12px;
  font-weight: 600;
  color: gray;
}

/* .skd-edit-new-booking-right-packing {
    margin-bottom: 5px;
} */
.skd-edit-new-booking-pack-main h5 {
  font-size: 12px;
  font-weight: 600;
}

.skd-new-booking-left-dim h5 {
  font-size: 10px;
  color: gray;
  font-weight: 600;
}

.skd-new-booking-center-dim h5 {
  font-size: 10px;
  text-align: center;
  color: gray;
  font-weight: 600;
}

.skd-new-booking-left-dim input {
  width: 60px;
  margin-right: 3px;
  padding: 3px;
  border: 1px solid lightgray;
  outline: none;
}

.skd-new-booking-left-dim input:focus {
  border: 1px solid #0064d9;
}

.skd-new-booking-center-dim input {
  width: 45px;
  margin-right: 3px;
  padding: 3px;
  background-color: rgb(240, 240, 240);
  border: 1px solid transparent;
  outline: none;
}

.skd-edit-new-booking-right-invoice-itemName input {
  margin-top: 5px;
  padding: 7px;
  width: 95%;
  border: 1px solid lightgray;
  outline: none;
}

.skd-edit-new-booking-right-invoice-itemName input:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-right-invoice-content {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.skd-edit-new-booking-right-invoice-itemName textarea {
  margin-top: 5px;
  padding: 7px;
  width: 95%;
  border: 1px solid lightgray;
  resize: vertical;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

.skd-edit-new-booking-right-invoice-itemName textarea:focus {
  outline: 1px solid rgb(51, 116, 214);
  outline-offset: 2px;
  box-shadow: 0px 0px 3px 0px rgb(51, 116, 214);
  border-radius: 5px;
}

.skd-edit-new-booking-right-invoice-content h5 {
  font-size: 12px;
  color: gray;
  font-weight: 600;
}

.skd-edit-new-booking-right-invoice-content input {
  width: 85%;
  padding: 5px;
  outline: none;
  border: 1px solid lightgray;
}

.skd-edit-new-booking-right-invoice-content input:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-right-invoice-content select:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-right-invoice-content select {
  width: 95%;
  padding: 5px;
  outline: none;
  border: 1px solid lightgray;
}

.skd-edit-new-booking-right-invoice-option {
  width: 200px;
}

.skd-edit-new-booking-term select {
  padding: 7px;
  width: 100%;
  outline: none;
  border: 1px solid lightgray;
}

.skd-edit-new-booking-term select:focus {
  border: 1px solid #0064d9;
}

.skd-edit-new-booking-button {
  padding: 5px;
  display: flex;
}

.skd-edit-new-booking-button-option {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.skd-new-booking-print {
  display: flex;
  align-items: center;
  margin-right: 5px;
  border: 1px solid rgb(230, 230, 230);
  background-color: white;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.1s;
}

.skd-new-booking-print2 {
  display: flex;
  align-items: center;
  margin-right: 5px;
  border: 1px solid rgb(230, 230, 230);
  background-color: white;
  padding: 5px;
  cursor: pointer;
  font-size: 13px;
  border-radius: 5px;
  transition: 0.1s;
}

.skd-new-booking-print2:hover {
  background-color: rgb(235, 235, 235);
}

.skd-new-booking-print:hover {
  background-color: rgb(230, 230, 230);
}

.skd-new-booking-print:active {
  background-color: rgb(220, 220, 220);
}

.skd-new-booking-print h5 {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  user-select: none;
}

.skd-new-booking-cancel {
  margin-right: 5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  padding: 7px;
  background-color: rgb(240, 240, 240);
  cursor: pointer;
  transition: 0.1s;
}

.skd-new-booking-cancel:hover {
  background-color: rgb(220, 220, 220);
}

.skd-new-booking-cancel:active {
  background-color: lightgray;
}

.skd-new-booking-cancel h5 {
  user-select: none;
  font-weight: 600;
}

.skd-new-booking-update {
  margin-right: 10px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid transparent;
  color: white;
  font-weight: 600;
  background-color: #0178da;
  transition: 0.1s;
  cursor: pointer;
  font-size: 13px;
}

.skd-new-booking-dus {
  margin-right: 5px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  font-weight: 600;
  background-color: rgb(240, 240, 240);
  transition: 0.1s;
  cursor: pointer;
  font-size: 13px;
}

.skd-new-booking-dus:hover {
  background-color: rgb(225, 225, 225);
}

.skd-new-booking-dus:active {
  background-color: lightgray;
}

.skd-new-booking-update:hover {
  background-color: #006fca;
}

.skd-new-booking-update:active {
  background-color: #005ca7;
}

.skydart-newbooking-export-container {
  position: absolute;
  z-index: 9999;
  margin-left: -4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  background-color: white;
  margin-top: 6px;
  width: 180px;
  user-select: none;
}

.export-clear {
  font-size: 16px !important;
  cursor: pointer !important;
}

.export-clear:hover {
  background-color: lightgray !important;
}

.skd-edit-new-booking-loading {
  position: absolute;
  margin-top: 80px;
  display: flex;
  border-radius: 999px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  width: 120px;
  right: 0;
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  background-color: white;
  animation: showDown 0.1s;
}

.skydart-pdf-file-newBooking {
  margin-left: 5px;
  padding: 7px;
  display: flex;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  user-select: none;
  align-items: center;
}

.skydart-pdf-file-newBooking h5 {
  font-weight: 600;
  margin-left: 5px;
}

.skydart_skydart__new__booking_sin a {
  font-size: 11.5px;
  color: black;
  cursor: pointer;
}

.skydart_skydart__new__booking_sin p {
  font-size: 11.5px;
  user-select: none;
}

.skydart_DUSlabel {
  position: relative;
}

.skydart_DUSlabel_container {
  cursor: pointer;
  width: fit-content;
}

.skydart_DUSlabel_container:hover p {
  text-decoration: underline;
}

.skydart_DUSlabel_container:active p {
  color: red;
}

.skydart_skydart__new__booking_sin a:hover {
  color: blue;
  text-decoration: underline;
}

.skydart_skydart__new__booking_sin a:active {
  color: red;
}

@keyframes showDown {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: 90px;
  }
}

.skd-new-booking-print-container {
  position: absolute;
  margin-top: -130px;
  width: 200px;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: 3px solid white;
  padding: 5px;
  border-radius: 5px;
  margin-left: -65px;
}

.skd-new-booking-print-container:after,
.skd-new-booking-print-container:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.skd-new-booking-print-container:after {
  border-color: transparent;
  border-top-color: transparent;
  border-width: 12px;
  margin-left: -30px;
}

.skd-new-booking-print-container:before {
  border-color: transparent;
  border-top-color: white;
  border-width: 12px;
  margin-left: -10px;
}

.skd-new-booking-print-option {
  display: flex;
  padding: 5px;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}

.skd-new-booking-print-option1 {
  display: flex;
  padding: 5px;
  align-items: center;
  cursor: not-allowed;
  border-radius: 3px;
  opacity: 0.3;
}

.skd-new-booking-print-option1 h5 {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}

.skd-new-booking-print-option:active {
  transform: scale(0.98);
}

.skd-new-booking-print-option:hover {
  background-color: rgb(230, 230, 230);
}

.skd-new-booking-print-option1:hover {
  background-color: rgb(230, 230, 230);
}

.skd-new-booking-print-option h5 {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}

.skd-edit-new-booking-country1 {
  background-color: white;
  position: absolute;
  min-height: 30px;
  max-height: 150px;
  z-index: 9999;
  width: 430px;
  margin-left: 5px;
  overflow-y: scroll;
  box-shadow:
    rgba(50, 50, 93, 0.15) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.skd-edit-new-booking-country-box {
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  color: gray;
}

.skd-edit-new-booking-country-box:hover {
  color: black;
  background-color: rgb(230, 230, 230);
}

.skd-edit-new-booking-country-box h5 {
  font-weight: 600;
  margin-left: 10px;
}

.search-column-input:focus {
  outline: 1px solid #002fca;
  outline-offset: 1px;
}
.search-column-input1 {
  outline: 1px solid #002fca;
  outline-offset: 1px;
}
.create-shipment-fade {
  animation: fadeIn 0.2s;
}
.track-shipment-customer-btn {
  font-weight: 600 !important;
  font-size: 17px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.skydart-customer-tracking {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/src/customer/routes/tracking.png);
}
@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.main-1 {
  background: #283c86; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #45a247, #283c86); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #45a247,
    #283c86
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.tracking-printing {
  page-break-after: always !important;
}
.shipment-exploit {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.skd-navbar {
  padding: 5px;
  display: flex;
  width: 250px;
  /* width: 15%; */
  flex-direction: column;
}
.skd-navbar-upload {
  padding: 7px;
  margin: 10px;
  background-color: #2380cc;
  color: white;
  border-radius: 20px;
  user-select: none;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.skd-navbar-upload h5 {
  font-weight: 600;
  margin-left: 5px;
}
.skd-navbar-upload:hover {
  background-color: #196caf;
}
.skd-navbar-upload:active {
  background-color: #15609c;
}
.skd-navbar-img {
  padding: 5px;
  display: flex;
  align-items: center;
}
.skd-navbar-img h3 {
  font-weight: 600;
  color: darkblue;
  margin-left: 5px;
}
.skd-navbar-option {
  padding: 5px;
}
.skd-navbar-option1 {
  padding: 5px;
  border-top: 1px solid rgb(226, 226, 226);
  margin-top: auto;
}
.dox-input-declare {
  margin-left: auto;
  padding: 5px;
  border: 1px solid rgb(220, 220, 220);
  outline: none;
  border-radius: 5px;
  width: 60px;
}
.skydart-draft-loading {
  margin-left: 10px;
  padding: 5px;
  width: 80px;
  border-radius: 3px;
  background-color: lightyellow;
}
.skydart-draft-loading p {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.skd-nav-link {
  padding: 7px;
  border-radius: 3px;
  display: flex;
  margin-top: 3px;
  margin-left: 0px;
  align-items: center;
  cursor: pointer;
  color: black;
  text-decoration: none !important;
}
.skydart-regular-country-render {
  background-color: white;
  z-index: 9999;
  max-height: 250px;
  min-height: 35px;
  top: 35px;
  margin-top: 7px;
  border-radius: 5px;
  overflow-y: scroll;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
  position: absolute;
  width: 320px;
}
.skydart-regular-country-render-option {
  padding: 9px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  transition: 0.1s;
}
.skydart-regular-country-render-option:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-regular-country-render-option:active {
  background-color: rgb(225, 225, 225);
}
.skydart-regular-country-render-option h5 {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-left: 5px !important;
}
.skd-nav-link p {
  margin-left: 20px;
  font-weight: 600;
  font-size: 13px;
}
.skydart-bookShipment-right-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  height: 83vh;
}
.skd-nav-active {
  background-color: rgb(186, 217, 250);
  color: black;
}
.skd-nav-active p {
  font-weight: 700;
}
.skd-nav-link:hover {
  background-color: rgb(232, 232, 232);
}
.skd-nav-active:hover {
  background-color: rgb(186, 217, 250);
}
.skd-nav-option {
  margin-top: auto;
}
.skd-nav-link:hover .hover-text {
  display: block;
}
.hover-text {
  position: absolute;
  margin-left: 160px;
  background-color: rgb(75, 75, 75);
  color: white;
  width: 90px;
  font-size: 11px;
  padding: 5px;
  z-index: 9999;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  display: none;
}
.skydart-draft {
  flex: 5;
}
.skd-dash-board {
  flex: 5;
}
.skd-reporting {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.skd-reporting-container {
  padding: 12px;
}
.skd-reporting-header {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skd-reporting-content {
  padding: 5px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 2.4px 2.4px 3.2px;
  background-color: white;
}
.skd-reporting-stat {
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 4.2px;
}
.skd-reporting-content input {
  padding: 9px;
  margin-left: 10px;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: rgb(230, 230, 230);
  width: 75%;
}
.skd-reporting-report-primary h3 {
  font-weight: 600;
}
.skd-reporting-report-primary h5 {
  margin-top: 5px;
  font-weight: 600;
  color: rgb(185, 57, 57);
}
.skd-reporting-content button {
  padding: 9px;
  border: none;
  border-radius: 7px;
  margin-left: 25px;
  font-weight: 600;
  cursor: pointer;
}
.skd-reporting-content button:hover {
  background-color: lightgray;
}
.skd-reporting-header h3 {
  font-weight: 600;
}
.skd-reporting-report {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.skd-reporting-report-primary {
  padding: 10px;
  width: 50%;
}
.skd-reporting-stat-head {
  padding: 5px;
  display: flex;
  align-items: center;
}
.skd-reporting-date {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.skd-reporting-date input {
  padding: 5px;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
}
.skd-reporting-dash-body {
  padding: 10px;
}
.skd-reporting-dash-header {
  padding: 10px;
  display: flex;
  align-items: center;
}
.skd-reporting-paid h5 {
  color: black;
  font-size: 15px;
}
.skd-reporting-paid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skd-reporting-unpaid h5 {
  color: black;
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
}
.skd-reporting-unpaid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skd-dash-board-header {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skd-reporting-dash {
  margin-top: 15px;
  width: 100%;
  border-radius: 7px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.skd-dash-board-header h3 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  font-size: 17px;
}
.skd-dash-board-hold {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.skd-dash-board-hold h5 {
  margin-left: 5px;
  font-weight: 600;
  font-size: 13px;
}
.skd-dash-board-header-left {
  display: flex;
  align-items: center;
  width: 60%;
}
.skd-dash-board-track {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 35%;
}
.skd-dash-board-track input {
  padding: 8px;
  border: 1px solid lightgray;
  outline: none;
  width: 100%;
  border-radius: 7px;
}
.skd-hold-reason {
  padding: 7px;
  color: rgb(201, 72, 72);
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.skd-hold-reason:hover {
  text-decoration: underline;
}
.skd-dash-board-header-right-container-option {
  display: flex;
  align-items: center;
  background-color: #0378d8;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
.skd-dash-board-header-right-container-option1 {
  display: flex;
  align-items: center;
  background-color: rgb(226, 226, 226);
  padding: 7px;
  margin-right: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.skd-dash-board-header-right-container-option h5 {
  margin-left: 5px;
  font-weight: 600;
  font-size: 13px;
}
.skd-dash-board-header-right-container-option1 h5 {
  font-weight: 600;
  font-size: 13px;
}
.skd-dash-board-header-right-container-option:hover {
  background-color: #0164b6;
}
.skd-dash-board-header-right-container-option1:hover {
  background-color: lightgray;
}
.skd-dash-board-header-right-container {
  display: flex;
  align-items: center;
}
.skd-dash-board-warn {
  margin: 10px;
  background-color: white;
  border-left: 5px solid #0764af;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.skd-dash-board-search {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.skd-dash-board-search-left {
  padding: 5px;
  background-color: white;
  width: 65%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
  border-radius: 10px;
  align-items: center;
}
.skd-dash-board-search-right {
  width: 50%;
  display: flex;
  align-items: center;
  margin-left: 7px;
  background-color: rgb(228, 228, 228);
  padding: 5px;
  border-radius: 10px;
  transition: 0.1s;
}
.skd-dash-board-search-right:hover {
  background-color: lightgray;
}
.skd-dash-board-search-left input {
  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 999px;
  padding-left: 27px;
  width: 30%;
  outline: none;
}
.skd-dash-board-search-right input {
  padding: 5px;
  border: none;
  width: 100%;
  outline: none;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5px;
}
.skd-dash-board-search-left input:focus {
  border: 1px solid rgb(230, 230, 230);
  outline: 1px solid #0360ac;
  outline-offset: 2px;
  box-shadow: 0 0 3px #2e7be0;
}
.skd-dash-board-warn-header {
  padding: 10px;
  display: flex;
  align-items: center;
}
.skd-dash-board-warn-header h3 {
  font-weight: 600;
  color: #0764af;
  font-size: 15px;
  margin-left: 5px;
}
.skd-dash-board-warn-body {
  padding: 10px;
  display: flex;
  padding-top: 0px;
  gap: 1em;
}
.dash-board-option {
  display: flex;
}
.skd-dash-board-status {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  display: flex;
}
.skd-dash-board-status-option {
  margin-left: 17px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  color: #0068be;
  align-items: center;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
}

.skd-dash-board-status-option1 {
  margin-left: 17px;
  background-color: rgb(241, 241, 241);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
}
.skd-dash-board-status-option2 {
  margin-left: 17px;
  margin-right: 17px;
  background-color: rgb(79, 143, 79);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  color: white;
  align-items: center;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
}
.skd-dash-board-status-option-hold {
  margin-left: 17px;
  background-color: rgb(201, 72, 72);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  width: 12%;
  cursor: pointer;
  padding: 5px;
  color: white;
  align-items: center;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
}
.skd-dash-board-status-option-hold h5 {
  margin-left: 5px;
  font-weight: 700;
  font-size: 14px;
}
.skd-dash-board-status-option h5 {
  margin-left: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #0068be;
}
.skd-dash-board-status-option1 h5 {
  margin-left: 5px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}
.skd-dash-fetch {
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
  min-height: fit-content;
  max-height: 70vh;
  border-top: 1px solid lightgray;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.skd-dash-fetch-container {
  border-bottom: 1px solid lightgray;
}
.skd-dash-fetch-header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  border-left: 3px solid white;
  z-index: 999;
  box-shadow:
    rgba(50, 50, 105, 0.05) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.skd-new-year {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.skd-new-year-container {
  margin-bottom: 130px;
  margin-top: 130px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
}
.skd-new-year-container button {
  margin-top: 10px;
  padding: 10px;
}
.skd-new-year-container img {
  width: 705px;
  user-select: none;
  animation: fadeIn 0.2s;
}
.skd-selected {
  display: flex;
  border-left: 3px solid #0360ac;
  background-color: rgb(233, 243, 255);
  color: #0360ac;
  border-bottom: 1px solid rgb(233, 243, 255);
}
.skd-selected:hover {
  background-color: rgb(212, 231, 255);
  border-left: 3px solid #0360ac;
}
.skd-dash-fetch-body {
  display: flex !important;
  border-left: 3px solid white !important;
  border-bottom: 1px solid rgb(230, 230, 230) !important;
}
.skd-dash-fetch-body:hover {
  background-color: rgb(235, 235, 235) !important;
  border-left: 3px solid rgb(226, 226, 226) !important;
}
.skd-dash-fetch-body:nth-last-child(1) {
  border: none;
}
.skd-bill-no-header {
  padding: 10px;
  border-right: 1px solid lightgray;
  width: 2%;
}
.skd-bill-hawb-header {
  padding: 10px;
  width: 9%;
  border-right: 1px solid lightgray;
}
.skd-bill-sin-header {
  padding: 10px;
  width: 7%;
  border-right: 1px solid lightgray;
}
.skd-bill-time-header {
  padding: 10px;
  width: 13%;
  border-right: 1px solid lightgray;
}
.skd-bill-destination-header {
  padding: 10px;
  width: 12%;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
}
.skd-bill-weight-header {
  padding: 10px;
  width: 5%;
  border-right: 1px solid lightgray;
}
.skd-bill-type-header {
  padding: 10px;
  width: 3%;
  border-right: 1px solid lightgray;
}
.skd-bill-status-header {
  padding: 10px;
  width: 5%;
  border-right: 1px solid lightgray;
}
.skd-bill-value-header {
  padding: 10px;
  width: 4%;
  border-right: 1px solid lightgray;
}
.skd-bill-descrip-header {
  padding: 10px;
  width: 20%;
}
.skd-bill-no-header h5 {
  font-size: 12px;
}
.skd-bill-hawb-header h5 {
  font-size: 12px;
  color: #0665b3;
}
.skd-bill-time-header h5 {
  font-size: 12px;
}
.skd-bill-destination-header h5 {
  font-size: 12px;
  color: #0665b3;
}
.skd-bill-weight-header h5 {
  font-size: 12px;
}
.skd-bill-type-header h5 {
  font-size: 12px;
}
.skd-bill-status-header h5 {
  font-size: 12px;
}
.skd-bill-value-header h5 {
  font-size: 12px;
}
.skd-bill-descrip-header h5 {
  font-size: 12px;
}

.skd-bill-no-body {
  padding: 10px;
  width: 2%;
  border-right: 1px solid lightgray;
}
.skd-bill-hawb-body {
  padding: 10px;
  width: 9%;
  border-right: 1px solid lightgray;
}
.skd-bill-sin-body {
  padding: 10px;
  width: 7%;
  border-right: 1px solid lightgray;
}
.skd-bill-sin-body h5 {
  font-size: 10px;
}
.skd-bill-time-body {
  padding: 10px;
  width: 13%;
  border-right: 1px solid lightgray;
}
.skd-bill-destination-body {
  padding: 10px;
  width: 12%;
  display: flex;
  align-items: center;
  border-right: 1px solid lightgray;
}
.skd-bill-weight-body {
  padding: 10px;
  width: 5%;
  border-right: 1px solid lightgray;
}
.skd-bill-type-body {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 3%;
  border-right: 1px solid lightgray;
}
.skd-bill-status-body {
  padding: 10px;
  width: 5%;
  border-right: 1px solid lightgray;
}
.skd-bill-value-body {
  padding: 10px;
  width: 4%;
  border-right: 1px solid lightgray;
}
.skd-bill-descrip-body {
  padding: 10px;
  width: 20%;
}
.skd-bill-no-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-hawb-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-time-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-destination-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-weight-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-type-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-status-body h5 {
  font-size: 12px;
  font-weight: 600;
}
.skd-bill-value-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-descrip-body h5 {
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
}
.skd-bill-destination-icon {
  font-size: 15px !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  padding: 2px !important;
  transition: 0.1s !important;
}
.skd-bill-destination-icon:hover {
  background-color: lightgray !important;
}
.skd-hawb-option {
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
.skd-hawb-option1 {
  display: flex;
  align-items: center;
  color: gray;
  margin-right: 5px;
  cursor: not-allowed;
}
.skd-hawb-option:hover {
  text-decoration: underline;
}
.skd-bill-hawb-option {
  position: absolute;
  display: none;
}
.skd-dash-fetch-body:hover .skd-bill-hawb-option {
  display: flex;
}
.skd-selected:hover .skd-bill-hawb-option {
  display: flex;
}
.skd-dash-fetch-body:hover .skd-bill-hawb {
}
.skd-selected:hover .skd-bill-hawb {
}
.skd-bill-destination-option {
  position: absolute;
  margin-top: 19px;
}
.skd-bill-destination-option h5 {
  font-size: 8px;
}
.skd-label {
  background-color: white;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}
.skd-label-header {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}
.skd-label-header-left h3 {
  font-weight: 600;
  font-size: 17px;
}
.skd-label-header-left {
  width: 5%;
}
.skd-label-header-center-btn {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #0068be !important;
}
.skd-label-header-center {
  width: 8%;
}
.skd-label-header-right-btn {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.55) !important;
}

.skd-label-alert {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}
.skd-label-warn {
  margin-top: 15px;
  margin-left: 30px;
  border-radius: 5px;
  margin-right: 30px;
  background-color: #f0f0f0;
}
.skd-label-alert h5 {
  font-weight: 600;
  font-size: 12px;
}
.skd-label-alert strong {
  margin-left: 5px;
  text-decoration: underline;
  color: #0068be;
  cursor: pointer;
}
.skd-label-receive {
  margin-left: auto;
}
.skd-label-warn-container {
  padding: 13px;
  display: flex;
  align-items: center;
}
.skd-label-warn-container h5 {
  font-size: 11px;
  font-weight: 600;
}
.skd-label-warn-mode {
  margin-left: auto;
}
.skd-label-list {
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
}
.skd-label-list h3 {
  font-weight: 600;
  font-size: 17px;
}
.skd-label-list input {
  margin-top: 5px;
  width: 20%;
  padding: 5px;
  border: 2px solid lightgray;
  border-radius: 3px;
  margin-left: auto;
  outline: none;
}
.skd-absolute {
  font-weight: 700;
  font-size: 10px;
  margin-left: auto;
  margin-top: -20px;
}
.skd-label-list input:focus {
  border: 2px solid #1d61b9;
}
.skd-label-list-container {
  margin-top: 10px;
}
.skd-label-list-container-header {
  background-color: #f0f0f0;
  display: flex;
  border-bottom: 1px solid lightgray;
}
.skd-label-list-container-body {
  display: flex;
  border-bottom: 1px solid lightgray;
  align-items: center;
}
.skd-label-hawb-header {
  padding: 5px;
  width: 65%;
}
.skd-label-hawb-body {
  padding: 5px;
  width: 65%;
  border-right: 1px solid lightgray;
}
.skd-label-hawb-header h5 {
  font-size: 11px;
}
.skd-label-hawb-body h5 {
  font-size: 11px;
  font-weight: 600;
  word-wrap: break-word;
}
.skd-label-date-header {
  padding: 5px;
  width: 10%;
}
.skd-label-date-body {
  padding: 5px;
  width: 10%;
}
.skd-label-date-header h5 {
  font-size: 11px;
}
.skd-label-date-body h5 {
  font-size: 11px;
  font-weight: 600;
}
.skd-label-note-header {
  padding: 5px;
  width: 10%;
}
.skd-label-note-body {
  padding: 5px;
  width: 10%;
}
.skd-label-note-header h5 {
  font-size: 11px;
}
.skd-label-note-body h5 {
  font-size: 11px;
  font-weight: 600;
}
.skd-label-action-header {
  padding: 5px;
  width: 10%;
}
.skd-label-action-body {
  padding: 5px;
  width: 10%;
}
.skd-label-action-header h5 {
  font-size: 11px;
}
.skd-label-action-body h5 {
  font-size: 11px;
  font-weight: 600;
  color: #0066b9;
  text-decoration: underline;
  cursor: pointer;
}
.get-start-btn {
  margin-top: 10px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: #0074d9 !important;
  color: white !important;
}
/* .printing {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 7%;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: white;
  animation: fadeIn 0.2s;
  width: 41%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */
/* .skydart-create-print1 {
  display: none;
}
@media print {
  .skydart-create-print1 {
      display: block;
  }
} */

.skydart-booking-hawb1 {
  display: none;
}

.skd-alert-cancel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: fadeIn 0.2s;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.35);
}
.skd-alert-container {
  background: white;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: rgba(0, 0, 0, 0.2) 2.4px 2.4px 3.2px;
  border-radius: 5px;
  margin-top: 15%;
  width: 35%;
  margin-left: 30%;
  margin-bottom: 70px;
  animation: popup 0.1s;
  overflow: hidden;
}
.skd-alert-header {
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skd-alert-header h1 {
  font-size: 18px;
  color: black;
}
.skd-alert-body {
  padding: 10px;
  padding-top: 0px;
}
.skd-alert-body-container {
  padding: 5px;
  color: rgb(201, 72, 72);
  background-color: rgb(255, 228, 228);
  border-radius: 5px;
}
.skd-alert-body h5 {
  font-weight: 600;
}
.skd-alert-body-button {
  margin-top: 10px;
  display: flex;
}
.skd-alert-body-button-option {
  margin-left: auto;
  display: flex;
  gap: 0.5em;
}
.skd-alert-delete {
  background-color: rgb(201, 72, 72);
  color: rgb(255, 228, 228);
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  border: none;
}
.skd-alert-cancel1 {
  padding: 7px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
}
.skydart-draft-edit-packing {
  margin-top: 5px;
}
.skydart-draft-edit-packing-form {
  margin-top: 5px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
}
.skydart-draft-edit-packing-form p {
  font-size: 13px;
}
.skydart-draft-edit-packing-form input {
  width: 80%;
  padding: 3px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  outline: none;
}
.skydart-draft-edit-packing-packageValue-option {
  display: flex;
  align-items: center;
}
.skydart-draft-edit-packing-packageValue {
  margin-top: 5px;
}
.skydart-draft-edit-packing-packageValue-container {
  display: flex;
  margin-top: 10px;
}
.skydart-draft-edit-invoice-draft {
  margin-top: 10px;
}
.skydart-draft-edit-invoice-head {
  background-color: rgb(82, 120, 163);
  color: white;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skydart-draft-edit-invoice-input p {
  font-size: 10px;
}
.skydart-draft-edit-packing-packageValue-option input {
  width: 44px;
  padding: 3px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  outline: none;
}
.skydart-draft-edit-packing-packageValue-option p {
  font-size: 10px;
  font-weight: 600;
  margin-left: 3px;
  margin-right: 3px;
}
.skydart-draft-edit-invoice-summary {
  margin-top: 10px;
}
.skydart-draft-edit-invoice-summary-option {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
.skydart-draft-edit-invoice-input input {
  padding: 5px;
  margin-top: 3px;
  width: 93%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3px;
}
.skydart-draft-edit-invoice-input select {
  padding: 5px;
  margin-top: 3px;
  width: 97%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3px;
}
.skydart-draft-edit-packing-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media print {
  .skydart-booking-hawb1 {
    display: block !important;
    page-break-after: always !important;
  }
  .printing4 {
    page-break-after: always !important;
  }
  .printing3 {
    page-break-after: always !important;
  }
  .invoice3 {
    page-break-after: always !important;
    padding-top: 10px !important;
  }
  .invoice3 input {
    display: none;
  }
  .packing-header-hawb {
    display: none;
  }
  .unit-after-edit {
    display: block !important;
  }
}

.staff-booking-left-header {
  padding: 10px;
}
.staff-booking-left-option {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#staff-booking-hidden {
  display: none;
}
.staff-booking-attach {
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgb(45, 114, 170);
  color: white;
  cursor: pointer;
}
.staff-booking-attach:hover {
  background-color: rgb(34, 98, 150);
}
.staff-booking-attach:active {
  background-color: rgb(28, 80, 122);
}
.staff-booking-left-body {
  margin: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.staff-booking-left-header h3 {
  color: white;
}
.staff-booking-left-option input {
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  border: none;
  width: 93%;
  outline: none;
  background-color: rgb(235, 235, 235);
}
.staff-booking-left-option button {
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  margin-top: 10px;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.staff-booking-left-option button:hover {
  background-color: rgb(221, 221, 221);
}
.staff-booking-left-option button:active {
  transform: scale(0.9);
}
.skydart-draft-search {
  display: flex;
  border-radius: 10px;
  padding: 5px;
  align-items: center;
}
.skydart-draft-search-right {
}
.skydart-draft-search-left h5 {
  font-weight: 600;
  margin-right: 5px;
}
.skydart-draft-search-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skydart-draft-search-right {
  display: flex;
  align-items: center;
}
.skydart-draft-search-right input {
  padding: 10px;
  padding-left: 45px;
  border: 1px solid transparent;
  background-color: rgba(201, 215, 233, 0.534);
  border-radius: 7px;
  outline: none;
  width: 450px;
  font-size: 15px;
  transition: 0.1s;
}
.skydart-draft-search-button button {
  padding: 10px;
  border: none;
  background-color: rgb(58, 111, 180);
  font-weight: 600;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.skydart-draft-body-header {
  display: flex;
  position: sticky;
  align-items: center;
  top: 0;
  z-index: 2;
  background-color: white;
}
.skydart-draft-search-query {
  padding: 10px;
}
.skydart-draft-invoice {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1px;
  color: white;
  display: flex;
  align-items: center;
  background-color: rgb(47, 168, 88);
}
.skydart-draft-invoice h5 {
  font-weight: 600;
  font-size: 10px;
  margin-left: 5px;
}
.skydart-draft-body-body {
  display: flex;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.skydart-draft-body-body1 {
  display: flex;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgb(193, 207, 224);
  background-color: rgb(226, 239, 255);
}
.skydart-draft-body-body:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.skydart-draft-body-header-refresh {
  padding: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 28px;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}
.skydart-draft-helper {
  position: absolute;
  font-weight: 600;
  font-size: 11px;
  user-select: none;
  padding: 5px;

  border-radius: 3px;
  margin-left: 2px;
  display: none;
  margin-top: 75px;
  background-color: rgb(55, 55, 55);
  color: white;
}
.skydart-draft-date {
  margin-right: 15px;
  margin-left: auto;
}
.skydart-draft-date h5 {
  font-weight: 600;
  font-size: 12px;
}
.skydart-draft-important {
  margin-top: 3px;
}
.skydart-draft-paginate {
  margin-left: auto;
  padding: 5px;
  display: flex;
}
.skydart-draft-search-box {
  position: absolute;
  background-color: white;
  border-top: 1px solid rgb(230, 230, 230);
  z-index: 9999;
  margin-top: 68px;
  transition: 0.1s;
  margin-top: 190px;
  height: 150px;
  word-wrap: break-word;
  overflow-y: auto;
  width: 512px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px;
}
.skydart-draft-content {
  width: 100%;
  display: flex;
  align-items: center;
}
.skydart-draft-hawb h5 {
  font-weight: 600;
  font-size: 13.5px;
  margin-left: 10px;
}
.skydart-draft-hawb {
  padding: 16px;
  width: 17%;
  display: flex;
  align-items: center;
}
#draft-invoice {
  display: none;
}
.skydart-draft-weight {
  margin-left: 35px;
}
.skydart-draft-weight p {
  font-size: 13px;
}
.skydart-draft-edit-invoice {
  display: flex;
  margin-top: 10px;
}
.skydart-draft-address {
}
.skydart-draft-country h5 {
  font-weight: 600;
  font-size: 13.5px;
}
.draft-invoice-label {
  padding: 7px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid rgb(240, 240, 240);
}
.draft-invoice-label:hover {
  background-color: rgb(230, 230, 230);
}
.draft-invoice-label:active {
  transform: scale(0.9);
}
.skydart-draft-body-header-refresh:hover {
  background-color: rgba(196, 202, 216, 0.527);
}
.skydart-draft-body-header-refresh:hover .skydart-draft-helper {
  animation: fadeIn 0.2s;
  display: block;
}
.skydart-draft-body-header-refresh:active {
  transform: scale(0.9);
}
.skydart-draft-body-header-refresh:active .skydart-draft-helper {
  white-space: nowrap;
  transform: scale(1);
  text-align: center;
}
.skydart-draft-body-header-checkbox {
  padding: 5px;
}
.skydart-draft-body-select {
  position: absolute;
  margin-top: 5px;
  z-index: 9999;
  width: 350px;
  border-radius: 5px;
  background-color: white;
  box-shadow:
    rgba(50, 50, 105, 0.35) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  padding: 5px;
}
.skydart-draft-body-select-option {
  padding: 10px;
  cursor: pointer;
  user-select: none;
  transition: 0.1s;
}
.skydart-draft-body-select-option:hover {
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
}
.skydart-draft-body-select-option:active {
  background-color: rgb(220, 220, 220);
}
.skydart-draft-body-select-option h5 {
  font-weight: 600;
  margin-left: 30px;
}
.skydart-draft-body-label {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 25%;
  user-select: none;
}
.skydart-draft-body-label1 {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 170px;
  user-select: none;
}
.skydart-draft-body-label:hover {
  background-color: rgb(245, 245, 245);
}
.skydart-draft-body-label:active {
  background-color: rgb(236, 236, 236);
}

.skydart-draft-body-label1:hover {
  background-color: rgb(245, 245, 245);
}
.skydart-draft-body-label1:active {
  background-color: rgb(236, 236, 236);
}
.skydart-draft-body-label1 h5 {
  font-weight: 600;
  margin-left: 15px;
  font-size: 14px;
}
.skydart-draft-body-label h5 {
  font-weight: 600;
  margin-left: 15px;
  font-size: 14px;
}
.skydart-draft-body-phan-loai {
  display: flex;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.skydart-draft-shipment-shown {
  margin-left: auto;
  margin-right: 7px;
}
.skydart-draft-shipment-shown h5 {
  font-weight: 600;
  font-size: 13px;
}
.skydart-draft-body {
  border-radius: 20px;
  margin-left: 20px;
  width: 100%;
  margin-right: 15px;
  margin-top: 7px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 3px;
  height: calc(100vh - 5.5rem);
  overflow-y: auto;
}
.skydart-draft-edit-form-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skydart-draft-sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9999;
}
.skydart-draft-edit-form-header p {
  font-size: 15px;
}
.skydart-draft-edit-form {
  margin-top: 7px;
  margin-left: 15px;
  width: 450px;
  margin-right: 32px;
  border-radius: 10px;
  height: 78vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 3px;
}
.skydart-draft-edit-address {
  margin-top: 5px;
}
.skydart-draft-edit-address input {
  margin-top: 5px;
  padding: 7px;
  outline: none;
  font-size: 14px;
  transition: 0.1s ease;
  width: 94%;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 3px;
}
.skydart-draft-edit-packing-packageValue-button {
  margin-top: 12px;
  display: flex;
}
.skydart-draft-edit-packing-packageValue-button-option {
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid rgb(230, 230, 230);
  margin-right: 5px;
  user-select: none;
}
.skydart-draft-edit-packing-packageValue-button-option:hover {
  background-color: rgb(240, 240, 240);
}
.skydart-draft-edit-packing-packageValue-button-option:active {
  transform: scale(0.9);
}
.skydart-draft-edit-packing-packageValue-button-option h5 {
  font-weight: 600;
}
.skydart-draft-edit-address input:focus {
  outline: 1px solid #0764af;
  outline-offset: 1.5px;
}
.skydart-draft-wrap {
  display: flex;
  width: 100%;
}
.skydart-draft-edit-form-hawb {
  padding: 10px;
  color: #0066b9;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.skydart-draft-edit-form-input {
  padding: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.skydart-draft-edit-book {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  border-top: 1px solid rgb(230, 230, 230);
  bottom: 0;
  justify-content: center;
  background-color: white;
  z-index: 9999;
}
.skydart-draft-save-shipment {
  padding: 7px;
  width: 90px;
  border-radius: 5px;
  margin-top: 12px;
  font-weight: 600;
  border: 1px solid rgb(225, 225, 225);
  background-color: white;
  cursor: pointer;
}
.skydart-draft-save-shipment:hover {
  background-color: rgb(230, 230, 230);
}
.skydart-draft-save-shipment:active {
  transform: scale(0.9);
}
.skydart-draft-book-shipment {
  margin-top: 7px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  border: none;
  background-color: rgb(60, 110, 167);
  color: white;
  font-weight: 600;
  width: 200px;
  cursor: pointer;
}
.skydart-draft-edit-country-container {
  position: absolute;
  z-index: 2;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
  max-height: 170px;
  min-height: fit-content;
  width: 100%;
  margin-top: 5px;
  overflow-y: scroll;
}
.skydart-draft-edit-country-option {
  padding: 10px;
  cursor: pointer;
  transition: 0.1s;
  user-select: none;
}
.skydart-draft-edit-country-option:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-draft-edit-country-option:active {
  background-color: rgb(220, 220, 220);
}
.skydart-draft-edit-country-option h5 {
  font-weight: 600;
  margin-left: 10px;
}
.skydart-draft-book-shipment:hover {
  opacity: 0.8;
}
.skydart-draft-book-shipment:active {
  background-color: rgb(62, 97, 138);
}
.skydart-draft-edit-form-type-option {
  border: 1px solid rgb(230, 230, 230);
  width: 47%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  user-select: none;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  height: 80px;
  transition: 0.1s;
}
.skydart-draft-edit-form-type-option1 {
  border: 1px solid transparent;
  width: 47%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  user-select: none;
  justify-content: center;
  background-color: rgb(82, 120, 163);
  color: white;
  cursor: pointer;
  flex-direction: column;
  height: 80px;
  transition: 0.1s;
}
.skydart-draft-edit-service-option {
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.skydart-draft-edit-service-option:hover {
  background-color: rgb(245, 245, 245);
}
.skydart-draft-edit-form-type-option:hover {
  background-color: rgb(240, 240, 240);
}
.skydart-draft-edit-form-type-option:active {
  background-color: rgb(226, 226, 226);
}
.skydart-draft-edit-form-type-option h5 {
  margin-top: 5px;
  font-weight: 600;
}
.skydart-draft-edit-form-hawb h3 {
  font-weight: 600;
  font-size: 17px;
}
.skydart-draft-search-button button:hover {
  background-color: rgb(43, 77, 122);
}
.skydart-draft-search-button button:active {
  transform: scale(0.9);
}
.skydart-draft-search-right input:focus {
  /* border: 1px solid transparent;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px; */
}
.skydart-draft-search-left {
  padding: 7px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  user-select: none;
  transition: 0.1s;
}
.skydart-draft-search-button {
  margin-left: 5px;
}
.skydart-draft-header {
  margin-top: 10px;
  margin-left: 15px;
  border-radius: 10px;
  margin-right: 10px;
}
.skydart-bookShipment-header {
  padding: 20px;
}
.skydart-bookShipment-header h3 {
  font-weight: 600;
  color: rgb(72, 83, 102);
  font-size: 22px;
  margin-left: 5px;
}
.skydart-bookShipment-container {
  display: flex;
  margin-left: 17px;
  margin-right: 9px;
}
.skydart-bookShipment-left {
  width: calc(100vw - 21.5rem);
}
.skydart-bookShipment-left-header {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}
.skydart-bookShipment-form {
  padding: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}
.skydart-bookShipment-service {
  padding: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}
.skydart-bookShipment-btn {
  padding: 10px;
  background-color: white;
  margin-top: 14px;
  margin-right: 15px;
  border-radius: 10px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
}
.skydart-bookShipment-btn-container {
  display: flex;
}
.skydart-bookShipment-cancel-btn {
  padding: 7px;
  margin-right: auto;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(240, 240, 240);
}
.skydart-bookShipment-cancel-btn:hover {
  background-color: rgb(232, 232, 232);
}
.skydart-bookShipment-cancel-btn:active {
  background-color: lightgray;
}
.skydart-bookShipment-btn-container p {
  font-size: 14px;
}
.skydart-bookShipment-book-btn {
  padding: 7px;
  margin-right: 2px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  transition: 0.1s;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: rgb(73, 130, 177);
  color: white;
}
.skydart-bookShipment-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skydart-bookShipment-loading-container {
  background-color: white;
  width: 340px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation: popup 0.1s;
}
.skydart-bookShipment-loading-container1 {
  background-color: white;
  width: 420px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation: popup 0.1s;
}
.skydart-bookShipment-save-confirm-cancel {
  padding: 10px;
  border-radius: 5px;
  border: none;
  user-select: none;
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  transition: 0.1s;
}
.skydart-bookShipment-save-confirm-book-another {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
  user-select: none;
  cursor: pointer;
  background-color: rgb(87, 132, 168);
  transition: 0.1s;
  color: white;
  font-weight: 600;
}
.skydart-bookShipment-save-confirm-book-another:hover {
  background-color: rgb(72, 114, 148);
}
.skydart-bookShipment-save-confirm-cancel:hover {
  background-color: rgb(225, 225, 225);
}
.skydart-bookShipment-save-confirm-book-another:active {
  background-color: rgb(60, 98, 129);
}
.skydart-bookShipment-save-confirm-option {
  margin-top: auto;
  padding: 15px;
  display: flex;
}
.skydart-bookShipment-save-btn {
  padding: 5px;
  margin-right: 2px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  transition: 0.1s;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: rgb(73, 130, 177);
  color: white;
}
.skydart-bookShipment-save-btn:hover {
  background-color: rgb(60, 112, 155);
}
.skydart-bookShipment-save-btn:active {
  background-color: rgb(42, 90, 129);
}
.skydart-bookShipment-book-btn1 {
  padding: 7px;
  margin-right: 2px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  transition: 0.1s;
  font-size: 14px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.5;
  border: 1px solid transparent;
  background-color: rgb(73, 130, 177);
  color: white;
}
.skydart-bookShipment-book-btn:hover {
  background-color: rgb(53, 99, 138);
}
.skydart-bookShipment-book-btn:active {
  background-color: rgb(43, 91, 131);
}
.skydart-bookShipment-service input {
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
  margin-right: 5px;
  padding: 7px;
  border: 1px solid rgb(213, 223, 236);
  border-radius: 5px;
  width: 94%;
  outline: none;
}
.skydart-bookShipment-service input:hover {
  outline: 1px solid rgb(214, 214, 214);
}
.skydart-bookShipment-service input:focus {
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
}
.skydart-bookShipment-invoice {
  padding: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
}
.skydart-bookShipment-term {
  padding: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
}
.skydart-bookShipment-packing {
  padding: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
}
.skydart-bookShipment-right {
  margin-left: auto;
}
.skydart-bookShipment-service h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-term h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-invoice h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-form h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-packing h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-left-header h5 {
  margin-left: 5px;
  font-size: 15px;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-draft-hawb {
  margin-left: 5px;
  background-color: rgb(240, 240, 240);
  border-left: 5px solid rgb(225, 225, 225);
  font-size: 14px;
}
.skydart-bookShipment-draft-hawb p {
  margin-left: 3px;
}
.skydart-bookShipment-draft-type {
  margin-left: 5px;
  font-size: 15px;
}
.skydart-bookShipment-draft-service {
  margin-left: 5px;
  font-size: 15px;
}
#book-upload {
  display: none;
}
.label-upload {
  padding: 7px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(249, 249, 250);
  transition: 0.2s;
  cursor: pointer;
  margin-right: 7px;
  user-select: none;
}
.label-upload:active {
  background-color: rgb(230, 230, 230) !important;
}
.label-upload1 {
  padding: 7px;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(50, 113, 155);
  transition: 0.2s;
  color: white;
  cursor: pointer;
  margin-right: 7px;
}
.skydart-bookShipment-attach {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.2s;
  align-items: center;
}
.skydart-bookShipment-attach-container {
  background-color: white;
  border-radius: 3px;
  width: 600px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-top: 10%;
  margin-bottom: 10%;
}
.skydart-bookShipment-attach-header {
  padding: 10px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skydart-bookShipment-attach-clear {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skydart-bookShipment-attach-body-content {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid lightgray;
}
.skydart-bookShipment-attach-body {
  border-bottom: 1px solid lightgray;
}
.skydart-bookShipment-attach-body-selection {
  margin: 15px;
  display: flex;
  align-items: center;
}
.skydart-bookShipment-attach-body-content h5 {
  line-height: 2;
  font-weight: 700;
}
.uploading-book {
  color: rgb(50, 58, 66);
  padding: 8px;
  font-size: 13px;
  border-radius: 1px;
  margin-right: 7px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(230, 230, 230);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.uploading-book:hover {
  background-color: rgb(230, 230, 230);
}
.uploading-book:active {
  border: 1px solid;
}
.skydart-bookShipment-attach-clear:hover {
  background-color: rgb(230, 230, 230);
}
.skydart-bookShipment-attach-clear:active {
  background-color: lightgray;
}
.skydart-bookShipment-attach-header h3 {
  font-size: 16px;
  font-weight: 600;
}
.label-upload:hover {
  background-color: #e7e7e7;
  transition: 0.2s;
}
.label-upload p {
  font-size: 14px;
  margin-left: 7px;
}
.label-upload1 p {
  font-size: 14px;
  margin-left: 7px;
}
.skydart-bookShipment-select-type {
  margin-top: 10px;
  border: 1px solid;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(213, 223, 236);
}
.skydart-bookShipment-select-type:hover {
  outline: 1px solid rgb(230, 230, 230);
  background-color: rgb(250, 250, 250);
}
.skydart-bookShipment-select-type h3 {
  font-weight: 600;
  font-size: 15px;
  margin-left: 10px;
}
.skydart-bookShipment-select-type-container {
  position: absolute;
  background-color: white;
  z-index: 9999;
  margin-left: 5px;
  width: 455px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-top: 10px;
  animation: fade 0.1s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.skydart-bookShipment-select-type-container h3 {
  font-weight: 600;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 450;
}
.skydart-bookShipment-select-type-option {
  padding: 7px;
  margin: 5px;
  user-select: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #e0f0ff;
}
.skydart-bookShipment-select-type-option1 {
  padding: 7px;
  margin: 5px;
  user-select: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.1s;
}
.skydart-bookShipment-select-type-option1:hover {
  background-color: rgb(240, 240, 240);
}
.skydart-bookShipment-select-type-option1:active {
  background-color: rgb(230, 230, 230);
}
@keyframes fade {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 10px;
  }
}
.skydart-bookShipment-save-display {
  position: absolute;
  background-color: white;
  width: 120px;
  margin-top: 3px;
}
.skydart-bookShipment-save-save {
  padding: 7px;
  user-select: none;
  cursor: pointer;
}
.skydart-bookShipment-save-save:active {
  background-color: lightgray;
}
.skydart-bookShipment-save-save:hover {
  background-color: rgb(230, 230, 230);
}
.booking-area {
  padding: 7px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 94.5%;
  height: 150px;
  border-radius: 3px;
  cursor: default;
  outline: none;
  border: 1px solid rgb(199, 207, 218);
  resize: none;
  transition: 0.1s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.skydart-bookShipment-countries-container {
  height: 160px;
  margin-bottom: 5px;
  /* border-top: 1px solid rgb(199,207,218); */
  overflow-y: auto;
  width: 70%;
  animation: fadeIn 0.2s;
}
.skydart-bookShipment-countries {
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  animation: fadeIn 0.2s;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 3px;
  background-color: rgb(245, 245, 245);
  align-items: center;
  user-select: none;
  transition: 0.1s;
  cursor: pointer;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 2px;
}

.skydart-bookShipment-countries:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-bookShipment-countries:active {
  background-color: lightgray;
}
.skydart-bookShipment-countries p {
  font-weight: 600;
  margin-left: 7px;
  font-size: 13px;
}
.booking-area:hover {
  border: 1px solid rgb(157, 165, 173);
}
.booking-area:focus {
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
  cursor: text;
  transition: 0.1s inset;
}
.skydart-bookShipment-shortcut-option {
  color: rgb(61, 78, 104);
  margin-right: 7px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  transition: 0.1s;
}
.skydart-bookShipment-shortcut-option p {
  font-size: 12px;
  font-weight: 600;
}
.skydart-bookShipment-packing-info-header-option1 p {
  font-size: 11px;
  font-weight: 600;
}
.skydart-bookShipment-shortcut-option:hover {
  background-color: rgb(230, 230, 230);
}
.skydart-bookShipment-shortcut-option:active {
  background-color: rgb(220, 220, 220);
}
.skydart-bookShipment-wrap {
  overflow-y: scroll;
  padding: 3px;
  height: calc(100vh - 11rem);
}
.skydart-bookShipment-wrap::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
.skydart-bookShipment-wrap::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(119, 119, 119, 0.562);
}
.skydart-bookShipment-packing-header {
  margin-top: 7px;
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
}
.skydart-bookShipment-packing-header-option {
  background-color: rgb(245, 245, 245);
  padding: 5px;
  border: 1px solid rgb(230, 230, 230);
  margin-right: 7px;
  border-radius: 3px;
}
.skydart-bookShipment-packing-header-option p {
  font-size: 11.5px;
}
.skydart-bookShipment-packing-info {
  margin-top: 10px;
  margin-bottom: 10px;
}
.skydart-bookShipment-info-packing-header {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
}
.skydart-bookShipment-packing-info-header-option {
  display: flex;
  width: 2000px;
}
.skydart-bookShipment-packing-info-header-option1 {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  color: rgb(78, 90, 122);
  background-color: rgb(255, 255, 255);
  padding: 4px;
  border: 1px solid rgb(184, 192, 209);
  user-select: none;
}
.ss {
  background: rgb(243, 255, 251);
}
.skydart-bookShipment-packing-info-header-option3 {
  width: 30px;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  color: rgb(78, 90, 122);
  background-color: rgb(255, 255, 255);
  padding: 4px;
  border: 1px solid rgb(184, 192, 209);
  user-select: none;
  margin-left: 3px;
}
.skydart-bookShipment-packing-info-header-option3:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-bookShipment-packing-info-header-option3:active {
  transform: scale(0.9);
}
.skydart-bookShipment-packing-info-header-option4 {
  width: 50px;
}
.skydart-bookShipment-packing-info-header-option2 {
  width: 175px;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  color: rgb(78, 90, 122);
  background-color: rgb(255, 255, 255);
  padding: 4px;
  border: 1px solid rgb(184, 192, 209);
  user-select: none;
}
.skydart-bookShipment-packing-info-header-option1:hover {
  background-color: rgb(235, 235, 235);
}
.skydart-bookShipment-packing-info-header-option1:active {
  transform: scale(0.9);
}
.skydart-bookShipment-packing-info-header-option p {
  font-size: 11px;
  margin-left: 1px;
}
.skydart-bookShipment-packing-info-dim {
  width: 90px;
  display: flex;
  align-items: center;
}
.skydart-bookShipment-packing-info-kg input {
  width: 80%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 3px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: rgb(240, 240, 240);
}
.skydart-bookShipment-packing-info-dim input {
  width: 50%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 3px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: rgb(240, 240, 240);
}
.skydart-bookShipment-invoice-wrap {
  margin-left: 5px;
  margin-right: 5px;
}
.skydart-bookShipment-invoice-header {
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: rgb(243, 243, 243);
}
.skydart-bookShipment-invoice-container {
  margin-top: 12px;
}
.skydart-bookShipment-invoice-box-option {
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.skydart-bookShipment-invoice-box-option p {
  margin-left: 5px;
  margin-right: 5px;
  width: 32%;
  font-size: 14px;
}
.skydart-bookShipment-invoice-box-option input {
  padding: 5px;
  width: 61%;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgb(202, 208, 218);
}
.skydart-bookShipment-invoice-box-option select {
  padding: 7px;
  width: 61%;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid rgb(202, 208, 218);
}
.skydart-bookShipment-invoice-box-option select:hover {
  outline: 1px solid rgb(214, 214, 214);
}
.skydart-bookShipment-invoice-box-option input:hover {
  outline: 1px solid rgb(214, 214, 214);
}
.skydart-bookShipment-invoice-box-option select:focus {
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
}
.skydart-bookShipment-invoice-box-option input:focus {
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
}
.skydart-bookShipment-right-header {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skydart-bookShipment-right-header-icon {
  display: flex;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 3.2px;
  align-items: center;
}
.skydart-bookShipment-right-header h5 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-right-header-icon-option {
  padding: 7px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-right: 1px solid rgb(236, 236, 236);
  align-items: center;
  transition: 0.1s;
}
.skydart-bookShipment-right-header-icon-option1 {
  padding: 7px;
  display: flex;
  cursor: pointer;
  background-color: rgb(160, 199, 224);
  justify-content: center;
  border-right: 1px solid rgb(236, 236, 236);
  align-items: center;
  transition: 0.1s;
}
.skydart-bookShipment-right-header-icon-option:hover {
  background-color: rgb(160, 199, 224);
}
.skydart-bookShipment-right-header-icon-option:nth-last-child(1) {
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.skydart-bookShipment-right-header-icon-option:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.skydart-bookShipment-right-header-icon-option1:nth-last-child(1) {
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.skydart-bookShipment-right-header-icon-option1:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.skydart-bookShipment-right-header-icon-option p {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}
.skydart-bookShipment-right-header-icon-option1 p {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}
.skydart-bookShipment-term-container {
  margin-right: 5px;
  display: flex;
  margin-left: auto;
}
.skydart-bookShipment-term-option {
  padding: 7px;
  width: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(235, 235, 235);
  transition: 0.1s;
  justify-content: center;
}
.skydart-bookShipment-term-option1 {
  padding: 7px;
  width: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-color: rgba(82, 120, 170, 0.459);
  border: 1px solid rgb(235, 235, 235);
  transition: 0.1s;
  justify-content: center;
}
.skydart-bookShipment-term-option p {
  font-size: 14px;
}
.skydart-bookShipment-term-option1 p {
  font-size: 14px;
}
.skydart-bookShipment-term-option:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.skydart-bookShipment-term-option:nth-last-child(1) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.skydart-bookShipment-term-option1:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.skydart-bookShipment-term-option1:nth-last-child(1) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.skydart-bookShipment-term-option:hover {
  background-color: rgba(82, 120, 170, 0.459);
}
.skydart-bookShipment-term-option:active {
  background-color: rgba(58, 88, 128, 0.459);
}
.skydart-bookShipment-subTotal-option {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skydart-bookShipment-subTotal {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
}
.skydart-bookShipment-subTotal-option p {
  font-weight: 600;
  color: rgb(36, 61, 100);
}
.skydart-bookShipment-subTotal-btn {
  margin-left: auto;
  display: flex;
  margin-top: 5px;
}
.skydart-bookShipment-subTotal-cancel {
  padding: 4px;
  margin-right: 5px;
  user-select: none;
  cursor: pointer;
  border: 1px solid rgb(220, 220, 220);
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
  display: flex;
  align-items: center;
  transition: 0.1s;
}
.skydart-bookShipment-subTotal-cancel:hover {
  background-color: rgb(220, 220, 220);
}
.skydart-bookShipment-subTotal-cancel:active {
  background-color: lightgray;
}

.skydart-bookShipment-subTotal-cancel p {
  font-size: 13.5px;
  margin-left: 5px;
}
.skydart-bookShipment-transportValue {
  padding: 5px;
}
.skydart-bookShipment-transportValue input {
  padding: 7px;
  width: 96.5%;
  font-size: 14px;
  outline: none;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid rgb(202, 208, 218);
}
.skydart-bookShipment-transportValue p {
  font-size: 13px;
}
.skydart-bookShipment-transportValue input:focus {
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
}
.skydart-bookShipment-invoice-attached {
  margin-top: 10px;
}
.skydart-bookShipment-invoice-attached-header {
  display: flex;
  padding: 5px;
  background-color: rgb(245, 245, 245);
}
.skydart-bookShipment-invoice-attached-body {
  display: flex;
  margin-top: 3px;
  padding: 5px;
}
.skydart-bookShipment-invoice-attached-option:nth-child(1) {
  width: 260px;
}
.skydart-bookShipment-invoice-attached-option:nth-child(2) {
  width: 100px;
}
.skydart-bookShipment-invoice-attached-option:nth-child(3) {
  width: 90px;
}

.skydart-bookShipment-invoice-attached-header p {
  font-size: 13px;
  font-weight: 600;
}
.skydart-bookShipment-invoice-attached-option1:nth-child(1) {
  width: 260px;
}
.skydart-bookShipment-invoice-attached-option1:nth-child(2) {
  width: 100px;
}
.skydart-bookShipment-invoice-attached-option1:nth-child(3) {
  width: 90px;
}

.skydart-bookShipment-invoice-attached-body p {
  font-size: 12px;
}
.file-attached-text {
  position: absolute !important;
  background-color: red !important;
  color: white !important;
  z-index: 9999 !important;
  margin-top: -40px !important;
  right: 0 !important;
  padding: 3px !important;
  min-width: 15px !important;
  font-size: 12px !important;
  border-radius: 999px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  animation: popup 0.1s !important;
  justify-content: center !important;
}
.skydart-regular-booking-option {
  margin-top: 5px;
}
.skydart-regular-booking-name {
  display: flex;
  margin-top: 5px;
  width: 100%;
  align-items: center;
}
.skydart-regular-booking-name1 {
  display: flex;
  margin-top: 5px;
  width: 100%;
  align-items: center;
}
.skydart-regular-booking {
  padding: 5px;
}
.skydart-regular-booking-name select {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 330px;
  font-size: 14px;
  border: none;
  background-color: rgb(249, 251, 255);
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(213, 223, 236);
}
.skydart-regular-booking-name input {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: rgb(249, 251, 255);
  outline: none;
  border-radius: 20px;
  border: 1px solid rgb(213, 223, 236);
}
.skydart-regular-booking-name input:focus {
  background-color: white;
  border: 1px solid rgb(195, 220, 255);
  outline: 1px solid rgb(31, 82, 139);
  outline-offset: 1px;
}

.skydart-regular-booking-name p {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}
.skydart-regular-booking-option {
  display: flex;
  align-items: center;
}
.skydart-regular-booking-option p {
  width: 30%;
}
.skd-new-booking-location {
  display: flex;
  align-items: center;
}
.skd-new-booking-location-option {
  padding: 5px;
  margin-right: 3px;
  width: 35px;
  background-color: rgb(248, 252, 255);
  display: flex;
  border-radius: 5px;
  color: rgb(31, 82, 139);
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: center;
}
.skd-new-booking-location-option:hover {
  background-color: rgb(230, 239, 245);
}
.skd-new-booking-location-option:active {
  background-color: rgb(237, 244, 250);
}
.skd-new-booking-location h5 {
  font-weight: 600;
}
.skd-new-booking-location1 h5 {
  font-weight: 600;
}
.skd-new-booking-location-option1 {
  padding: 5px;
  margin-right: 3px;
  width: 35px;
  background-color: rgb(230, 239, 245);
  display: flex;
  border-radius: 5px;
  color: rgb(31, 82, 139);
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: center;
}
.skydart-bookShipment-btn-container {
}
.hawb-bill-address-detail h5 {
  width: 35%;
}
.currentPage-input input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.currentPage-input input[type='number'] {
  -moz-appearance: textfield;
}

.package-box {
  width: 30px;
  height: 30px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(45deg);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.box-face {
  position: absolute;
  width: 30px;
  height: 30px;
  background: linear-gradient(145deg, #004a70, #006b3f);
  border: 1px solid #003366;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* More detailed smaller shadows for softer effect */
.front {
  transform: translateZ(17px);
  background: linear-gradient(145deg, #003366, #006b3f);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.top {
  transform: rotateX(90deg) translateZ(17px);
  background: linear-gradient(145deg, #006b3f, #003366);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.side {
  transform: rotateY(90deg) translateZ(17px);
  background: linear-gradient(145deg, #003366, #006b3f);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

/* Paper Stick - Add refined and realistic appearance */
.paper-stick {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 12px;
  height: 6px;
  background-color: #fafafa; /* Light grayish for a clean, luxury feel */
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #dddddd;
}

.paper-text {
  font-size: 5px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  line-height: 6px;
}

/* Refined and more detailed package appearance */
.package-box {
  background: linear-gradient(135deg, #003366 50%, #006b3f 50%);
  border: 1px solid #003366;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.package-box .box-face {
  background: linear-gradient(135deg, #003366, #006b3f);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.front {
  border: 1px solid #003366;
  background: linear-gradient(135deg, #003366, #006b3f);
}

.top {
  background: linear-gradient(135deg, #006b3f, #003366);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

.side {
  background: linear-gradient(135deg, #003366, #006b3f);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

.checkbox-wrapper-41 {
  --size: 60px;
}
.workroom-notebook-center1 {
  background-image: url('./assets/dot.svg');
}

.checkbox-wrapper-41 input[type='checkbox'] {
  -webkit-appearance: none;
  width: var(--size);
  height: calc(var(--size) / 2);
  background-color: #fff;
  border: 3px solid #222;
  border-radius: 30px 100px 100px 100px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.5s;
}

.checkbox-wrapper-41 input[type='checkbox']::before {
  content: '';
  position: absolute;
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  left: 0;
  top: 50%;
  transform: translateY(-50%) scale(0.7);
  border: 3px solid #222;
  border-radius: 30px 100px 100px 100px;
  background-color: #fde881;
  box-sizing: border-box;
  transition: all 0.5s;
}

.checkbox-wrapper-41 input[type='checkbox']:checked {
  background-color: #fde881;
  border-radius: 100px 100px 30px 100px;
}

.checkbox-wrapper-41 input[type='checkbox']:checked::before {
  left: 50%;
  background-color: #fff;
  border-radius: 100px 100px 30px 100px;
}

.dimensions-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.scene {
  perspective: 600px;
  position: relative;
}

.box-hawb {
  width: 50px;
  height: 50px;

  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
}

.rectangle-box {
  width: 80px;
  height: 50px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
}

@keyframes rotateBox {
  0% {
    scale: 0;
    /* transform: rotateX(-30deg) rotateY(0deg); */
  }
  100% {
    scale: 1;
    /* transform: rotateX(-30deg) rotateY(360deg); */
  }
}
.popup-dog {
  animation: rotateBox 0.1s;
}

.face,
.rect-face {
  position: absolute;
  background-color: #b5651d; /* Brown color */
  border: 2px solid #8b4513;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.face {
  width: 50px;
  height: 50px;
}

.rect-face {
  width: 80px;
  height: 50px;
}

.face.front {
  transform: translateZ(25px);
}
.face.back {
  transform: rotateY(180deg) translateZ(25px);
}
.face.left {
  transform: rotateY(-90deg) translateZ(25px);
}
.face.right {
  transform: rotateY(90deg) translateZ(25px);
}
.face.top {
  transform: rotateX(90deg) translateZ(25px);
}
.face.bottom {
  transform: rotateX(-90deg) translateZ(25px);
}

.rect-face.front {
  transform: translateZ(25px);
}
.rect-face.back {
  transform: rotateY(180deg) translateZ(25px);
}
.rect-face.left {
  height: 50px;
  width: 50px;
  margin-left: 15px;
  transform: rotateY(-90deg) translateZ(40px);
}
.rect-face.right {
  transform: rotateY(90deg) translateZ(40px);
  height: 50px;
  width: 50px;
}
.rect-face.top {
  transform: rotateX(90deg) translateZ(25px);
}
.rect-face.bottom {
  transform: rotateX(-90deg) translateZ(25px);
}

.details {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.ruler {
  width: 80px;
  height: 10px;
  background: #ddd;
  border: 1px solid #aaa;
  text-align: center;
  line-height: 10px;
  font-size: 8px;
}
.pending-text {
  transition: all 0.3s ease-in-out;
}
.label {
  width: 50px;
  height: 20px;
  background: white;
  border: 1px solid #aaa;
  text-align: center;
  line-height: 20px;
  font-size: 8px;
}

.tape {
  width: 50px;
  height: 5px;
  background: #ffc107;
  border: 1px solid #ff9800;
  text-align: center;
  line-height: 5px;
  font-size: 8px;
}

.icon-container {
  margin-top: 20px;
}

.tape-icon {
  margin: 10px auto;
  display: block;
}

@keyframes fadeInTopLeftToBottomRight {
  0% {
    opacity: 0;
    transform: translate(-20px, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.fadebox-stg {
  animation: fadeInTopLeftToBottomRight 0.25s ease-out forwards;
}
.box-stg {
  animation: fadeInTopLeftToBottomRight 0.25s;
  animation-delay: 1s;
}

.word-generating-select-box {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  width: 40%;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  outline: none;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 13.5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 13px;
}

.word-generating-select-box:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
