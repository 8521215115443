.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(200, 207, 255, 0.3);
  backdrop-filter: blur(10px);
  z-index: 9999;
  overflow: hidden;
}

/* Snowflakes */
.snowflake {
  position: absolute;
  top: -10%; /* Start above the viewport */
  left: 50%;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
  animation:
    snow-fall linear infinite,
    sway ease-in-out infinite;
}

.snowflake:nth-child(1) {
  left: 10%;
  animation-duration: 6s, 2s;
  animation-delay: 0s, 0.2s;
}

.snowflake:nth-child(2) {
  left: 30%;
  animation-duration: 8s, 3s;
  animation-delay: 1s, 0.4s;
}

.snowflake:nth-child(3) {
  left: 50%;
  animation-duration: 5s, 2.5s;
  animation-delay: 0.5s, 0.6s;
}

.snowflake:nth-child(4) {
  left: 70%;
  animation-duration: 7s, 2.2s;
  animation-delay: 1.2s, 0.3s;
}

.snowflake:nth-child(5) {
  left: 90%;
  animation-duration: 9s, 2.8s;
  animation-delay: 0.8s, 0.5s;
}

/* Snowfall Animation */
@keyframes snow-fall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh); /* Move down the entire viewport */
  }
}

/* Sway Animation */
@keyframes sway {
  from {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

/* Letter container */
.letter-container {
  max-width: 600px;
  /* margin: 20px auto;
  padding: 30px; */
  background: rgba(255, 255, 255, 0.7);
  border-radius: 16px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
  color: #4a4a4a;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  animation: pop-in 0.5s ease-out;
}

/* Pop-in animation */
@keyframes pop-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Header styling */
.letter-header h1 {
  font-size: 26px;
  color: #15c77f;
  font-weight: 800;
  text-shadow: 0px 2px 6px rgba(255, 111, 145, 0.6);
  margin-bottom: 10px;
}
.letter-header p {
  font-size: 16px;
  color: #555;
}

/* Body styling */
.letter-body ul {
  padding-left: 25px;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.letter-body li {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #444;
  font-size: 15px;
  font-weight: 500;
}

/* Footer styling */
.letter-footer p {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: 20px;
}

/* Image styling */
.letter-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.5s;
}
.letter-image img:hover {
  transform: scale(1.05);
}
