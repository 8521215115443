.scanning {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.scanning__container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}
.scanning__container__left {
  display: grid;
  align-items: center;
}
.scanning__container__left h3 {
  font-weight: 600;
  color: darkblue;
  font-size: 15px;
}
.scanning__container__left img {
  height: 7vh;
  margin-left: 5px;
}
.scanning__container h1 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
}
.scanning-for-pick-up input {
  padding: 20px;
  margin-top: 10px;
  font-size: 17px;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.scanning-pickUp-form textarea {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  border: none;
  resize: none;
  height: 8vh;
  font-size: 17px;
  font-family: Arial;
  width: 100%;
  background-color: rgb(238, 238, 238);
}
.scanning-for-arrived input {
  padding: 20px;
  margin-top: 5px;
  font-size: 17px;
  margin-bottom: 5px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.scanning-pack-input {
  padding: 10px !important;
  margin-left: 10px !important;
  width: 85% !important;
}
.scanning-pack-declared {
  padding: 13px;
  background-color: rgb(240, 240, 240);
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  width: 90px;
  color: black;
  font-size: 15px;
}
.scanning-pack-declared:active {
  background-color: royalblue;
  color: white;
}
.scanning__container p {
  font-size: 12px;
  font-weight: 400;
  color: gray;
}
.scanning__content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scanning__content h5 {
  font-weight: 600;
  font-size: 11px;
}
.scanning__content p {
  font-weight: 600;
  font-size: 10px;
  color: gray;
}
.scanning__content__left__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scanning__shipment {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scanning__bottom {
  margin-left: 20px;
  margin-right: 20px;
}
.scanning__shipment__Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}
.scanning__shipment__Content h5 {
  font-weight: 600;
  font-size: 12px;
}
.scanning__shipment__content h5 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
}
.scanning__shipment__Content p {
  font-size: 12px;
  font-weight: 400;
}
.scanning__shipment__content {
  margin-bottom: 10px;
}
.scanning__shipment__content p {
  font-weight: 400;
  font-size: 12px;
}

.scanning__bottom__scanning h3 {
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 5px;
}
.scanning__bottom__scanning {
  border-bottom: 1px solid lightgray;
}
.scanning__bottom__scanning h5 {
  font-weight: 600;
  font-size: 13px;
  color: gray;
}
.scanning__bottom textarea {
  resize: none;
  border: none;
  background-color: whitesmoke;
  outline: none;
  width: 100%;
  height: 7vh;
}
.scanning__button {
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
}
.scan-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
.scan-btn {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: rgb(91, 129, 235) !important;
  color: white !important;
  width: 100% !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 5px !important;
  font-size: 18px !important;
}
.scan-btn1 {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: whitesmoke !important;
  color: gray !important;
  width: 100% !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 5px !important;
}
.hold-btn2 {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: rgb(218, 49, 44) !important;
  color: white !important;
  width: 100% !important;
  border: none !important;
  padding: 10px !important;
  font-size: 18px !important;
  border-radius: 5px !important;
}
/* .arrived-btn {
    text-transform: capitalize !important;
    font-weight: 600 !important
} */
.scanning-flight {
  height: 23vh;
  overflow-y: scroll;
}
.scanning-flight-header {
  display: flex;
  padding: 5px;
}
.scanning-flight-option {
  width: 50%;
  padding: 5px;
}
