.manifest-create-shipment {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.25);
}
.manifest-create-shipment-container {
  width: 800px;
  animation: 0.1s;
  overflow-y: auto;
  height: 580px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 7px;
  animation: slayDown 0.2s;
}
@keyframes slayDown {
  from {
    opacity: 0;
    margin-top: -20px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
.manifest-create-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  z-index: 9999;
  right: 0;
  background-color: rgba(255, 255, 255, 0.55);
}
.ship-sidbear-body-bottom {
  margin-top: auto;
}
.manifest-create-shipment-header {
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(225, 225, 225);
}
.manifest-create-shipment-header-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.manifest-create-shipment-header-right select {
  margin-right: 5px;
  padding: 7px;
  cursor: pointer;
  border: none;
  background-color: rgb(242, 242, 242);
  border-radius: 2px;
  outline: none;
}
.manifest-create-shipment-header-right select:hover {
  background-color: rgb(230, 230, 230);
}
.manifest-create-shipment-clear {
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid rgb(230, 230, 230);
}
.manifest-create-shipment-clear:hover {
  background-color: rgb(240, 240, 240);
  outline: 1px solid lightgray;
  outline-offset: 1px;
}
.manifest-create-shipment-clear:active {
  background-color: white;
  transform: scale(0.9);
  outline-offset: 0.5px;
}
.manifest-create-shipment-body {
  display: flex;
}
.manifest-create-shipment-body-left {
  width: 70%;
  border-right: 1px solid lightgray;
}
.manifest-create-shipment-body-right {
  width: 100%;
}
.manifest-create-shipment-account-info {
  margin: 10px;
  padding-bottom: 7px;
  border-bottom: 1px dashed rgb(214, 214, 214);
}
.manifest-create-shipment-account-info input {
  margin-top: 5px;
  padding: 7px;
  background-color: rgb(243, 243, 243);
  border-radius: 2px;
  border: 1px solid rgb(230, 230, 230);
  width: 280px;
  outline: none;
}
.manifest-create-shipment-account-info input:focus {
  outline: 1px solid rgb(68, 115, 216);
  outline-offset: 1.5px;
  background-color: white;
}
.manifest-create-shipment-account-info h5 {
  font-weight: 600;
}
.manifest-create-shipment-account-info input:focus::placeholder {
  color: lightgray;
}
.manifest-create-shipment-shipping-info {
  margin: 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(214, 214, 214);
}
.manifest-create-shipment-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.manifest-create-shipment-type select {
  margin-top: 5px;
  padding: 5px;
}
.manifest-create-shipment-textarea textarea {
  margin-top: 5px;
  width: 98%;
  height: 135px;
  outline: none;
  border-radius: 2px;
  border: 1px solid rgb(230, 230, 230);
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  line-height: 1.5;
  line-break: strict;
  resize: none;
}
.manifest-create-shipment-textarea textarea:focus {
  font-weight: 600;
  outline: 1px solid rgb(68, 115, 216);
  outline-offset: 1.5px;
  font-size: 14px;
}
.manifest-create-shipment-body-right-header {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
}
.manifest-create-shipment-body-right-header h5 {
  font-weight: 600;
}
.manifest-create-shipment-body-right-body {
  display: flex;
  flex-direction: column;
}
.manifest-create-shipment-body-right-body strong {
  font-weight: 600 !important;
  text-decoration: underline !important;
  color: black !important;
}
.manifest-create-status-option {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.manifest-create-date {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
  outline: none;
}
.manifest-create-date:focus {
  outline: 1px solid rgb(68, 115, 216);
  outline-offset: 1.5px;
}
.manifest-create-bottom {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  background-color: white;
  border-top: 1px solid rgb(230, 230, 230);
  box-shadow:
    rgba(60, 64, 67, 0.5) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.35) 0px 2px 6px 2px;
  z-index: 2;
}
.manifest-create-bottom-container {
  padding: 10px;
  display: flex;
}
.manifest-cancel-button {
  margin-left: auto;
  margin-right: 5px;
  padding: 7px;
  font-size: 14px;
  color: rgb(156, 156, 156);
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  border-radius: 2px;
}
.manifest-cancel-button:hover {
  background-color: rgb(240, 240, 240);
  color: black;
}
.manifest-cancel-button:active {
  transform: scale(0.9);
  background-color: lightgray;
}
.manifest-post-button {
  padding: 7px;
  font-size: 14px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: rgb(45, 102, 189);
  border-radius: 2px;
}
.manifest-post-button:hover {
  background-color: rgb(26, 76, 150);
}
.manifest-post-button:active {
  background-color: rgb(27, 94, 194);
  transform: scale(0.9);
}
#form-file-upload {
  height: 19rem;
  width: 35rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 3px;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  margin-top: 50px;
  background-color: rgb(22, 120, 190);
  color: white;
}

.upload-button:hover {
  text-decoration-line: underline;
  background-color: rgb(22, 101, 158);
}
.upload-button:active {
  transform: scale(0.9);
}
.manifest-attaching-content-option {
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;
}
.manifest-attaching-content-option h5 {
  font-weight: 600;
  font-size: 12px;
}
.form-upload-header-option {
  padding: 7px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}
.form-upload-header-option p {
  font-size: 14px;
  margin-left: 7px;
}
.form-upload-header {
  margin-top: 10px;
}
#label-file-upload.drag-active {
  background-color: #ffffff;
  outline: 2px dashed rgb(27, 94, 194);
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.manifest-attaching-content-option {
  margin: 10px;
  padding: 7px;
  border-radius: 3px;
  background-color: rgb(245, 245, 245);
  width: fit-content;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.manifest-attaching-content {
  display: flex;
}
.manifest-attaching-content button {
  margin: 10px;
  padding: 3px;
  cursor: pointer;
}
.download-format-manifest:active {
  color: red !important;
}
.download-format-manifest {
  margin-left: 18px !important;
  user-select: none !important;
}
