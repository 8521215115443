.manifest-action-import {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 9999;
  animation: fadeIn 0.1s;
  justify-content: center;
}

.manifest-action-import-container {
  width: 450px;
  border-radius: 3px;
  animation: popup 0.1s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

.manifest-action-export-container {
  width: 450px;
  border-radius: 5px;
  animation: popup 0.1s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

.manifest-action-import-header {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.manifest-action-import-header h5 {
  font-weight: 600;
  font-size: 15px;
}

.skd-user-info-export-name {
  padding: 5px;
  margin-left: 5px;
  position: relative;
  margin-right: 5px;
}

.skd-user-info-export-name input {
  margin-top: 7px;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  border: 1px solid lightgray;
  outline: none;
  padding-right: 32px;
}

.skd-user-info-export-name input:hover {
  border: 1px solid rgb(210, 210, 210);
}

.skd-user-info-export-name input:focus {
  border: 1px solid rgb(230, 230, 230) !important;
  outline: 1px solid #002fca !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 3px dodgerblue !important;
}

.clear-export-name-skd-user {
  position: absolute;
  right: 12px;
  bottom: 33px;
  cursor: pointer;
}

.skd-user-info-export-select {
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.skd-user-info-export-select-option {
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  user-select: none;
  align-items: center;
}

.skd-user-info-export-select-option:hover {
  background-color: rgb(240, 240, 240);
}

.skd-user-info-export-select-option:active {
  background-color: rgb(225, 225, 225);
}

.skd-user-shipment-last-header {
  display: flex;
  user-select: none;
  align-items: center;
}

.skd-user-info-export-button {
  padding: 8px;
  display: flex;
}

.skd-user-info-export-select h5 {
  font-weight: 600;
  font-size: 14px;
}

.skd-user-info-export-name h5 {
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: black;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #4682f3;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.skd-user-info-export-button-apply2 {
  background-color: #2887e6;
  width: 85px;
  text-decoration: none;
  color: white;
  display: flex;
  font-size: 14px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  border-radius: 5px;
  margin-left: 5px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.skd-user-info-export-button-apply2:hover {
  opacity: 0.9;
}

.skd-user-info-export-button-apply2:active {
  transform: scale(0.95);
}

.skd-user-info-export-button-cancel:hover {
  background-color: rgb(220, 220, 220);
}

.skd-user-info-export-button-cancel:active {
  transform: scale(0.95);
}

.skd-user-info-export-button {
  padding: 8px;
  display: flex;
}

.skd-user-info-export-button-cancel {
  margin-left: auto;
  padding: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgb(240, 240, 240);
  width: 65px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  border-radius: 5px;
}

.skd-import-container-popup {
  animation: popup 0.1s;
}

@keyframes popup {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}
